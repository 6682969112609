import { Avatar, Badge, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { statusConnection } from "../../components/OnlineInterviewing/config";
import NetworkComponent from "./Network";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  default: {
    "& .MuiBadge-badge":{
      backgroundColor: theme.palette.grey[400],
      boxShadow: `0 0 0 2px ${theme.palette.primary.backgroundAll}`,
      color: `${theme.palette.primary.backgroundAll}`
    }
  },
  animate: {
    "& .MuiBadge-badge::after": { 
      animation: 'ripple 1.2s infinite ease-in-out'
    }
  },
  green: {
    "& .MuiBadge-badge::after": { 
      color: `${theme.palette.call.main}`,
    },
    "& .MuiBadge-badge": { 
      backgroundColor: `${theme.palette.call.main}`,
    }
  },
  red: {
    "& .MuiBadge-badge::after": { 
      color: `${theme.palette.hangup.main}`,
    },
    "& .MuiBadge-badge": { 
      backgroundColor: `${theme.palette.hangup.main}`,
    }
  },
  yellow: {
    "& .MuiBadge-badge::after": { 
      color: `${theme.palette.orange.main}`,
    },
    "& .MuiBadge-badge": { 
      backgroundColor: `${theme.palette.orange.main}`,
    }
  }
}));

const AvatarComponent = ({ profile, name, color, animate, title, network, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} position="relative" alignItems="center" direction="column">
      <Grid>
        <Tooltip title={title || ''} placement="right-end" color="primary" arrow>
          <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            overlap="circular"
            variant="dot"
            className={clsx(classes.default, classes[color || ''], {
              [classes.animate]: animate,
            })}
          >
            <Avatar
              alt={`${profile?.identity?.firstname || ""} ${profile?.identity?.lastname || ""}`}
              src={ profile?.identity?.picture || profile?.identity?.firstname || null }
              sx={{ width: 80, height: 80 }}
            />
          </Badge>
        </Tooltip>
      </Grid>
      <Grid container item spacing={1} xs={12}>
        { network && <NetworkComponent count={network} /> }
        <Grid sx={{ textAlign: `${!network && 'center'}` }} item xs>
          <Typography sx={{ color: "primary.text" }} variant="bigText">
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvatarComponent;
