const initialStore = {
   template: [{},
    {
        language: "en",
        top: "Hello",
        body: "You are invited to participate in an interview by %3 \non %1 at %2 ",
        press: "Please click on the connect button to join the event.",
        button: "connect",
        browser: "or you may simply copy and paste the following link into \nyour favorite web browser:",
        link: "<guest-link-generated-by-IQOYA-CONNECT>",
        headset: "For an optimal quality, we advise the use of a headset",
        copy1: "This connection is powered by ",
        copy2: " from Digigram",
        add: "Add to:",
        direction: "ltr",
    },
    {
        language: "fr",
        top: "Bonjour",
        body: "Vous êtes invité à participer à une interview par %3 \nle %1 à %2 ",
        press: "Veuillez cliquer sur \nle bouton de connexion pour rejoindre l'événement.",
        button: "CONNEXION",
        browser: "ou vous pouvez simplement copier et coller le lien suivant dans \nvotre navigateur Web préféré:",
        link: "<lien-invité-généré-par-IQOYA-CONNECT>",
        headset: "Pour une qualité optimale, nous conseillons l'utilisation d'un casque",
        copy1: "Cette connexion est alimentée par ",
        copy2: " de Digigram",
        add: "Ajouter à:",
        direction: "ltr",
    },
    {
        language: "es",
        top: "Hola",
        body: "Está invitado a participar en una entrevista de %3 \nen %1 a las %2 ",
        press: "Haga clic en el botón de conexión para unirse al evento.",
        button: "conectar",
        browser: "o puede simplemente copiar y pegar el siguiente enlace en \nsu navegador web favorito:",
        link: "<invitado-enlace-generado-por-IQOYA-CONNECT>",
        headset: "Para una calidad óptima, recomendamos el uso de un auricular",
        copy1: "Esta conexión es alimentada por ",
        copy2: " de Digigram",
        add: "Añadir:",
        direction: "ltr",
    },
    {
        language: "pt",
        top: "Olá",
        body: "Você está convidado a participar a uma entrevista da %3 \nem %1 às %2 ",
        press: "Clique no botão conectar para participar Ao evento.",
        button: "conectar",
        browser: "ou você pode simplesmente copiar e colar o seguinte link em \nseu navegador favorito:",
        link: "<guest-link-gerado-por-IQOYA-CONNECT>",
        headset: "Para uma qualidade ideal, aconselhamos o uso de um fone de ouvido",
        copy1: "Esta conexão é alimentada por ",
        copy2: " da Digigram",
        add: "Adicionar à:",
        direction: "ltr",
    },
    {
        language: "ru",
        top: "Здравствуйте, ",
        body: "Вы приглашены принять участие в интервью %3 \nна %1 в %2. ",
        press: 'Нажмите кнопку \nподключения, чтобы присоединиться к мероприятию.',
        button: "подключиться",
        browser: "или вы можете просто скопировать и вставить следующую ссылку \nв свой любимый веб-браузер:",
        link: "<гостевая-ссылка-создана-IQOYA-CONNECT>",
        headset: "Для оптимального качества мы рекомендуем использовать гарнитуру",
        copy1: "Это соединение питается от ",
        copy2: " из Дигиграммы",
        add: "Добавить к:",
        direction: "ltr",
    },
    {
        language: "ua",
        top: "Вітаємо,",
        body: "Вас запрошують взяти участь в інтерв'ю від %3 \nна %1 на %2 ",
        press: "Будь ласка, натисніть кнопку підключити, щоб приєднатися до події.",
        button: "підключити",
        browser: "або ви можете просто скопіювати та вставити наведене нижче посилання у \nсвій улюблений веб-браузер:",
        link: "<guest-link-generated-by-IQOYA-CONNECT>",
        headset: "Для оптимальної якості радимо використовувати гарнітуру",
        copy1: "Це з’єднання живиться від ",
        copy2: " з Digigram",
        add: "Додати до:",
        direction: "ltr",
    },
    {
        language: "ab",
        top: "مرحبا",
        body: "أنت مدعو للمشاركة في مقابلة من قبل %3 \nعلى %1 على الساعة %2",
        press: "الرجاء الضغط على زر الاتصال للانضمام إلى الحدث.",
        button: "الاتصال",
        browser: "أو يمكنك ببساطة نسخ الرابط التالي ولصقه في \nمستعرض الويب المفضل لديك:",
        link: "<guest-link-generated-by-IQOYA-CONNECT>",
        headset: "للحصول على جودة مثالية ، ننصح باستخدام سماعة رأس",
        copy1: "هذا الاتصال مدعوم من ",
        copy2: " من قبل Digigram",
        add: "أضفه إلى:",
        direction: "rtl",
    },
    {
        language: "ch",
        top: "您好！",
        body: "您被邀請參加 %3 在 %2 的 %1 上\n的採訪。請單擊",
        press: "請點擊連接按鈕加入活動。",
        button: "連接",
        browser: "或者您可以簡單地將以下鏈接複製並粘貼到 \n您最喜歡的網絡瀏覽器中:",
        link: "<guest-link-generated-by-IQOYA-CONNECT>",
        headset: "為了獲得最佳質量，我們建議使用耳機",
        copy1: "此連接由 ",
        copy2: " 來自 Digigram",
        add: "添加:",
        direction: "rtl",
    },
   ],
   language: [
    { name: "English", code: 'en' } ,
    { name: "Français", code: 'fr' }, 
    { name: "Español", code: 'es' }, 
    { name: "Portuguese", code: 'pt' },
    { name: "Russian", code: 'ru' },
    { name: "Ukrainian", code: 'ua' },
    { name: "Arabic", code: 'ab' },
    { name: "Chinese", code: 'ch'}]
  };
  const template = (state = initialStore, action) => {
    switch (action.type) {
      case "TAKE_TEMPLATE":
        return {
          ...state,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  export default template;