import { Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import audioTest from "../../../audio/test.mp3";

const StreamLevelBars = ({ stream, ...props }) => {
    const main = useRef();
    
    let rafId;
    let analyser;
    let visualElements;
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const tick = () => {
      analyser.fftSize = 32;
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);
      processFrame(dataArray);
      rafId = requestAnimationFrame(tick);
    };

    useEffect(() => {
      createDOMElements();

      if(!stream) return;
      analyser = audioContext.createAnalyser();

      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      rafId = requestAnimationFrame(tick);
      
      return () => {
        cancelAnimationFrame(rafId);
        analyser.disconnect();
        source.disconnect();
        audioContext.close();
      }
    }, [stream]);


    const processFrame = (data) => {
      const values = data.sort(sorting).slice(0, 14);
      if (values.length > 0) {
        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          const elmStyles = visualElements[i].style;
          if (value > 140) {
            elmStyles.backgroundColor = i < 10 ? "#1EDD5F" : (i < 12 ? "#f99816" : "#ff4f5b");
          } else {
            elmStyles.backgroundColor = "#C4C4C4";
          }
        }
      }
    };


    const generateHTML = (item) => {
        const visualValueCount = 14;
        for (let i = 0; i < visualValueCount; ++i) item.current.appendChild(document.createElement("div"));
    };

    const sorting = (a, b) => {
      if (a > b) return -1; 
      if (a < b) return 1;
      return 0;
    }

    const createDOMElements = () => {
        if (main && main.current.querySelectorAll("div").length > 0) {
          main.current.innerHTML = "";
        }
        generateHTML(main);
        visualElements = main.current.querySelectorAll("div");
    };
    
    return (
      <Grid container item ref={main}
        sx={{ 
          height: "27px",
          width: 120,
          "& > div": {
            minWidth: "6px",
            mr: "2px",
            bgcolor: "primary.soundVisual",
          },
      }}/>
    );
}
export default StreamLevelBars;