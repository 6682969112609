export default (theme) => ({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1110px",
        },
      },
    },
  },
});