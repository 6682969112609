/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useTheme } from '@mui/styles';
import { Bars } from  'react-loader-spinner';

import types from '../../../types';
import App from "../App";
import useNotifier from '../Notifier';

const Loader = ({ loading, getAuthToken, ...props}) => {
  useNotifier();
  const theme = useTheme();
  
  useEffect( () => {
      getAuthToken();
  }, []);
  return (
    <>
      { loading 
      ? <div style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <Bars color={theme?.palette?.info?.main} height={80} width={80} />
      </div> 
      : <App />}
    </>
  );
};

export default connect(
  (state) => ({loading: state.app.loading}), 
  (dispatch) => ({ getAuthToken: () => dispatch({ type: types.app.GET_AUTH_TOKEN })})
)(Loader);