import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Button, ButtonGroup, Fab, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import { Mic, MicOff, MicNone ,Stop,Pause,RadioButtonChecked,Tune,PersonAdd,CallEnd,Call, CloudQueue, GraphicEq } from "@mui/icons-material";
import { connect } from "react-redux";
import useNavigation from "../../../hooks/useNavigation";
import { FabButtonInvited, FabButtonNew } from "./animations";
import { generatePath, useParams } from "react-router-dom";
import { FETCH, JANUS, MEDIA, ROUTES } from "../../../constants";
import types from "../../../types";

const useStyles = makeStyles((theme) => ({ 
  call: { 
    color: `${theme.palette.call.contrastText} !important`,
    backgroundColor: `${theme.palette.call.main} !important`, 
    "&:hover": { backgroundColor: `${theme.palette.call.dark} !important` } 
  },
  hangup: { 
    color: `${theme.palette.hangup.contrastText} !important`,
    backgroundColor: `${theme.palette.hangup.main} !important`, 
    "&:hover": { backgroundColor: `${theme.palette.hangup.dark} !important` } 
  },
  answer: { 
    color: `${theme.palette.orange.contrastText} !important`,
    backgroundColor: `${theme.palette.orange.main} !important`, 
    "&:hover": { backgroundColor: `${theme.palette.orange.main} !important` } 
  },
}));

const ControlPanel = ({ count_records,getRecordings, items, item, onCall, onAnswer, onHangup, onDecline, callState, localSource, recording, ...props }) => {
  const classes = useStyles();
  const pageSize = FETCH.PAGE_SIZE;

  const { navigate } = useNavigation()
  const { interviewId } = useParams();
  useEffect(() => {
    interviewId && getRecordings({ id: interviewId, pageLength: pageSize, skip: 0 })
  }, [items.length === 0])
  
  // const [isOpenButton, setIsOpenButton] = useState(false);
  // const [isBlurInvited, setIsBlurInvited] = useState(false);
  // const [start, setStart] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [isMuted, setIsMutes] = useState(false);

  const onMute = () => {
    localSource.getAudioTracks()[0].enabled = !(localSource.getAudioTracks()[0].enabled);
    setIsMutes(!localSource.getAudioTracks()[0].enabled);
  };
  const onMessage = () => {};
  const onResume = () => {};
  const onPause = () => {};
  const stopRecord = () => {};

  // const changeExpendedLeeave = () => start ? setIsOpenButton(true) : setIsOpenButton(false);
  // const changeExpended = () => setIsOpenButton(true);
  // const changeExpendedLeeaveInvited = () => start ? setIsBlurInvited(true) : setIsBlurInvited(false);
  // const changeExpendedInvited = () => setIsBlurInvited(true);
  const openUrl = (url) => navigate(url)
  
  const isOpenButton = false;
  const start = false;
  return (
    <Grid container mt={2} p={2} gap={1} sx={{ 
      alignItems: "center",
      borderRadius: "5px",
      bgcolor: 'primary.backgroundAll' }}
    >

      <Grid container item gap={1} width={125}>
        {
          (callState !== JANUS.IN_CALL) &&
            (<Fab onClick={() => openUrl( generatePath(ROUTES.AUDIO_SETTING_INTERVIEW_ID, {interviewId}))}><Tune /></Fab>)
        }
        {
          (localSource?.getAudioTracks()) &&
            <Fab onClick={onMute} className={isMuted ? classes.answer : ''}>{isMuted ? (<MicOff />) : (<Mic />) }</Fab>
        }
      </Grid>

      <Grid container item xs >
        {/* <ButtonGroup aria-label="recording">
          <Tooltip  title="Recording">
            <RadioButtonChecked 
              sx={{ width: 40, height: 40 }}
              color={[recording.local, recording.cloud].includes(MEDIA.RECORDING) ? "hangup" : "text"}
            />
          </Tooltip>
          <Tooltip  title="Cloud">
            <Button 
              color={recording.cloud === MEDIA.RECORDING ? "hangup" : "blue"} 
              // variant={recording.cloud === MEDIA.RECORDING ? "contained" : "outlined"} 
            ><CloudQueue /></Button>
          </Tooltip>
          <Tooltip  title="Local">
            <Button
              color={recording.local === MEDIA.RECORDING ? "hangup" : "blue"} 
              // variant={recording.local === MEDIA.RECORDING ? "contained" : "outlined"} 
            ><MicNone /></Button>
          </Tooltip>
        </ButtonGroup> */}
      </Grid>

      {/* { (callState === JANUS.IN_CALL) && (
        <Grid container item xs gap={2} minWidth={130}>
          <Fab onClick={() => (isPause ? onResume() : onPause())} > <Pause /></Fab>
          <Fab onClick={stopRecord}> <Stop /> </Fab>
        </Grid>
      )} */}

      { !!item?.guest && (
        <Grid container item width={300} gap={1} justifyContent="flex-end" wrap="nowrap">
          
          { ([JANUS.IN_CALL, JANUS.CALLING].includes(callState)) 
            ? (
              <Fab variant="extended" className={classes.hangup} onClick={onHangup}>
                <CallEnd sx={{ mr: 1 }} /> End Call
              </Fab>
            ) 
            : ((callState === JANUS.INCOMING_CALL) 
            ? (<>
              <Fab variant="extended" className={classes.answer} onClick={onAnswer} >
                <Call sx={{ mr: 1 }} /> Accept
              </Fab>
              <Fab variant="extended" className={classes.hangup} onClick={onDecline}>
                <CallEnd sx={{ mr: 1 }} /> Decline
              </Fab>
            </>)
            : (
              <Fab variant="extended" className={classes.call} onClick={onCall} >
                <Call sx={{ mr: 1 }} /> Call
              </Fab>
            )) }
        </Grid>
      )}
      <Grid container item justifyContent="flex-end" width={160}>
        <Fab
          disabled={callState === JANUS.IN_CALL}
          variant="extended"
          onClick={() => openUrl(`${ROUTES.INTERVIEW}/${interviewId}/invite`)}>
          <PersonAdd sx={{ mr: 1 }} />invite guest
        </Fab>
      </Grid>
      
      
      {count_records > 0 && (
      <Grid container item width={250}>
        <Button 
          disabled={count_records <= 0 || callState === JANUS.IN_CALL}
          onClick={() => openUrl(generatePath(ROUTES.MANAGERECORDING_INTERVIEW, { interviewId }))}
          variant='cBlue'
        >
          <GraphicEq 
            sx={{ mr: 1 }} 
            preserveAspectRatio= 'none'
            style={{ height: '15px', width: '25px' }}
          /> manage recordings {`(${count_records})`}
        </Button>
      </Grid>
      )}
    </Grid>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getRecordings: (data) => dispatch({ type: types.recordingsmanage.GET_MANAGE_RECORDING, data}) 
});
const mapStateToProps = (state) => ({ 
  count_records: state.interview.item.count_records,
  item: state.interview.item,
  items: state.managerecordings.items,
  callState: state.janus.call_state,
  localSource: state.janus.localSource,
  recording: state.janus.recording,
});
export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
