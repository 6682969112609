/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_MANAGE_RECORDING: 'managerecording/get_manage_recording',
    GET_RECORDING: 'managerecording/get_recording',
    GET_MARKERS: 'managerecording/get_markers',
    GET_AUDIO_URL: 'managerecording/get_audio_url',
    CREATE_MANAGE_RECORDING: 'managerecording/add_manage_recording',
    UPLOAD_RECORDING: 'managerecording/upload_recording',
    UPDATE_MANAGE_RECORDING: 'managerecording/update_manage_recording',
    DELETE_MANAGE_RECORDING: 'managerecording/delete_manage_recording',
    SEND_TO_EMAIL_MANAGE_RECORDING: 'managerecording/send_to_email_manage_recording',
    SEND_TO_FTP_MANAGE_RECORDING: 'managerecording/send_to_ftp_manage_recording',
    CHANGE_SELECTED: 'managerecording/change_selected',
    FETCH_STATUS: 'managerecording/fetch_status',
    UPDATE_MARKERS_RECORDING: 'interview/update_markers_recording',
    DELETE_MARKERS_RECORDING: 'interview/delete_markers_recording',
    // success
    GET_MANAGE_RECORDING_SUCCESS: 'managerecording/get_manage_recording_success',
    GET_AUDIO_URL_SUCCESS: 'managerecording/get_audio_url_success',
    GET_RECORDING_SUCCESS: 'managerecording/get_recording_success',
    GET_MARKERS_SUCCESS: 'managerecording/get_markers_success',
    CREATE_MANAGE_RECORDING_SUCCESS: 'managerecording/add_manage_recording_success',
    UPDATE_MANAGE_RECORDING_SUCCESS: 'managerecording/update_manage_recording_success',
    DELETE_MANAGE_RECORDING_SUCCESS: 'managerecording/delete_manage_recording_success',
    SEND_TO_EMAIL_MANAGE_RECORDING_SUCCESS: 'managerecording/send_to_email_manage_recording_success',
    SEND_TO_FTP_MANAGE_RECORDING_SUCCESS: 'managerecording/send_to_ftp_manage_recording_success',
    UPDATE_MARKERS_RECORDING_SUCCESS: 'interview/update_markers_recording_success',
    DELETE_MARKERS_RECORDING_SUCCESS: 'interview/delete_markers_recording_success',
}