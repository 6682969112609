import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";

import types from "../../../types";
import { FETCH, ROUTES } from "../../../constants";

import useNavigation from "../../../hooks/useNavigation";
import Preloader from "../../containers/Preloader";
import FloatActions from "./FloatActions";
import DeleteDialog from "../../containers/Dialog/DeleteDialog";
import NoRowsText from "../../containers/NoRowsText";

const columns = [
  {
    field: "__check__",
    hideable: false,
    sortable: false,
    editable: false,
    width: 55
  },{
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    sortable: false,
    editable: false,
  },

  {
    field: "phone",
    headerName: "Phone number",
    sortable: false,
    flex: 1,
  },
];

const List = ({ items, loading, getContact, editContact, deleteContacts, ...props }) => {
  const [selected, setSelected] = useState([]);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const pageSize = FETCH.PAGE_SIZE;
  const { navigate } = useNavigation();

  useEffect(() => {
    getContact({ pageLength: pageSize, skip: 0 });
  }, []);

  const onClose = () => setSelected([]);
  const onRename = useCallback((item,p) => {
    if (!editing) return;
    setEditing(false);
    setName("") 
    editContact({ _id: item.id, name: name, shared: false })
  }, [name])
  const onAdd = () => navigate(ROUTES.ADRESSBOOK_NEW);
  const onDelete = () => setOpenConfirm(true);
  const onCancelDelete = () => setOpenConfirm(false);
  const onConfirmDelete = () => { 
    setOpenConfirm(false); 
    deleteContacts({ _ids: selected }); 
  }
  
  const onEdit = () => {
    if (selected?.length === 1) {
      navigate(`${ROUTES.ADRESSBOOK}/${selected[0]}`);
    }
  };

  return (
    <>
      <DataGrid
        rows={items || []}
        columns={columns}
        loading={loading}
        components={{
          LoadingOverlay: Preloader,
          NoRowsOverlay: () => (<NoRowsText text="Click on the + button to add a new contact in your address book" />)
        }}
        componentsProps={{
          cell: {
            onChange: (i) => setName(i.target.value)
          }
        }}
        onCellFocusOut={onRename}
        onCellEditStart={() => setEditing(true)}
        onCellEditStop={(item, events) => {
          if(events.keyCode === 13){
            return editContact({ _id: item.id, name: name, shared: false })
          }
          return
        }}
        isRowSelectable={(e) => !editing}
        getRowId={(row) => row._id}
        onSelectionModelChange={setSelected}
        selectionModel={selected}
        checkboxSelection
        disableSelectionOnClick
      />
      <FloatActions
        selected={selected}
        onDelete={onDelete}
        onClose={onClose}
        onAdd={onAdd}
        onEdit={onEdit}
      />
      <DeleteDialog 
        open={openConfirm}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  items: state.contacts.items,
  loading: state.contacts.fetchStatus === FETCH.LOADING,
});

const mapDispatchToProps = (dispatch) => ({
  getContact: (data) => dispatch({ type: types.contacts.GET_CONTACT, data: data }),
  editContact: (data) => dispatch({ type: types.contacts.SAVE_CONTACT, data: data }),
  deleteContacts: (e) => dispatch({ type: types.contacts.DELETE_CONTACT, data: e }),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
