import React from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as LightBG } from "../../../images/LightBG.svg";
import { ReactComponent as DarkBG } from "../../../images/DarkBG.svg";
import constants from "../../../constants";

const backgrounds = {
  [constants.themes.DARK]: DarkBG,
  [constants.themes.LIGHT]: LightBG,
};

const PageHeader = (props) => {
  const {
    titleText,
    theme,
  } = props;
  return (
    <Grid item xs={12}>
      <Grid
        sx={{ position: "relative", "& svg": { width: "100%", height: "100%" } }}
        item
        xs={12}
      >
        <Grid component={backgrounds[theme]}> </Grid>
        <Grid
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            lineHeight: "68px",
            fontWeight: 600,
            color: "primary.text",
            top: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "3rem",
              },
            }}
          >
            {titleText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  (state) => ({theme: state.setting.theme})
)(PageHeader);
