import {Grid,Button,Typography,InputLabel,Switch,FormControl,Select,MenuItem} from "@mui/material";
import React, { useEffect } from "react";
import {ArrowForward,AutoFixHigh,PlayArrow} from "@mui/icons-material";
import useNavigation from "../../../hooks/useNavigation";
import { ROUTES } from "../../../constants";
import HelpTip from "../../containers/HelpTip";
import { validators } from "./validators";
import useForm from "../../../hooks/useForm";
import { connect } from "react-redux";
import { generatePath, useParams } from "react-router-dom";

const PostProductionForm = ({ item, ...props }) => {
    const { navigate, setAskBeforeNavigate } = useNavigation();
    const initState = {
        normalization: false,
        magicClean: false,
        loudnessTarget: "-23"
    }
    const { interviewId } = useParams()
    const submitProduction = () => onPageUrl(generatePath(ROUTES.EDIT_RECORDINGS, {interview_id: interviewId}))
    const {
        handleChange,
        handleSubmit,
        handleBlur,
        state,
        isChanged,
      } = useForm({ initState, validators, callback: submitProduction });
    useEffect(() => setAskBeforeNavigate(isChanged), [isChanged]);
    const onPageUrl = (url) => navigate(url)
    return (
      <Grid mt={3} sx={{ bgcolor: "primary.backgroundAll" }} container item xs={12}>
        <Grid container justifyContent="center" item xs={12}>
          <Grid container justifyContent="center" item xs={6}>
            <Typography variant="manage">{item.name}</Typography>
          </Grid>
        </Grid>
        <Grid mt={3} container justifyContent='center' item xs={12}>
          <Grid container item xs={7}>
            <Grid item xs={12}>
              <Typography variant="middle">
                Choose your postproduction steps
              </Typography>
            </Grid>
            <Grid mt={2} container alignItems='center' item xs={12}>
              <Grid item xs={6}>
                <Typography color="primary.text"> Normalization:</Typography>
              </Grid>
              <Grid container alignItems='center' justifyContent="end" item xs={6}>
                off
                <Switch  
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={state.normalization}
                    name="normalization"
                />
                on
                <HelpTip children="on" />
              </Grid>
            </Grid>
            <Grid mb={2} mt={4} container item xs={12}>
                <FormControl fullWidth>
                        <InputLabel>
                            <Typography color="primary.text">Loudness target</Typography>
                        </InputLabel>
                        <Select
                            name="loudnessTarget"
                            value={state.loudnessTarget}
                            label="Loudness target"
                            onChange={handleChange}>
                            <MenuItem value="-23">-23 LUFS (EBU R128 recommendation for European TV)</MenuItem>
                        </Select>
                </FormControl>
                <Grid container justifyContent="flex-start" item xs={12}>
                    <Grid mt={1} item xs={3.5}>
                        <Button variant="contained">
                            <PlayArrow sx={{ mr: 0.8 }} />
                            <Typography variant="buttonS">
                                pre-listening...
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={3} container item xs={12}>
                <Grid container alignItems="center" item xs={6}>
                    <Grid mr={1}><AutoFixHigh sx={{ maxWidth: 18}} /></Grid>
                    <Grid><Typography color="primary.text">Magic clean:</Typography></Grid>
                </Grid>
                <Grid sx={{ display: "flex", justifyContent: "end" }} item xs={6}>
                    off
                    <Switch
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={state.magicClean}
                        name="magicClean"
                      />
                    on
                    <HelpTip children="Magic clean" />
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" item xs={12}>
                    <Grid mt={1} item xs={3.5}>
                        <Button variant="contained">
                            <PlayArrow sx={{ mr: 0.8 }} />
                            <Typography variant="buttonS">
                                pre-listening...
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container mt={5} mb={8} justifyContent="center" item xs={12}>
            <Grid container spacing={2} item xs={3}>
                <Grid item xs={6}>  
                    <Button onClick={() => onPageUrl(generatePath(ROUTES.INTERVIEW_EDIT, { interviewId: interviewId }))} variant="outlined" color="orange">
                        Close 
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => submitProduction()} variant="contained" color="orange">
                        Next <ArrowForward sx={{ ml: 1 }} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    );
};
const mapStateToProps = (state) => ({
    item: state.interview.item
})
export default connect(mapStateToProps)(PostProductionForm);
  