import { useEffect, useState } from "react";
import { Stop } from "@mui/icons-material";
import { connect } from "react-redux";
import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import { MEDIA } from '../../../constants';
import { ButtonProgress } from '../../containers/Buttons'

const machine = createMachine({
  predictableActionArguments: true,
  id: 'test mic',
  initial: 'inactive',
  states: {
    inactive: {
      entry: ['cleanup'],
      on: { TOGGLE: 'recording' }
    },
    recording: {
      entry: ['recording'],
      on: { 
        TOGGLE: 'inactive',
        REC_DONE: 'rec_done',
      }
    },
    rec_done: {
      entry: ['rec_done'],
      on: { 
        PLAY: 'playing',
      }
    },
    playing: {
      entry: ['playing'],
      on: { 
        TOGGLE: 'inactive',
      }
    }
  }
});

const ButtonTestInput  = ({devices, input, ...props }) => {
  const [countdown, setCountdown] = useState(0);
  const [media, setMedia] = useState(null);
  const [blob, setBlob] = useState(null);
  const [audio, setAudio] = useState(null);
  
  // machine actions
  const [state, send] = useMachine(machine, { 
    devTools: true,
    actions: {
      cleanup: () => {
        audio?.pause();
        setAudio(undefined);
        setCountdown(0);
        setBlob(undefined);
        (media?.state === 'recording') && media.stop();
      },
      recording: () => {
        setCountdown(MEDIA.TEST_MIC_SEC);
        media?.start();
      },
      rec_done: () => {
        (media?.state === 'recording') && media.stop();
      },
      playing: () => {
        let audio = new Audio(URL.createObjectURL(blob.data));
        if(typeof audio?.sinkId !== 'undefined') {
          audio.setSinkId(devices.outputId)
          .catch(console.log)
          .finally(() => { audio.play() });
        }else{
          audio.play()
        }
        setAudio(audio);
        setCountdown(MEDIA.TEST_MIC_SEC);
      },
    },
   });
  
  // prepare media recorder
  useEffect(() => {
    if(!input){
      return;
    }
    const mediaStream = new MediaRecorder(input);
    mediaStream.ondataavailable = setBlob;
    setMedia(mediaStream);
    return () => { 
      (media?.state === 'recording') && media.stop();
      if(media){
        media.ondataavailable = undefined;
      }
      setMedia(undefined);
    };
  }, [input]);

  // countdown timer
  useEffect(() => {
    if(countdown === 0){
      return state.matches('playing') ? send('TOGGLE') : send('REC_DONE');
    }
    const timer = setTimeout(() => { setCountdown(countdown - 1) }, 1000);
    return () => clearTimeout(timer);
  },[countdown]);

  // playing
  useEffect(() => { blob?.data && send('PLAY'); },[blob?.data]);

  return (
    <ButtonProgress 
      variant="cBlue"
      sx={{ width: 200 }}
      normal={countdown > 0}
      reverse={state.matches('playing')}
      onClick={() => send('TOGGLE')}
    >
      {(state.value !== 'inactive') && (<Stop sx={{ mr: 1 }} />)}
      {state.value === 'inactive' && 'test mic'}
      {state.value === 'recording' && `recording (${countdown})`}
      {state.value === 'playing' && `playing (${countdown})`}
    </ButtonProgress>
  );
};

const mapStateToProps = (state) => ({
  devices: state.media.devices
})
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ButtonTestInput);