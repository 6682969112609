import { Add, Close, DeleteOutlineOutlined, Edit } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import "../../../scss/FloatAB.scss";
import React from "react";

const FloatActionsInterview = ({ selected, onDelete, onClose, onRename, onAdd, ...props }) => {
  const openAction = selected?.length > 0;
  const editAction = selected?.length === 1;
  const deleteAction = selected?.length > 1;
  const handleFab = () => openAction ? onClose() : onAdd();

  const handleDelete = (e) => { 
    e.stopPropagation(); 
    onDelete(); 
  }
  return (
    <>
      <SpeedDial
        direction="left"
        ariaLabel={openAction ? (editAction ? "edit" : "delete") : "add"}
        icon={ openAction ? ( <Close /> ) : (<Add />)}
        onClick={handleFab}
        open={openAction}
      >
        <SpeedDialAction
          icon={<DeleteOutlineOutlined />}
          tooltipTitle="Remove selected"
          onClick={handleDelete}
        />
        {!deleteAction && <SpeedDialAction
          icon={<Edit />}
          onClick={onRename}
          tooltipTitle="Rename selected"
        />}
      </SpeedDial>
    </>
  );
};

export default FloatActionsInterview;
