import React from "react";
import { Grid, ImageList, ImageListItem, ListItemButton, Tooltip } from "@mui/material"
import { Cancel } from "@mui/icons-material"
import Wave from "../../../images/wav.svg";
import Mp3 from "../../../images/mp3.svg";

const ListFile = ({ carouselImages }) => {
    const deleteImage = (id) => {}
    return (
        <Grid container item xs={12} sm={5}>
            <ImageList cols={5} rows={2} gap={8} rowHeight={85} variant="quilted">
                {carouselImages && carouselImages.map((item, int) => (
                    <ImageListItem key={int}>
                        <ListItemButton 
                            sx={{ position: 'absolute', right: 0, p: 0, maxHeight: 18 }} 
                            onClick={() => deleteImage(item.id)}>
                                <Cancel color="primary" sx={{ maxWidth: 20 }} />
                        </ListItemButton>
                        <Grid container direction={'column'} alignItems="center" item xs={12}>
                            <Tooltip title={item.name}>
                                <Grid pt={1}>{item.img.indexOf("wav") ? <Wave /> : <Mp3 />}</Grid> 
                            </Tooltip>
                            {/* <ImageListItemBar  title={item.name} /> */}
                        </Grid>
                    </ImageListItem>
                ))} 
        </ImageList>
      </Grid>
    )
}
export default ListFile