import { useEffect, useState } from "react";
import { Stop, PlayArrow } from "@mui/icons-material";
import { connect } from "react-redux";
import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import { ButtonProgress } from '../../containers/Buttons';
import audioTest from "../../../audio/test.mp3";

const machine = createMachine({
  predictableActionArguments: true,
  id: 'test mic',
  initial: 'inactive',
  states: {
    inactive: {
      entry: ['inactive'],
      on: { TOGGLE: 'playing' }
    },
    playing: {
      entry: ['playing'],
      on: { 
        TOGGLE: 'inactive',
      }
    }
  }
});

const ButtonTestOutput  = ({devices, setOutput, ...props }) => {
  const [audio, ] = useState(new Audio(audioTest));
  const [outputAudio, ] = useState(new Audio());
  const [ctx, ] = useState(new AudioContext());

  // machine actions
  const [state, send] = useMachine(machine, { 
    devTools: true,
    actions: {
      inactive: () => {
        audio.pause();
        audio.currentTime = 0;
      },
      playing: () => {
        outputAudio.play();
        audio.play();
      },
    },
  });

  // if audio ended
  useEffect(() => {
    audio.onended = () => { send('TOGGLE') };
    return () => { audio.onended = undefined; }
  }, [audio])

  // set output device
  useEffect(() => {
    if(!devices?.outputId || !outputAudio){
      return;
    }
    if(typeof outputAudio?.sinkId !== 'undefined') {
      outputAudio.setSinkId(devices?.outputId).catch(console.log);
    }
  }, [devices.outputId, audio]);

    // get output source
    useEffect(() => {
      const source = ctx.createMediaElementSource(audio);
      const destination = ctx.createMediaStreamDestination();
      source.connect(destination);
      setOutput(destination.stream);
      outputAudio.srcObject = destination.stream;
      return () => {
        source.disconnect();
        ctx.close();
      }
    }, []);

  return (
    <ButtonProgress 
      onClick={() => send('TOGGLE')}
      duration={`${audio.duration}s`}
      normal={state.matches('playing')}
    >
      {state.matches('inactive') ? (<PlayArrow sx={{ mr: 1 }} />) : (<Stop sx={{ mr: 1 }} />)}
      {state.matches('inactive') && 'test speakers'}
      {state.matches('playing') && `stop`}
    </ButtonProgress>
  );
};

const mapStateToProps = (state) => ({
  devices: state.media.devices
})
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ButtonTestOutput);