// import { makeStyles } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => {
  return {
    menuList: {
      padding: 0,
      flex: "1 0 auto",
      overflowY: "auto",
    },
    hidden: {
      display: "none",
    },
    itemIcons: {
      minWidth: "0px!important",
      maxHeight: "48px",
      overflow: "hidden",
      "&& li": {
        maxHeight: "48px",
        overflow: "hidden",
      },
      "&& .MuiMenuItem-gutters:hover": {
        backgroundColor: theme.palette.primary.selectedModal,
      },
      "&& svg": {
        color: theme.palette.primary.text,
      },
      color: theme.palette.primary.text,
    },
    menuItems: {
      maxWidth: "256px",
      width: "100%",
      textDecoration: "none",
      minHeight: "48px!important",
      backgroundColor: theme.palette.primary.backgroundAll,
      display: "flex",
      color: theme.palette.primary.text + "!important",
      "& .links": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.selectedModal + "!important",
        color: theme.palette.primary.hoverText + "!important",
        "& svg": {
          color: theme.palette.primary.hoverText + "!important",
        },
        "& div": {
          color: theme.palette.primary.hoverText + "!important",
        },
      },
      "& svg": {
        color: theme.palette.primary.text + "!important",
      },
    },
    ItemsTextList: {
      paddingTop: "5px",
      marginLeft: "2em",
    },
    selected: {
      minHeight: "48px!important",
      backgroundColor: theme.palette.primary.selectedModal + "!important",
      "& svg": {
        color: theme.palette.primary.hoverText + "!important",
      },
      "& div": {
        color: theme.palette.primary.hoverText + "!important",
      },
      "& a": {
        color: theme.palette.primary.hoverText + "!important",
      },
    },
  };
});

