/* eslint-disable react-hooks/exhaustive-deps */

import { connect } from "react-redux";
import { useLocation, matchPath, generatePath } from "react-router-dom";
import { AccountBoxOutlined,Mic,Tune } from "@mui/icons-material";
import { MenuItem, ListItemIcon, ListItemText, MenuList } from "@mui/material";
import { useStyles } from "./styles";
import types from "../../../types";
import { ROUTES } from "../../../constants";
import useNavigation from "../../../hooks/useNavigation";

const MenuItems = ({ interview, ...props}) => {
  const {  isMenuOpen } = props;
  const interviewId = interview?._id
  const classes = useStyles(props);
  const location = useLocation();
  const { navigate } = useNavigation();

  const routerLinks = [
    {
      path: ROUTES.HOME,
      name: "Interviews",
      icon: <Mic />,
    },{
    //   path: ROUTES.INTERVIEW,
    //   name: "Interviews",
    //   icon: <KeyboardVoiceOutlined />,
    // },{
      path: ROUTES.ADRESSBOOK,
      name: "Address book",
      icon: <AccountBoxOutlined />,
    },{
      path: interviewId ? 
        generatePath(ROUTES.AUDIO_SETTING_INTERVIEW_ID, {interviewId}) : 
        ROUTES.AUDIO_SETTING_INTERVIEW, 
      name: "Audio Settings",
      icon: <Tune />,
    }
  ];
  return (
    <MenuList className={ classes.menuList }>

      {routerLinks.map((e, i) => {
        const match = e?.path && matchPath(location.pathname, e?.path);
        return (
          <MenuItem
            key={i}
            className={ match ? classes.selected : classes.menuItems }
            onClick={(event) => {
              event.preventDefault();
              e.onClick ? e.onClick() : navigate(e.path)
            }}
          >
            <ListItemIcon className={ classes.itemIcons } >
              {e.icon}
            </ListItemIcon>
            <ListItemText className={ isMenuOpen ? classes.ItemsTextList : classes.hidden } >
              {e.name}
            </ListItemText>
          </MenuItem>
        );
      })}

    </MenuList>
  );
}


const mapStateToProps = (state) => ({
  isMenuOpen: state.app.isMenuOpen,
  interview: state.interview.item
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenuOpen: (toggle) => dispatch({ type: types.app.TOGGLE_MENU_OPEN, toggle: toggle }),
  toggleTheme: (data) => dispatch({ type: types.settings.TOGGLE_THEME, data: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);