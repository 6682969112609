
export default (theme) => ({
    components: {
        MuiFab: {
          styleOverrides: {
            root: {
                backgroundColor: theme.palette.primary.backgroundAllNumber,
                color: theme.palette.primary.text,
                "&:hover": {
                    color: theme.palette.primary.selectedModal,
                }
            },
            extended:{
              height: '54px'
            }
          }
        }
    }
})