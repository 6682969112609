import { createTheme, responsiveFontSizes } from "@mui/material";
import constants from "../constants";
import paletteDark from './paletteDark';
import paletteLight from './paletteLight';
import typography from './typography';

import MuiContainer from './components/MuiContainer';
import MuiDataGrid from './components/MuiDataGrid';
import MuiSpeedDialAction from './components/MuiSpeedDialAction';
import MuiTextField from "./components/MuiTextField";
import MuiFormControlLabel from "./components/MuiFormControlLabel";
import MuiCheckbox from "./components/MuiCheckbox";
import MuiButton from "./components/MuiButton";
import MuiDialog from "./components/MuiDialog";
import MuiBaseline from "./components/MuiBaseline";
import MuiPaper from "./components/MuiPaper";
import MuiFab from "./components/MuiFab";
import MuiBadge from "./components/MuiBadge";
import MuiDivider from "./components/MuiDivider";
import MuiDateTimePicker from "./components/MuiDateTimePicker";

const pallets = {
  [constants.themes.LIGHT]: paletteLight,
  [constants.themes.DARK]: paletteDark,
};

export default (colorTheme) => {

  // apply typography
  let theme = createTheme(
    typography(pallets[colorTheme]), 
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    }
  );

  // apply components
  theme = createTheme(theme, MuiContainer(theme));
  theme = createTheme(theme, MuiDataGrid(theme));
  theme = createTheme(theme, MuiSpeedDialAction(theme));
  theme = createTheme(theme, MuiTextField(theme));
  theme = createTheme(theme, MuiFormControlLabel(theme));
  theme = createTheme(theme, MuiCheckbox(theme));
  theme = createTheme(theme, MuiButton(theme));
  theme = createTheme(theme, MuiDialog(theme));
  theme = createTheme(theme, MuiBaseline(theme));
  theme = createTheme(theme, MuiPaper(theme));
  theme = createTheme(theme, MuiFab(theme));
  theme = createTheme(theme, MuiBadge(theme));
  theme = createTheme(theme, MuiDivider(theme));
  theme = createTheme(theme, MuiDateTimePicker(theme));
    
  // fix for header font <410px
  theme.typography.h4['@media (min-width:0px)'] = {
    fontSize: '1rem',
  };
  theme.typography.h4['@media (min-width:410px)'] = {
    fontSize: '1.5rem',
  };

  // apply responsiveFontSizes
  theme = responsiveFontSizes(theme);

  return theme;
};
