export default (theme) => ({
  components: {
    MuiNativeSelect: {
      styleOverrides: {
        root: {
          backgroundColor: `${theme.palette.primary.backgroundAll}`,
        },
        select: {
          backgroundColor: `${theme.palette.primary.backgroundAll}`,
          "&:not([multiple]) option": {
            backgroundColor: `${theme.palette.primary.backgroundAll}`,
          }
        }, 
        
      }
    },
    MuiDataGrid: {
      defaultProps: {
        autoPageSize: true
      },
      styleOverrides: {
        select: {
          backgroundColor: `${theme.palette.primary.backgroundAll}`,
        },
        paper: {
            backgroundColor: `${theme.palette.primary.backgroundAll}`,
        },
        root: {
          border: 0,
          backgroundColor: `${theme.palette.primary.backgroundAll}`,
          "& .MuiDataGrid-overlay": {
            color: `${theme.palette.primary.button}`,
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 0,
          },
          "&.MuiDataGrid-panelContent": {
            backgroundColor: `${theme.palette.primary.backgroundAll}`,
            
          },
          "& .MuiDataGrid-footerContainer": {
            paddingRight: 90,
            color: `${theme.palette.primary.text}`,
            backgroundColor: `${theme.palette.primary.backgroundAll}`,
            borderTop: 0,
            "& .MuiToolbar-regular": {
              "& .Mui-disabled": {
                color: `${theme.palette.primary.disabled}`,
              },
              color: `${theme.palette.primary.text}`,
            },
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
            backgroundColor: `${theme.palette.primary.backgroundAll}`,
          },
          "& .MuiCheckbox-colorPrimary": {
            color: `${theme.palette.primary.text}`,
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          color: `${theme.palette.primary.text}`,
          borderTop: 0,
          borderBottom: 0,
          "& .MuiDataGrid-cell": {
            borderBottom: 0,
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        },
      },
    },
  },
});