import types from "../types";

const defaultState = {
    notifications: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.notifications.ENQUEUE_SNACKBAR:
            return {
                ...state,
                // removed all messages GUEST-289
                // notifications: [
                //     ...state.notifications,
                //     {
                //         key: action.key || new Date().getTime() + Math.random(),
                //         ...action.notification,
                //     },
                // ],
            };

        case types.notifications.CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case types.notifications.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        default:
            return state;
    }
};