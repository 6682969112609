import React from "react";
import { Outlet } from "react-router-dom";
import Page from "../../containers/Page";

const AddressBook = (props) => {

  return (
    <Page titleText="ADDRESS BOOK">
      <Outlet />
    </Page>
  );
};

export default AddressBook;
