export default (theme) => ({
  components: {
    MuiSpeedDial: {
      styleOverrides: {
        root: {
          marginRight: 15,
          marginTop: -28
        },
        fab: ({ ownerState, theme }) => ({
          color: theme.palette.primary.white,
          backgroundColor: ownerState.open ? theme.palette.text.main : theme.palette.info.main,
          "&:hover": {
            backgroundColor: ownerState.open ? theme.palette.text.light : theme.palette.info.light,
          },
        }),
        actions: {
          marginRight: '0 !important',
          paddingRight: '0 !important'
        },
      }
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab:{
          color: theme.palette.primary.textFAB,
          "&:hover": {
            color: theme.palette.primary.white,
            backgroundColor: theme.palette.primary.button,
          },
        },
      },
    },
  },
});