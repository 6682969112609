export default (theme) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "6px",
        },
        "&::-webkit-scrollbar-button": {
          backgroundColor: "#fff",
          width: "14px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-track-piece": {
          backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
          height: "50px",
          backgroundColor: "#000000b0",
          borderRadius: "5px",
          opacity: 0.6,
        },
        "&::-webkit-scrollbar-corner": {
          backgroundColor: "#999",
        },
        "&::-webkit-resizer": {
          backgroundColor: "#000000b0",
          opacity: 0.6,
        },
      },
    },
  },
});
