/* eslint-disable react-hooks/exhaustive-deps */
import {Button,Grid,InputLabel,Typography,FormGroup,FormControlLabel,Checkbox} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import types from "../../../types";
import { PATH } from "../../../constants";
import useForm from "../../../hooks/useForm";
import { validators } from "./Validator"
import HelpTip from "../../containers/HelpTip";
import AutocompleteContacts from "../../containers/AutocomplateContacts";
import TemplateAccordion from "./templateAccordion";
import useNavigation from "../../../hooks/useNavigation";
import DateTimeAccordion from "./dateTimeAccordion";
import SettingAccordion from "./settingAccordion";
import { generatePath, useParams } from "react-router-dom";
import { FETCH, ROUTES } from "../../../constants";
import Preloader from "../../containers/Preloader";

const Form = ({ contacts, template, languages, fetchFinished, newContact, item, getContact, sendInvite, isSendInvite, updateState, getKeyForLink, keyForLink, ...props}) => {
  const [openDuration, setOpenDuration] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { interviewId } = useParams();
  const [sendMail, setSendMail] = useState(false);
  const { setAskBeforeNavigate, navigate } = useNavigation()
  useEffect(() => { (isSendInvite && sendMail) && navigate(generatePath(ROUTES.INTERVIEW_SUCCESS_SEND, { interviewId: interviewId })) }, [isSendInvite])
  useEffect(() => { (isSendInvite && !sendMail) && setAskBeforeNavigate(false) && navigate(generatePath(ROUTES.INTERVIEW_EDIT, { interviewId: interviewId })) }, [isSendInvite])
  useEffect(() => getKeyForLink(), [])
  const initState = {
    _id: interviewId,
    name: '',
    email: '',
    dateTo: '',
    dateFrom: '',
    duration: '',
    languageTemplate: 'en',
    bodyTemplate: '',
    expire: false,
    settingsForGuest: false,
    echo: false,
    control: false,
    noise: false,
    filter: false,
    isSendInvite: false,
    inviteLink: `${PATH.GUEST_URL}${interviewId}`,
    googlecalendar: "",
    outlook: ""
  }
  const submitForm = () => {
    setAskBeforeNavigate(false)
    setSendMail(state.email !== "")
    sendInvite(state)
    updateState()
  }
  const {
    handleChange,
    handleStateChange,
    handleSubmit,
    setInitState,
    handleBlur,
    state,
    isChanged,
  } = useForm({initState, validators, callback: submitForm })
  const isEmail = useMemo(() => {
   return isDisabled || state.email === ''
  }, [state, isDisabled])
  useEffect(() => {
    setIsDisabled(typeof item?.invite?.name === 'undefined' && state.name === '' )
  }, [state?.name, state?.dateFrom])
  useEffect(() => setAskBeforeNavigate(isChanged), [isChanged]);
  useEffect(() => isDisabled && handleStateChange({ isSendInvite: false }), [isDisabled]);
  useEffect(() => { 
    item?.invite && setInitState(Object.assign(initState, item.invite));
    handleStateChange({ outlook: `${window.location.origin}/nodeGuest/api/v1/calendar/ics/${keyForLink}`})
    if(newContact) handleStateChange({ name: newContact.name, email: newContact?.email })
  }, [ fetchFinished, newContact !== null ])
  const onClose = () => updateState() && navigate(generatePath(ROUTES.INTERVIEW_EDIT, { interviewId: interviewId }))
  const triggerDuration = (panelid) => {
    if (panelid === openDuration) {
      setOpenDuration("");
      return;
    }
    setOpenDuration(panelid);
  }; 
  const generateDate = (date) => {
    return !!date && new Date(date).toISOString().replace(/-/g,'').replace(/:/g,'')
  }
  const generateLink = () => {
    let selectTmpl = template?.find((tmpl) => tmpl.language === state.languageTemplate)
    let detailsIcs = `${state.bodyTemplate} <br/><br/>` + 
    `<a href="${state.inviteLink}">${selectTmpl?.button.toUpperCase()}</a>` + 
    `<br/><br/>${selectTmpl?.browser}` + 
    `<br/><a href="${state.inviteLink}">${state.inviteLink}</a>` + 
    `<br/><br/>${selectTmpl?.headset}`
    return `https://www.google.com/calendar/render?action=TEMPLATE` + 
    `&text=${state.bodyTemplate}` + 
    `&dates=${generateDate(state.dateFrom) + 
    '/' + generateDate(state.dateTo)}` + 
    `&details=${encodeURIComponent(detailsIcs)}&sf=true&output=xml`
  }
  useEffect(() => {
    (state && template) && handleStateChange({ googlecalendar: generateLink() })
  }, [state.dateTo, state.dateFrom ])
  return (
    <Grid loading={`${fetchFinished}`} sx={{ bgcolor: "primary.backgroundAll" }} justifyContent="center" component="form" container item xs={12}>
      {!fetchFinished ? <Grid sx={{ minHeight: "550px" }}><Preloader  /> </Grid> : <>
      <Grid container mt={1.5} justifyContent="center" item xs={12}>
        <Typography sx={{ color: "text.secondary" }} variant="markersText">
          Invite your guest
        </Typography>
      </Grid>
      <Grid container mt={1.5} justifyContent="center" item xs={12}>
        <Grid item xs={11.5} sm={10} md={8}>
          <InputLabel sx={{ mb: 1.2, color: "primary.text" }}>
            Choose contact from Address book
            <HelpTip children="Choose contact from Address book"/>
          </InputLabel>
        </Grid>
        <Grid item xs={11.5} sm={10} md={8}>
            <AutocompleteContacts 
              state={state}
              setIsDisabled={setIsDisabled}
              openDuration={openDuration}
              triggerDuration={triggerDuration}
              handleStateChange={handleStateChange}
              />
        </Grid>
      </Grid>
      <Grid item xs={11.5} sm={10} md={8}>
        <DateTimeAccordion 
          openDuration={openDuration} 
          handleChange={handleChange}
          triggerDuration={triggerDuration}
          state={state}
          handleStateChange={handleStateChange}
        />
      </Grid>
      <Grid item xs={11.5} sm={10} md={8}>
        <SettingAccordion 
          openDuration={openDuration} 
          handleChange={handleChange}
          triggerDuration={triggerDuration}
          state={state}
          handleBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={11.5} sm={10} md={8}>
        <TemplateAccordion 
          openDuration={openDuration} 
          handleChange={handleChange}
          triggerDuration={triggerDuration}
          handleStateChange={handleStateChange}
          state={state}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center" item xs={12}>
            <Grid container justifyContent="center" item xs={6}>
              <FormGroup>
                <FormControlLabel 
                  control={<Checkbox
                  name="isSendInvite"
                  disabled={isEmail}
                  checked={state.isSendInvite}
                  onChange={handleChange}
                  onBlur={handleBlur}
                 />} label="Send invite via email" />
              </FormGroup>
            </Grid>
        </Grid>
      </Grid>
      <Grid container mb={10} justifyContent="center" item xs={12}>
        <Grid container mt={2} spacing={4} item xs={3}>
            <Grid item xs={6}>
                <Button color="orange" variant="outlined" onClick={onClose}>
                    close
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button disabled={isDisabled} color="orange" onClick={handleSubmit} variant="contained">
                    save
                </Button>
            </Grid>
        </Grid>
      </Grid>
    </>
    }
    </Grid>
  );
};
const mapStateToProps = (state) => ({
    contacts: state.contacts.items,
    newContact: state.contacts.newContact,
    keyForLink: state.interview.keyForLink,
    isSendInvite: state.interview.isSendInvite,
    template: state.template.template,
    item: state.interview.item,
    fetchFinished: state.interview.fetchStatus === FETCH.FINISHED
});
const mapDispatchToProps = (dispatch) => ({
    getContact: (data) => dispatch({ type: types.contacts.GET_CONTACT, data: data }),
    getKeyForLink: () => dispatch({ type: types.interview.GET_KEY_FOR_LINK }),
    sendInvite: (data) => dispatch({ type: types.interview.SEND_INVITE_INTERVIEW, data: data }),
    updateState: () => dispatch({ type: types.contacts.DEFAULT_CONTACT_SUCCESS })
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
