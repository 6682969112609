/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { generatePath } from "react-router";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";

import types from "../../../types";
import { FETCH, ROUTES, PATH } from "../../../constants";
import Preloader from "../../containers/Preloader";
import FloatActionsManageRecording from "./FloatActions";
import useNavigation from "../../../hooks/useNavigation";
import { Button, Grid, Box, Typography, Tooltip, Link } from "@mui/material";
import { AutoFixHigh, FileDownload } from "@mui/icons-material";
import DeleteDialog from "../../containers/Dialog/DeleteDialog";
import NoRowsText from "../../containers/NoRowsText";

const columns = [
  {
    field: "__check__",
    hideable: false,
    sortable: false,
    editable: false,
    width: 55
  },{
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    editable: true,
    description: "Click to download recording",
    renderCell: (data) => 
    <Tooltip title="Click to download recording">
      <Link component="button" sx={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          const path = `${PATH.API_BASE_URL}v1/managerecording/audio/${data.row._id}`;
          window && window.open(path, '_blank');
        }}
      > 
        <FileDownload sx={{ mr: 1 }}/>{data.value || ''}
      </Link>
    </Tooltip>
  },
  {
    field: "db_last_update",
    headerName: "Date",
    type: "date",
    flex: 1,
    sortable: false,
    editable: false,
    renderCell: (data) => {
      var optionsDate = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var optionsTime = {
        hour: "numeric",
        minute: "numeric",
      };
      let date, time;
      date = new Intl.DateTimeFormat("en-US", optionsDate).format(new Date(data.value));
      time = new Intl.DateTimeFormat("en-US", optionsTime).format(new Date(data.value));
      return date + " " + time
    }
  },

  {
    field: "duration",
    type: "string",
    headerName: "Duration",
    sortable: false,
    flex: 1,
  },
];
const List = ({ items, loading, changeSelected, getRecordings, deleteRecording, renameRecording, ...props }) => {
  const { interviewId } = useParams();
  const [selected, setSelected] = useState([]); 
  const [name, setName] = useState("");
  const [editing, setEditing] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [cellModesModel, setCellModesModel] = useState({});
  const pageSize = FETCH.PAGE_SIZE;
  const { navigate } = useNavigation();
  const isCountSelected = selected.length <= 0
  useEffect(() => {
    getRecordings({ id: interviewId, pageLength: pageSize, skip: 0 });
  }, [items.length <= 0]);
  const onDelete = () => setOpenConfirm(true);
  const onCancelDelete = () => setOpenConfirm(false);
  const onConfirmDelete = () =>{ 
    setOpenConfirm(false); 
    deleteRecording({ id: interviewId, _ids: selected }) 
  }
  const onClose = () => setSelected([]) 
  const onPagePostProduction = () => {
    changeSelected(selected)
    navigate(generatePath(ROUTES.EDIT_RECORDINGS, { interview_id: interviewId }));
  };
  const onAdd = () => {
    navigate(ROUTES.INTERVIEWERS);
  };
  const onRename = useCallback((e) => {
    e.stopPropagation(); 
    setEditing(true);
    setName(getName(selected[0]));
  });
  const getName = (id) => {
    const item = items.filter((i) => i._id === id)
    return item && item.length && item[0].name;
  };
  
  useEffect(() => {
    selected && changeSelected()
  }, [selected])
  const isEditing = useCallback(() => {
    return Object.keys(cellModesModel).reduce((acc, id) => 
      acc || Object.keys(cellModesModel[id]).reduce(
          (acc2, field) => acc2 || (cellModesModel[id][field]?.mode === GridCellModes.Edit)
        , false)
      , false);
  }, [cellModesModel]);
  useEffect( () => {
    const id = selected[0]
    const field = 'name';
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce((acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce((acc2, field) => ({
            ...acc2,
            [field]: { mode: GridCellModes.View }
          }), {})
        }),{}),
        ...(id && editing && {
          [id]: { 
          ...cellModesModel[id], 
          [field]: { mode: GridCellModes.Edit } 
        }}),
      }
    });
  }, [editing, selected]);
  const renameSubmit = (value) => {
    const id = selected[0];
    const cur = getName(id);
    setEditing(false);
    setName(null);
    id && value && (cur !== value) && renameRecording({
      _id: id,
      name: value,
    });
  }

  const handleCellKeyDown = (params, event, details) => {
    if (event.keyCode === 13) {
      renameSubmit(event.target.value)
    }
  };

  const onCellFocusOut = useCallback((event, p) => {
    if (!editing) return;
    p.defaultMuiPrevented = true;
    renameSubmit(name);
  }, [name]);

  const onChangeHandle = (event) => (event.target.type !== "checkbox") && setName(event.target.value);

  const handleCellModesModelChange = (newModel) => !isEditing() && setSelected(newModel);

  return (
     <Box sx={{ height: "calc(100% - 40px)"}}>
       <Grid container justifyContent="space-between" pl={3} pr={3} >
         <Grid item xs>
           <Typography variant="manageSmall">YOUR RECORDINGS</Typography>
         </Grid>
         <Grid container item width={250} gap={3} wrap="nowrap">
             <Button onClick={onPagePostProduction} disabled={isCountSelected} variant="cBlue" >
               <AutoFixHigh sx={{ mr: 1 }} /> make your montage
             </Button>
         </Grid>
       </Grid>
      <DataGrid
        sx={{ bgcolor: "white" }}
        rows={items}
        columns={columns}
        loading={loading}
        onCellFocusOut={onCellFocusOut}
        onCellKeyDown={handleCellKeyDown}
        cellModesModel={cellModesModel}
        isRowSelectable={(e) => !editing}
        components={{ 
          LoadingOverlay: Preloader,
          NoRowsOverlay: () => (<NoRowsText text="There are no recordings available" />)
        }}
        componentsProps={{ cell: { onChange: onChangeHandle } }}
        getRowId={(row) => row._id}
        selectionModel={selected}
        onSelectionModelChange={handleCellModesModelChange}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{
          newEditingApi: true,
          preventCommitWhileValidating: true,
        }}
        checkboxSelection
        disableSelectionOnClick
      />
      <FloatActionsManageRecording
        selected={selected}
        onRename={onRename}
        onDelete={onDelete}
        onClose={onClose}
        onAdd={onAdd}
      />
      <DeleteDialog 
        open={openConfirm}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  items: state.managerecordings.items,
  status: state.managerecordings.status,
  loading: state.managerecordings.fetchStatus === FETCH.LOADING,
  token: state.contacts.token,
});
const mapDispatchToProps = (dispatch) => ({
  changeSelected: (data) => dispatch({ type: types.recordingsmanage.CHANGE_SELECTED, data }),
  getRecordings: (data) => dispatch({ type: types.recordingsmanage.GET_MANAGE_RECORDING, data }),
  deleteRecording: (data) => dispatch({ type: types.recordingsmanage.DELETE_MANAGE_RECORDING, data }),
  renameRecording: (data) => dispatch({ type: types.recordingsmanage.UPDATE_MANAGE_RECORDING, data })
});
export default connect(mapStateToProps, mapDispatchToProps)(List);
