import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MicNone, CloudQueue } from "@mui/icons-material";
import { connect } from "react-redux";
import { css } from "styled-components";
import * as animate from "./animations";
import { JANUS } from "../../../constants";

const blinkrec = css([`blinkrec 1.5s linear 1s infinite running `, '']);
const moveGradient = css([`moveGradient 1.5s linear 1s infinite running `, '']);

const Timer = ({ seconds, isPause, callState, ...props}) => {
  const [timer, setTimer] = useState({});
  const { GridAnimated, FiberManualRecordTwoToneAnimated } = animate
  const zeroPad = (num, places) => String(num).padStart(places, '0');

  const getSeconds = (seconds) => zeroPad(seconds % 60, 2);
  const getMinutes = (seconds) => zeroPad(Math.floor(seconds / 60) % 60, 2);
  const getHours = (seconds) => zeroPad(Math.floor(seconds / 3600), 2);

  useEffect( () => {
    setTimer({
      seconds,
      sec: getSeconds(seconds),
      min: getMinutes(seconds),
      hour: getHours(seconds),
    })
  }, [seconds]);

  return (
      <Grid
        container
        sx={{
          alignItems:"center",
          borderRadius: "5px",
          backgroundColor: 'primary.backgroundAll',
          minHeight: 132,
          mb: 2
        }}
      >
        <Grid alignItems="center" justifyContent="center" container item xs={3.5} flexDirection="column">
          <Grid container alignItems='center' justifyContent='center' wrap="nowrap">
            <Typography variant="biggest">Rec</Typography>
            <FiberManualRecordTwoToneAnimated 
              sx={{
                "& path:nth-of-type(2)": {
                  color: (callState === JANUS.IN_CALL) ? "#EA4D6F" : "primary.text",
                  animation: `${(callState === JANUS.IN_CALL) && "blinkrec 1.5s linear 1s infinite running "}`,
                },
                "& path": {
                  opacity: 1,
                  color: `${(callState === JANUS.IN_CALL) ? "#EA4D6F" : "primary.backgroundAll"}`,
                  animation: (callState === JANUS.IN_CALL) ? blinkrec : '',
                },
              }}
            />
          </Grid>
          {(callState === JANUS.IN_CALL) && 
            <Grid container item flexDirection="column" width={80} sx={{
              color: 'primary.redText',
              animation: "blinkrec 1.5s linear 1s infinite running "
            }}>
              <Typography variant="subtitle" sx={{fontSize: "70%"}}><CloudQueue sx={{height: "14px"}}/>Cloud</Typography>
              <Typography variant="subtitle" sx={{fontSize: "70%"}}><MicNone  sx={{height: "14px"}} />Local</Typography>
            </Grid>
          }
        </Grid>
        <GridAnimated justifyContent='center' container item xs={8}
          sx={{
            backgroundImage: `${(callState === JANUS.IN_CALL)
                ? "linear-gradient(to top right, #EA4D6F,#EA4D6F38)"
                : "#fff"}`,
            animation: (!isPause && (callState === JANUS.IN_CALL)) ? moveGradient : '',
          }}
        >
          <Grid flexDirection='row' justifyContent='center' color="primary" container item xs={12}
          sx={{ 
            borderRadius: "100px",
            minHeight: 72,
            margin: '0.2em',
            alignItems: "center",
            backgroundColor: "primary.backgroundAllNumber",
          }}>
            <Grid container justifyContent="center" item xs={2}>
              <Typography
                sx={{ color: `${(callState === JANUS.IN_CALL) && "primary.redText"}` }}
                variant="numbers"
              >
                {timer?.hour || '00'}
              </Typography>
            </Grid>
            <Typography variant="numbers" mb={1}>:</Typography>
            <Grid container justifyContent="center" item xs={2}>
              <Typography
                sx={{ color: `${(callState === JANUS.IN_CALL) && "primary.redText"}` }}
                variant="numbers"
              >
                {timer?.min || '00'}
              </Typography>
            </Grid>
            <Typography variant="numbers" mb={1}>:</Typography>
            <Grid container justifyContent="center" item xs={2}>
              <Typography
                sx={{ color: `${(callState === JANUS.IN_CALL) && "primary.redText"}` }}
                variant="numbers"
              >
                {timer?.sec || '00'}
              </Typography>
            </Grid>
          </Grid>
        </GridAnimated>
      </Grid>
  );
};

const mapStateToProps = (state) => ({
  seconds: state.janus.recording_time || 0,
  callState: state.janus.call_state,
})
const mapDispatchToProps = (dispatch) => ({
  // getInterview: (data) => dispatch({ type: types.interview.GET_INTERVIEW, data }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Timer);
