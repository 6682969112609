import types from "../types";

const initialStore = {
  items: [],
  item: [],
  markers: [],
  urls: '',
  selectedItems: [],
  fetchStatus: false,
  status: false,
  link: "",
  request: false,
  recording_id: null,
};
const managerecordings = (state = initialStore, action) => {
  switch (action.type) {
    case types.recordingsmanage.GET_MANAGE_RECORDING_SUCCESS:
      return {
        ...state,
        items: action.payload.data ? action.payload.data : [],
        status: action.payload.data.length > 0,
      };
    case types.recordingsmanage.GET_RECORDING_SUCCESS:
      return {
        ...state,
        item: [],
        status: action?.payload?.data?.length > 0,
      };
    case types.recordingsmanage.GET_MARKERS_SUCCESS:
      return {
        ...state,
        markers: action?.payload.data,
        status: action?.payload?.data?.length > 0,
      };
    case types.recordingsmanage.GET_AUDIO_URL_SUCCESS:
       return {
          ...state,
          urls: [],
        };
    case types.recordingsmanage.FETCH_STATUS:
      return {
        ...state,
        ...action,
      };
    case types.recordingsmanage.UPDATE_MANAGE_RECORDING_SUCCESS:
      return {
        ...state,
        items: [],
        item: [],
        ...action,
      };
    case types.recordingsmanage.UPDATE_MARKERS_RECORDING_SUCCESS: {
        return {
          ...state,
          item: {
            ...state.item,
            markers: action?.payload.data,
          }
        };
    }
    case types.recordingsmanage.CHANGE_SELECTED:
      return {
        ...state,
        selectedItems: action.data
      };
    case types.recordingsmanage.DELETE_MANAGE_RECORDING_SUCCESS:
      return {
        ...state,
        items: [],
        item: {},
        ...action,
      };
    case types.recordingsmanage.CREATE_MANAGE_RECORDING_SUCCESS:
      return {
        ...state,
        item: action.payload.data ? action.payload.data : {},
      };
    case types.recordingsmanage.SEND_TO_EMAIL_MANAGE_RECORDING_SUCCESS:
      return {
        ...state,
        ...action,
      };
    case types.recordingsmanage.SEND_TO_FTP_MANAGE_RECORDING_SUCCESS:
      return {
        ...state,
        ...action,
      };
    default:
      return {
        ...state,
      };
  }
};

export default managerecordings;
