import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { JANUS, MEDIA } from '../constants';
import useIndexedDb from './useIndexedDb';

// let audio_ctx = new (window.AudioContext || window.webkitAudioContext)();
// let mediaStream;

const useAudio = () => {
  const {  clearIndexedDB,getAllBlobs,invokeSaveAsDialog,onDataAvailable } = useIndexedDb();
  const devices = useSelector(state => state.media.devices || {});
  const recordingLocal = useSelector(state => state.janus.recording.local);
  const [inputSource, setInputSource] = useState(null);

  // const [chunks, setChunks] = useState([]);
  // const [blob, setBlob] = useState(null);

  useEffect(()=>{
    let mediaRecorder;
    if(inputSource && recordingLocal === MEDIA.RECORDING){
      mediaRecorder = new MediaRecorder(inputSource, {
        mimeType: 'audio/webm',
        audioBitsPerSecond: 320000
      });
      mediaRecorder.start(1000);
      mediaRecorder.ondataavailable = onDataAvailable;
    }
    return () => {
      mediaRecorder?.stop();
    }
  },[recordingLocal, inputSource])


  // get input source
  useEffect(() => {
    const media = { audio: {deviceId: devices.inputId } };
    navigator?.mediaDevices?.getUserMedia(media)
      .then(setInputSource)
      .catch(console.log);
  }, [devices.inputId]);

  // cleanup input source
  useEffect(() => () => {
    inputSource?.getTracks().forEach(function(track) {
      track.stop();
      inputSource.removeTrack(track);
    });
  }, [inputSource]);

  // const onDataAvailable = ({ data }) => setChunks(chunks => [...chunks, data])
  
  // const onStopRecord = () => {
  //   mediaStream.stop()
  //   setBlob(null)
  //   setBlob(new Blob(chunks, { type: "audio/mp3;codecs=opus" }))
  //   setChunks([]);
  // };

  // const onStartRecord = () => {
  //     setBlob(null)
  //     mediaStream.start(950);
  //     mediaStream.ondataavailable = onDataAvailable;
  // };

  return {
    inputSource,
    // onStopRecord,
    // mediaStream,
    // audioCtx: audio_ctx,
    // blob,
    // setMediaStream: () => {},
  };
};

export default useAudio;
