/* eslint-disable react-hooks/rules-of-hooks */
import { call, put } from "redux-saga/effects";
import types from "../../types";
import { fetchWrapper } from "../fetchings";

export function* getContacts(action) {
  const response = yield call(fetchWrapper().post, "v1/addressbook/getAll", action.data);
  yield put({ type: types.contacts.GET_CONTACT_SUCCESS, payload: response });
}

export function* fetchContact(action) {
  const response = yield call(fetchWrapper().post, "v1/addressbook/getOne", action.data );
  yield put({ type: types.contacts.EDIT_CONTACT_SUCCESS, payload: response });
}

export function* defaultVariables(action) {
  yield put({ type: types.contacts.DEFAULT_CONTACT_SUCCESS });
}

export function* fetchSaveContact(action) {
  const response = yield call( fetchWrapper().put, "v1/addressbook/update", action.data );
  yield put({ type: types.contacts.UPDATE_CONTACT_SUCCESS, payload: response });
}

export function* createContact(action) {
  const response = yield call( fetchWrapper().post, "v1/addressbook/create", action.data );
  yield put({ type: types.contacts.ADD_CONTACT_SUCCESS, payload: response });
}

export function* fetchDeleteContact(action) {
  const response = yield call( fetchWrapper().delete, "v1/addressbook/delete", action.data );
  yield put({ type: types.contacts.DELETE_CONTACT_SUCCESS, payload: response });
}

export function* switchForm(action) {
  yield put({ type: types.contacts.OPEN_SOME_FORM_SUCCESS, payload: action.data });
}
