import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const NotFound = (props) => {
  return (
    // <Paper
    //   sx={{
    //     width: "100%",
    //     height: "100%",
    //   }}
    //   elevation={0}
    // >
      <Grid item xs={12}>
        <Grid
          sx={{
            minHeight: 300,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            mb: 5
          }}
          item
          xs={12}
        >
          <Typography
            sx={{
              color: "primary.button",
            }}
            variant="numberNotFound"
          >
            404
          </Typography>
        </Grid>
        <Grid
          sx={{
            minHeight: 100,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
        >
          <Typography
            sx={{
              color: "primary.button",
            }}
            variant="biggest"
          >
            This page not found
          </Typography>
        </Grid>
      </Grid>
    // </Paper>
  );
};
export default NotFound;
