/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Grid, Link, Tooltip, Typography } from "@mui/material";
import { Link as LinkIcon } from "@mui/icons-material";
import { JANUS, PATH } from "../../../constants";
import AudioVisual from "../../containers/AudioVisual";
import { connect } from "react-redux";
import AvatarComponent from "../../containers/Avatar";

const Contacts = ({ profile, item, connected, registered, callState, localSource, remoteSource, ...props }) => {
  const [copyLink, setCopyLink] = useState(false);
  const { interviewId } = useParams()

  const onCopyLink = (input) => {
    navigator && navigator.clipboard.writeText(`${PATH.GUEST_URL}${interviewId}`);
    setCopyLink(true);
  };

  return (
    <Grid 
      container
      sx={{ 
        bgcolor: "primary.backgroundAll",
        borderRadius: "5px"
      }}
      style={{flexGrow: 1}} 
    >
      <Grid container item xs={12}>
        <Grid container item xs={3.5} sx={{ alignItems: "flex-end", justifyContent: "center" }} >
          <AvatarComponent 
            profile={profile} 
            name="You" 
            title={registered && connected ? 'Ready' : ''} 
            network={false} 
            color={ Object.entries({
              'yellow': callState === JANUS.INCOMING_CALL,
              'green': connected,
              'red': !connected,
              '': true,
            }).filter(([key, value]) => !!value)[0][0] } 
            animate={[JANUS.INCOMING_CALL, JANUS.IN_CALL].includes(callState)} 
          />
        </Grid>
        <Grid alignItems='center' justifyContent='center' container item xs={8}>
          <Grid height={72} container item xs={12} sx={{ 
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "72px",
            bgcolor: 'primary.backgroundAllNumber',
            minHeight: "72px",
          }} >
            <AudioVisual stream={localSource} start={callState === JANUS.IN_CALL}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid height="15px" justifyContent='center' container item xs={12}>
        <Divider width="88%" />
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={3.5} alignItems="start" justifyContent="center">
          <AvatarComponent 
            profile={item?.invite} 
            name={item?.invite?.name || "Guest"} 
            color={ Object.entries({
              'yellow': [JANUS.INCOMING_CALL, JANUS.CALLING].includes(callState),
              'green': callState === JANUS.IN_CALL,
              '': true,
            }).filter(([key, value]) => !!value)[0][0] } 
            animate={[JANUS.INCOMING_CALL, JANUS.IN_CALL].includes(callState)} 
          />
        </Grid>
        <Grid alignItems='start' justifyContent='center' container item xs={8}>
          <Grid height={72} container item xs={12} sx={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "72px",
            bgcolor: 'primary.backgroundAllNumber',
            minHeight: "72px",
          }}>
            {[
              {
                cond: callState === JANUS.INCOMING_CALL,
                comp: <Grid>Incoming Call...</Grid>
              },{
                cond: callState === JANUS.CALLING,
                comp: <Grid>Calling...</Grid>
              },{ 
                cond: !!remoteSource,
                comp: <AudioVisual stream={remoteSource} start={callState === JANUS.IN_CALL} />
              },{
                cond: item?.invite,
                comp: <Tooltip 
                  open={copyLink} 
                  onClose={() => setCopyLink(false)}
                  placement="bottom-start" arrow title="Copied!"
                >
                  <Link href="#" onClick={onCopyLink}>
                    <LinkIcon sx={{ mr: 1.2, transform: "rotate(45deg)" }}  /> 
                    <Typography variant="small">Copy invite link</Typography>
                  </Link>
                </Tooltip>
              },{
                cond: true,
                comp: <Grid>Is not invited yet</Grid>
              }
            ].find(e => e?.cond)?.comp || <></>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  item: state.interview.item,
  profile: state.app.profile,
  systemStatus: state.interview.systemStatus,
  connected: state.janus.connected,
  registered: state.janus.registered,
  callState: state.janus.call_state,
})
export default connect(mapStateToProps)(Contacts);
