import types from "../types";

const initialStore = {

};
const todos = (state = initialStore, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default todos;
