/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
} from "@mui/material";
import { Logout, SettingsOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import types from "../../../types";
import { ROUTES } from "../../../constants";
import useNavigation from "../../../hooks/useNavigation";

const ProfileAvatar = ({ profile, getProfile, logout, ...props }) => {
  
  const classes = makeStyles((theme) => {
    return {
      menuLink: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none !important",
      },
    };
  })();

  useEffect(() => getProfile(), []);
  const { navigate } = useNavigation();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = (event) => setAnchorElUser(null);
  const LogoutHndle = useCallback((event) => logout(), []);

  return (
    <Grid {...props}>
      <Tooltip
        title={`${profile?.identity?.firstname} ${profile?.identity?.lastname}`}
      >
        <IconButton onClick={handleOpenUserMenu} sx={{ pr: 2 }}>
          <Avatar
            alt={`${profile?.identity?.firstname || ''} ${profile?.identity?.lastname || ''}`}
            src={ profile?.identity?.picture || profile?.identity?.firstname || null }
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          "& .MuiMenu-paper": { bgcolor: "primary.backgroundAll" },
          mt: "45px",
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          sx={{ color: "primary.text" }}
          onClick={(event) => {
            navigate( ROUTES.SETTINGS );
            handleCloseUserMenu(false);
          }}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem  sx={{ color: "primary.text" }} onClick={LogoutHndle}>
          <Link
            sx={{ color: "primary.text" }}
            className={classes.menuLink}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </Link>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default connect(
  (state) => ({ profile: state.app.profile }),
  (dispatch) => ({
    logout: () => dispatch({ type: types.app.LOGOUT }),
    getProfile: () => dispatch({ type: types.app.GET_USER_PROFILE_DATA }),
  })
)(ProfileAvatar);
