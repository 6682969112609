import { Grid } from "@mui/material";
import React from "react";
import { TailSpin } from "react-loader-spinner";

const Preloader = (props) => {
  return (
    <Grid
      sx={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        color: "#F0F1F4",
        backgroundColor: "primary.backgroundAll",
        "& svg": {
          color: "#F0F1F4",
        },
      }}
    >
      <TailSpin color="#F49744" />
    </Grid>
  );
};
export default Preloader