import { Grid, Typography } from "@mui/material"

const NoRowsText = ({ text, ...props }) => {
    return (
        <Grid  width="100%" height="100%" container justifyContent="center" >
            <Grid width="99%" container height="100%" justifyContent="center" alignItems='center'>
                <Typography color="noRows.main" variant="middleText" >{text}</Typography>
            </Grid>
        </Grid>
    )
}
export default NoRowsText