import { experimental_sx as sx } from '@mui/material/styles';

export default (theme) => ({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: sx({
          mb: 3,
          maxWidth: 540,
          width: "100%",
          "& label.Mui-focused": {
            color: "primary.text",
          },
          "& label": {
            color: "primary.text",
          },
          "& input:-webkit-autofill,input:-webkit-autofill:hover, input:-webkit-autofill:focus,input:-internal-autofill-selected,input:-internal-autofill-selected:focus":
            {
              textFillColor: (theme) => theme.palette.primary.text,
              transition: "background-color 5000s ease-in-out 0s",
              fill: "green",
            },
          "& .MuiInput-underline:after": {
            borderBottomColor: "primary.borderInput",
          },
          "& .MuiOutlinedInput-root": {
            color: "primary.text",
        
            "& fieldset": {
              borderColor: "primary.borderInput",
            },
            "&:hover fieldset": {
              borderColor: "primary.borderInput",
            },
            "&.Mui-focused fieldset": {
              borderColor: "primary.borderInput",
            },
          },
        })
      }
    },
  },
});