import { experimental_sx as sx } from '@mui/material/styles';

export default (theme) => ({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: sx({
          color: "primary.text",
          "&.Mui-checked": {
            color: "primary.checkbox",
          },
        })
      }
    },
  },
});