/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import types from "../../../types";
import { ROUTES, FETCH } from "../../../constants";
import useForm from "../../../hooks/useForm";
import useNavigation from "../../../hooks/useNavigation";
import { validators } from "./Validator";

const FormContact = ({items, addContact, editContact, getOne, fetchFinished, item, ...props}) => {
  const [ req , setReq ] = useState(false);
  const { contactId = null, outside = null} = useParams();
  const { navigate, setAskBeforeNavigate } = useNavigation();
  useEffect(() => {
    contactId && getOne({ _id: contactId })  
  }, [])
  const initState = {
    name: "",
    email: "",
    phone: "",
    second_phone: "",
    shared: false,
  };  

  const submit = () => {
    setAskBeforeNavigate(false);
    setReq(true);
    contactId ? editContact({ _id: contactId, ...state}) : addContact(state);
  };

  const handleCancel = () =>  navigate(outside ? -1 : ROUTES.ADRESSBOOK)
 
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setInitState,
    state,
    errors,
    isChanged,
    isValidErrors,
  } = useForm({ initState, validators, callback: submit });
  useEffect(() => { 
    if(contactId && item?.length > 0) {
      initState.name = item[0]?.name || '';
      initState.email = item[0]?.email || '';
      initState.phone = item[0]?.phone || '';
      initState.second_phone = item[0]?.second_phone || ''; 
      setInitState(initState);
    }
  }, [ fetchFinished, item ])

  let isValidForm = !isValidErrors();
  useEffect(() => setAskBeforeNavigate(isChanged), [isChanged]);
  useEffect(() => {
    if(req && fetchFinished)
      navigate(outside ? -1 : ROUTES.ADRESSBOOK);
  }, [fetchFinished, req]);
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label="Name"
        name="name"
        value={state.name}
        helperText={errors.name}
        error={errors.name ? true : false}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          min: 2,
          max: 50,
          startAdornment: " ",
          placeholder: "Name of your guest",
        }}
      />
      <TextField
        label="E-mail"
        name="email"
        value={state.email}
        helperText={errors.email}
        error={errors.email ? true : false}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          type: "email",
          startAdornment: " ",
          placeholder: "E-mail of your guest",
        }}
      />
      <TextField
        label="Phone number"
        name="phone"
        value={state.phone}
        helperText={errors.phone}
        error={errors.phone ? true : false}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{ maxLength: 21 }}
        InputProps={{
          startAdornment: " ",
          placeholder: "Phone number of your guest",
        }}
      />
      <TextField
        label="Second phone number"
        name="second_phone"
        value={state.second_phone}
        helperText={errors.second_phone}
        error={errors.second_phone ? true : false}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{ maxLength: 21 }}
        InputProps={{
          startAdornment: " ",
          placeholder: "Second phone number of your guest (optional)",
        }}
      />
      {/* <Box sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox 
              defaultChecked={state.phoneSecond}
              onChange={handleChange} />
          }
          label="Share this contact with other users of my organization"
        />
      </Box> */}
      <Box sx={{ mb: 2, display: "flex" }}>
        <Button sx={{ mr: 2}} onClick={handleCancel} color="orange" variant="outlined">
          Cancel
        </Button>
        <Button disabled={!isValidForm} onClick={handleSubmit} color="orange" variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  items: state.contacts.items,
  item: state.contacts.item,
  fetchFinished: state.contacts.fetchStatus === FETCH.FINISHED,
});
const mapDispatchToProps = (dispatch) => ({
  editContact: (data) => dispatch({ type: types.contacts.SAVE_CONTACT, data: data }),
  getOne: (data) => dispatch({ type: types.contacts.EDIT_CONTACT, data: data }),
  addContact: (data) => dispatch({ type: types.contacts.ADD_CONTACT, data: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContact);
