import { AccountBoxOutlined, Add, Search } from '@mui/icons-material';
import { Autocomplete, createFilterOptions, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { FETCH, ROUTES } from '../../../constants';
import useNavigation from '../../../hooks/useNavigation';
import types from '../../../types';
import EmptyAvatar from '../EmptyAvatar';
const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });
const AutocompleteContacts = ({ contacts, getContact, setIsDisabled, newContact, item, state, handleStateChange, ...props}) => {
    const isEmptyContacts = contacts.length === 0;
    const pageSize = FETCH.PAGE_SIZE;
    const [isActive, setIsActive] = useState(false);
    const { navigate } = useNavigation();
    const updateState = (name, email) => {
      handleStateChange({name, email})
    }
    useEffect(() => {
      if(!newContact){
        updateState(item?.invite?.name, item?.invite?.email)
        return
      }
    }, [item?.invite])
    useEffect(() => { updateState(newContact?.name, newContact?.email)}, [newContact])
    const sortItems = useMemo(() => {
      const items = [...contacts]
      return items?.sort((a, b) => {
        let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
    }, [contacts])
    const onOpenAddNewContact = () => navigate(generatePath(ROUTES.ADRESSBOOK_NEW_OUTSIDE, { outside: "outside"}))
    useEffect(() => { isEmptyContacts && getContact({ pageLength: pageSize, skip: 0 }) }, [])
   
    const renderOptions = (props, option) => (
        <div key={option._id}>
          {props['data-option-index'] <= 0 && (
            <MenuItem value={"add"} onClick={onOpenAddNewContact}>
              <Add sx={{ mr: 2.5, ml: 0.5 }} />
              <Typography variant="middleText">
                Add new contact
              </Typography>
            </MenuItem>
          )}
          <MenuItem {...props}>
            <Grid container item xs={12}>
              <Grid container justifyContent="flex-start" alignItems="center" item xs={0.8}>
                <EmptyAvatar name={option.name} />
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <Typography variant="span">{option.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ opacity: 0.6 }} variant="span">{option.email}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MenuItem>
        </div>
    );
    return (
        <>
            <Autocomplete
              id="country-select-demo"
              autoComplete
              value={state.name}
              options={sortItems || []}
              onChange={(_, option) => {
                setIsDisabled(!option)
                !option && updateState("", "")
                return option && updateState(option.name, option.email)
              }}
              noOptionsText={<>
                  <MenuItem value={"add"} onClick={onOpenAddNewContact}>
                    <Add sx={{ mr: 2.5, ml: 0.5 }} />
                    <Typography variant="span">Add new contact</Typography>
                  </MenuItem>
                </>}
              getOptionLabel={(option) => option.name || option}
              isOptionEqualToValue={(option, val) => val === "" || option.name === newContact?.name || 
                  option.name === item?.invite?.name || option.name === val }
              filterOptions={filterOptions}
              renderOption={(render, opt) => renderOptions(render, opt)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={() => setIsActive(true)}
                  onBlur={() => setIsActive(false)}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: isActive ? "Search contact" : "Choose contact",
                    startAdornment: (
                      <React.Fragment>
                        {isActive ? (
                          <Search sx={{ ml: 2 }} />
                        ) : (
                          <AccountBoxOutlined sx={{ ml: 2 }} />
                        )}
                      </React.Fragment>
                    ),
                  }}
                  label="Address book"
                />
              )}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
      contacts: state.contacts.items,
      newContact: state.contacts.newContact,
      item: state.interview.item || {},
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      getContact: (data) => dispatch({ type: types.contacts.GET_CONTACT, data: data })
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteContacts)
