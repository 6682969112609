import { Tooltip } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";


const HelpTip = (props) => {
  return (
    <Tooltip title={props.children || ''}>
      <HelpOutline sx={{ maxWidth: "13px", mb: 1, ml: 0.5 }} color="primary" />
    </Tooltip>
  );
};

export default HelpTip;