import React from "react";
// import { connect } from "react-redux";
import { Grid, Fab } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import useNavigation from "../../../hooks/useNavigation";

import PageHeader from "./PageHeader";

const fabStyle = {
  position: 'relative',
  top: 16,
  left: 16,
  marginBottom: "-56px",

};

const Page = ({ titleText, children, backButton, url, ...props }) => {
  const { navigate } = useNavigation();

  const handleBack = () => {
    navigate(url || -1);
  }

  return (
    <Grid container  direction="row" sx={{ 
      bgcolor: "primary.backgroundAll", 
      flexDirection: 'column',
      minHeight: { sm: 'calc(100vh - 125px)', xs: 'calc(100vh - 97px)'}, /* different header height {sm: 64, xs: 56} */
      flex: 1,
    }}>
      {backButton && (
        <Fab onClick={handleBack} aria-label="back" sx={{...fabStyle}}><ArrowBack /></Fab>
      )}
      <PageHeader titleText={titleText}/>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

export default Page;
