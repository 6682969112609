import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText 
} from '@mui/material';

import types from '../../../types';

const DialogConfirmationRaw = ({ onCancel = () => {}, onConfirm = () => {}, isShowing = false, defaultClose = true, ...other } ) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    onCancel();
    defaultClose && dispatch({type: types.app.SHOW_CONFIRMATION_DIALOG, payload: {isShowing: false}});
  };

  const handleConfirm = () => {
    // reset ask
    dispatch({type: types.app.ASK_BEFORE_NAVIGATE, payload: false});
    // hide dialod
    defaultClose && dispatch({type: types.app.SHOW_CONFIRMATION_DIALOG, payload: {isShowing: false}});
    // confirm action
    onConfirm();
  };

  return isShowing ? ReactDOM.createPortal(
    <Dialog
      sx={{
        color: "primary.text",
        "& .MuiDialog-paper": {
          bgcolor: "primary.backgroundAll",
          color: "primary.text",
          maxWidth: 444,
        },
      }}
      open={isShowing}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Leave the page?</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: "primary.text" }}
            id="alert-dialog-description"
          >
            Do you really want to leave the page? All entered data will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            sx={{
              pl: 0,
              color: "primary.selectedModal",
              backgroundColor: "transparent",
              "&:hover": {
                color: "primary.selectedModal",
                backgroundColor: "transparent",
              },
            }}
          >
            NO, Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{
              mr: 1,
              color: "primary.backgroundAll",
              backgroundColor: "primary.selectedModal",
              "&:hover": {
                color: "primary.backgroundAll",
                backgroundColor: "primary.selectedModal",
              },
            }}
            autoFocus
          >
            YES, confirm
          </Button>
        </DialogActions>
      </Dialog>
    , document.body) : null;
  }

const mapStateToProps = (state) => ({
  isShowing: state.app.confirmationDialog.isShowing,
  onConfirm: state.app.confirmationDialog.onConfirm,
});

const mapDispatchToProps = (dispatch) => ({
  // toggleTheme: (data) => dispatch({ type: types.settings.CHANGE_THEME, data: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmationRaw);
