import { createTheme } from "@mui/material";
import paletteLight from './paletteLight';

let palette = createTheme(paletteLight, {
  mode: 'dark',
  palette: {
    text: {
      primary: '#fff',
      secondary: '#fff',
      main: "#253D5A",
    },
    background: {
      default: "#0B1D29",
    },
    action: {
      active: "#F0F1F4",
    },
    dialog:{
      main: "#2F6A99",
      contrastText: '#fff',
    },
    noRows: {
      main: '#F0F1F4',
      contrastText: '#F0F1F4',
    },
    primary: {
      main: "#F0F1F4",
      main2: "#F0F1F4",
      contrastText: '#fff',//"#253D5A",
      iconbutton: "#CCDDFF",
      callStopButton: "#EA4D6F",
      textGris: "#F0F1F4",
      buttonBackground: "#F0F1F4",
      manageButton: "#58C2F0",
      recordbutton: '#EA4D6F',
      buttonWhite: "#F0F1F4",
      disabledText: "#0000004f",
      disabledBg: "#00000012",
      linkText: "#6FB9D9",
      backgroundGrey: "#E5E5E5",
      selected: "#F0F1F4",
      disabled: "#F0F1F473",
      text: "#F0F1F4",
      textCancelButton: "#F0F1F4",
      textNoChange: "#253D5A",
      modalText2: "#90CAF9",
      colorBorderChip: "rgba(255, 255, 255, 0.26)",
      textFAB: "#253D5A",
      text2: "#F0F1F4",
      blackText: "#F0F1F4",
      textHeader: "#F0F1F4",
      modalText: "#F0F1F4",
      white: "#fff",
      backgroundAll: "#253D5A",
      backgroundAllNumber: "#1D2B3E",
      checkbox: "#F49744",
      borderInput: "#F0F1F4",
      borderInputCopy: "#435561",
      colorText: "#F0F1F4",
      textGray: "#F0F1F4",
    },
  },
});

export default palette;
