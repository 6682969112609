/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {DeleteOutlineOutlined,DriveFolderUpload,Edit,ForwardToInbox} from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ROUTES } from "../../../constants";
import useNavigation from "../../../hooks/useNavigation";

const FloatABManage = ({ selected, onDelete, onClose, onRename, onAdd, ...props }) => {
  const openAction = selected?.length > 0;
  const editAction = selected?.length === 1;
  const { navigate } = useNavigation();
  const onPageUrl = (url) => navigate( url )
  const handleDelete = (e) => { 
    e.stopPropagation(); 
    onDelete(); 
  }
  return (
    <>
      <SpeedDial
        direction='left'
        hidden={!openAction}
        ariaLabel={openAction ? "add" : "delete"}
        icon={<SpeedDialIcon />}
        open={openAction}
        onClick={onClose}
      >
        {/* <SpeedDialAction
          icon={<ForwardToInbox />}
          tooltipTitle="Send to e-mail"
          onClick={() => onPageUrl(ROUTES.MANAGERECORDING_SEND_TO_EMAIL)}
        />
        <SpeedDialAction
          icon={<DriveFolderUpload />}
          tooltipTitle="Send to FTP"
          onClick={() => onPageUrl(ROUTES.MANAGERECORDING_SEND_TO_FTP)}
        /> */}
        <SpeedDialAction
          icon={<DeleteOutlineOutlined />}
          tooltipTitle="Delete"
          onClick={handleDelete}
        />
        {editAction && (
          <SpeedDialAction
            icon={<Edit />}
            tooltipTitle="Rename"
            onClick={onRename}
          />
        )}
      </SpeedDial>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    openForm: state.contacts,
  };
};
export default connect(mapStateToProps)(FloatABManage);
