import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const NetworkComponent = ({ count, ...props }) => {
    return (
        <Grid container item xs={6} sx={{
          height: "2em",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          "& > div": {
            width: 4,
            ml: 0.2,
            height: "100%",
            bgcolor: "primary.callButton",
          },
        }}>
            <Box maxHeight='8px' hidden={count < 1} ></Box>   
            <Box maxHeight='12px' hidden={count < 2} ></Box>   
            <Box maxHeight='16px' hidden={count < 3} ></Box>   
        </Grid>
    )
}
export default NetworkComponent
