import { ArrowDropDown } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, Switch, Typography } from '@mui/material';
import React from 'react';
import HelpTip from '../../containers/HelpTip';

const SettingAccordion = ({ state, openDuration, triggerDuration, handleChange, ...props }) => {
    return (
        <Accordion sx={{ bgcolor: "primary.backgroundAll"}} disableGutters elevation={0}
        expanded={openDuration === "panel2"}
        onChange={() => triggerDuration("panel2")}>
        <AccordionSummary sx={{ p:0}} expandIcon={<ArrowDropDown sx={{ fontSize: '32px'}} color="info" />}>
          <InputLabel>
            <Typography variant="middleText" color='text.primary'>
              Define your guest’s call page settings
            </Typography>
            <HelpTip children="Define your guest’s call page settings" />
          </InputLabel>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Grid alignItems="center" container item xs={12} md={9}>
          <Grid item xs={12} sm={7}>Show settings page to guest:</Grid>
              <Grid item xs={12} sm={5} container alignItems="center">
                      No
                  <Switch name='settingsForGuest' onChange={handleChange} 
                  checked={state.settingsForGuest} />
                      Yes
                  <HelpTip> Show settings page to guest</HelpTip>
              </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Typography variant="middleText" mt={2} color='text.primary'>
              Input effects
            </Typography>
            <Grid sx={{ alignItems: "baseline" }} container item xs={12}>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="echo"
                        checked={state.echo}
                        onChange={handleChange}
                      />
                    }
                    label="Echo cancellation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="control"
                        checked={state.control}
                        onChange={handleChange}
                      />
                    }
                    label="Auto gain control"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup sx={{ ml: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="noise"
                        checked={state.noise}
                        onChange={handleChange}
                      />
                    }
                    label="Noise suppression"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="filter"
                        checked={state.filter}
                        onChange={handleChange}
                      />
                    }
                    label="Highpass filter"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
}
export default SettingAccordion;