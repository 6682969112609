import { generatePath } from "react-router-dom";
import { call, put } from "redux-saga/effects";
import types from "../../types";
import { fetchWrapper } from "../fetchings";

export function* getAll(action) {
    const path = generatePath(`v1/managerecording/getAll/:interview_id`, {interview_id: action.data.id});
    const response = yield call( fetchWrapper().post, path, action.data );
    yield put({ type: types.recordingsmanage.GET_MANAGE_RECORDING_SUCCESS, payload: response });
}
export function* getOne(action) {
    const path = generatePath(`v1/managerecording/getOne/:recording_id`, {recording_id: action.data.id});
    const response = yield call( fetchWrapper().post, path, action.data );
    yield put({ type: types.recordingsmanage.GET_RECORDING_SUCCESS, payload: response });
}
export function* getMarkers(action) {
    const response = yield call( fetchWrapper().post, "v1/managerecording/getMarkers/", action.data );
    yield put({ type: types.recordingsmanage.GET_MARKERS_SUCCESS, payload: response });
}
export function* getAudio(action) {
    const path = generatePath(`v1/managerecording/audio/:recording_id`, {recording_id: action.data.id});
    const response = yield call( fetchWrapper().get, path );
    yield put({ type: types.recordingsmanage.GET_AUDIO_URL_SUCCESS, payload: response });
}
export function* create(action) {
    const path = generatePath(`v1/managerecording/:interview_id/create`, {interview_id: action.data.interview_id});
    const response = yield call( fetchWrapper().post, path, action.data );
    yield put({ type: types.recordingsmanage.CREATE_MANAGE_RECORDING_SUCCESS, payload: response });
    yield put({ type: types.interview.GET_INTERVIEW,  data: { _id: action.data.interview_id } });
}
export function* updateMarkersInterview(action) {
    const path = generatePath(`v1/managerecording/:recording_id/markers`, {recording_id: action.data.id});
    const response = yield call( fetchWrapper().put, path, action.data.markers );
    yield put({ type: types.recordingsmanage.UPDATE_MARKERS_RECORDING_SUCCESS, payload: response });
}
export function* upload(action) {
    const path = generatePath(`v1/managerecording/:interview_id/upload`, {interview_id: action.data.id});
    const response = yield call( fetchWrapper().post, path, action.data );
    yield put({ type: types.recordingsmanage.CREATE_MANAGE_RECORDING_SUCCESS, payload: response });
}
export function* update(action) {
    const response = yield call( fetchWrapper().put, "v1/managerecording/update", action.data );
    yield put({ type: types.recordingsmanage.UPDATE_MANAGE_RECORDING_SUCCESS, payload: response });
}
export function* deleteRecording(action) {
    const path = generatePath(`v1/managerecording/:interview_id/delete`, {interview_id: action.data.id});
    const response = yield call( fetchWrapper().delete, path, action.data );
    yield put({ type: types.recordingsmanage.DELETE_MANAGE_RECORDING_SUCCESS, payload: response });
}
export function* sendToEmail(action) {
    const response = yield call( fetchWrapper().post, "v1/managerecording/sendToEmail", action.data );
    yield put({ type: types.recordingsmanage.SEND_TO_EMAIL_MANAGE_RECORDING_SUCCESS, payload: response });
}
export function* sendToFtp(action) {
    const response = yield call( fetchWrapper().post, "v1/managerecording/sendToFtp", action.data );
    yield put({ type: types.recordingsmanage.SEND_TO_FTP_MANAGE_RECORDING_SUCCESS, payload: response });
}
