import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import types from "../../../types";
import useJanus from "../../../hooks/useJanus"
import { FETCH } from "../../../constants";

import RecordingsDialog from "./RecordingsDialog";
import Contacts from "./Contacts";
import Markers from "./Markers";
import ControlPanel from "./ControlPanel";
import Timer from "./Timer";
import TitleForm from "./TitleForm";

const OnlineInterviewing = ({ getInterview, item, fetchLoading, updateState, markersFetchLoading, ...props }) => {
  const { call, hangup, decline, answer, message, subscribe, localSource, remoteSource } = useJanus( item );
  const { interviewId } = useParams();
  const [isDisabled, setIsDisabled]= useState(true);

  useEffect(()=>{
    interviewId && getInterview( { _id: interviewId } );
    updateState()
  }, []);

  return (
    <>
      <Grid 
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ height: '100%', pb: 2 }}
      >
        
        <Grid sx={{mb: 2}}>
          <TitleForm setIsDisabled={setIsDisabled}/>
        </Grid>

        <Grid container item style={{ 
          flexGrow: 1,
          ...((isDisabled || fetchLoading) ? {pointerEvents: "none", opacity: "0.4"} : {})
        }}>
          <Grid 
            container
            direction="column"
            justifyContent="space-between"
            item xs={12} sm={8}>
            <Timer />
            <Contacts localSource={localSource} remoteSource={remoteSource}/>
          </Grid>

          <Grid item xs={12} sm={4} pl={{sm: 2, xs: 0}} pt={{sm: 0, xs: 2}}
            style={{ ...(markersFetchLoading && {pointerEvents: "none", opacity: "0.4"}) }}>
            <Markers />
          </Grid>
        </Grid>

        <Grid container item style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <ControlPanel
            onCall={call}
            onHangup={hangup}
            onDecline={decline}
            onAnswer={answer}
          />
        </Grid>

      </Grid>
      <RecordingsDialog />
    </>
  );
};
const mapStateToProps = (state) => ({
  item: state.interview.item,
  fetchLoading: state.interview.fetchStatus === FETCH.LOADING,
  markersFetchLoading: state.interview.markers.fetchStatus === FETCH.LOADING,
})
const mapDispatchToProps = (dispatch) => ({
  getInterview: (data) => dispatch({ type: types.interview.GET_INTERVIEW, data }),
  updateState: () => dispatch({ type: types.contacts.DEFAULT_CONTACT_SUCCESS })

})
export default connect(mapStateToProps, mapDispatchToProps)(OnlineInterviewing);
