import { useState, useEffect } from "react";
// import { getCountryCode } from "./Validator";

// ******************************
const useForm = ({ initState, validators, callback }) => {
  const [state, setState] = useState(initState);
  const [initialState, setInitialState] = useState(initState);
  const [errors, setErrors] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  
  const setInitState = ( initState ) => {
    setInitialState(initState);
    setState(initialState);
  }

  const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  const validateField = (fieldName, value) => {
    validators[fieldName] = validators[fieldName] || (() => {})
    return validators[fieldName](value);
  };
  
  useEffect(() => {
    if (isSubmited && !isValidErrors()) {
      setIsSubmited(false);
      callback();
    }
  }, [errors, isSubmited]);

  useEffect(() => {
    setIsChanged( !shallowEqual(state, initialState) );
  }, [state, initialState]);

  // ******************************
  const isValidErrors = () => 
  Object.values(errors)
    .filter(error => (typeof error === "string") && (error.length > 0))
    .length > 0;
  // ******************************
  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    const values = type === "checkbox" ? checked : value;
    setState(() => ({
      ...state,
      [name]: values
    }));
    setErrors({
      ...errors,
      [name]: validateField(name, checked)
    });
  };
  const handleStateChange = e => {
    setState(() => ({
      ...state,
      ...e
    }));
  };
  // ******************************
  const handleBlur = e => {
    const { name: fieldName } = e.target;
    setErrors({
      ...errors,
      [fieldName]: validateField(fieldName, state[fieldName])
    });
  };
  // ******************************
  const handleSubmit = e => {
    e.preventDefault && e.preventDefault();
    let _errors = {};
    setIsSubmited(false);
    for (const fieldName in validators) {
      _errors[fieldName] = validateField(fieldName, state[fieldName]);
    }
    setErrors({ ...errors, ..._errors });
    setIsSubmited(true);
  };

  return {
    handleChange,
    handleStateChange,
    handleSubmit,
    handleBlur,
    setInitState,
    state,
    errors,
    isChanged,
    isValidErrors,
  };
};

export default useForm;
