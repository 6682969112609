/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { generatePath, Outlet, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Page from "../../containers/Page";
 
const AudioSettings = () => {
  const { interviewId } = useParams()
  const link = interviewId && generatePath(ROUTES.INTERVIEW_EDIT, { interviewId: interviewId })
  return (
    <Page titleText="Audio Settings" backButton={interviewId} url={link}>
      <Outlet />
    </Page>
  );
};

export default AudioSettings;
