/* eslint-disable import/no-anonymous-default-export */
import app from './app';
import notifications from './notifications';
import interview from './interview';
import contacts from './contacts';
import settings from './settings';
import media from './media';
import recordingsmanage from './recordingsmanage';
import janus from './janus'

export default {
  recordingsmanage,
  app,
  notifications,
  interview,
  contacts,
  settings,
  media,
  janus
};
