/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_GENERAL_SETTINGS: 'settings/get_general_settings',
    UPDATE_GENERAL_SETTINGS: 'settings/update_general_settings',
    UPDATE_FTP_GENERAL_SETTINGS: 'settings/update_ftp_general_settings',
    GET_IMAGES_GENERAL_SETTINGS_CARUSEL: 'settings/get_images_general_settings_carusel',
    ADD_IMAGES_GENERAL_SETTINGS_CARUSEL: 'settings/add_images_general_settings_carusel',
    DELETE_IMAGES_GENERAL_SETTINGS_CARUSEL: 'settings/delete_images_general_settings_carusel',
    CHANGE_THEME: 'settings/change_theme',
    TOGGLE_THEME: 'settings/toggle_theme',
    FETCH_STATUS: 'settings/fetch_status',

    // success
    GET_GENERAL_SETTINGS_SUCCESS: 'settings/get_general_settings_success',
    UPDATE_GENERAL_SETTINGS_SUCCESS: 'settings/update_general_settings_success',
    UPDATE_FTP_GENERAL_SETTINGS_SUCCESS: 'settings/update_ftp_general_settings_success',
    GET_IMAGES_GENERAL_SETTINGS_CARUSEL_SUCCESS: 'settings/get_general_settings_carusel_success',
    ADD_IMAGES_GENERAL_SETTINGS_CARUSEL_SUCCESS: 'settings/add_general_settings_carusel_success',
    DELETE_IMAGES_GENERAL_SETTINGS_CARUSEL_SUCCESS: 'settings/delete_general_settings_carusel_success',
    CHANGE_THEME_SUCCESS: 'settings/change_theme_success',
  }