export default (theme) => ({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: `${theme.palette.dialog.contrastText}`,
          backgroundColor: `${theme.palette.dialog.main}`,
        },
      },
    },
  },
});
