// import { makeStyles } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => {
  return {
    root: {
      borderBottom: "none",
    },
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      maxWidth: "100%",
      position: "fixed!important",
      maxHeight: "64px",
      width: "100%",
      padding: "0px!important",
      backgroundColor: theme.palette.primary.backgroundAll,
    },
    toolbarTop: {
      display: "flex",
      alignItems: "center",
      justifyContent: 'space-between',
      color: `${theme.palette.primary.text}`,
    },
    toolbarTitle: {
      flex: 1,
    },
    headerText: {
      display: "flex",
      justifyContent: "space-around",
      color: theme.palette.primary.text,
    },
    toolbarMain: {
      position: "fixed",
      left: 0,
      padding: "0px!important",
      maxWidth: "100%",
      width: "100%",
      zIndex: 2,
    },
    toolbarLeft: {
      position: "relative",
      top: "25%",
      left: "16px",
      maxWidth: "300px",
      width: "0%",
      height: "100%",
      paddingLeft: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      zIndex: -1,
      transition: "all 1s linear",
    },
    toolbarSecondary: {
      justifyContent: "space-between",
      overflowX: "auto",
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    },
    toolbarMenu: {
      position: "relative",
      left: 0,
      width: "25%",
    },
    active: {
      minWidth: "254px!important",
      maxWidth: "254px!important",
      minHeight: "48px",
      maxHeight: "48px",
      width: "100%!important",
      justifyContent: "start",
      transition: "width 1s linear",
    },
    menuBurger: {
      cursor: "pointer",
      marginLeft: "22px",
    },
    Button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px",
      position: "static",
      height: "56px",
      background: theme.palette.primary.backgroundAll,
      color: theme.palette.primary.checkbox,
      boxShadow:
        "0px 0.25px 3px rgba(0, 0, 0, 0.039), 0px 2.75px 9px rgba(0, 0, 0, 0.19)",
      borderRadius: "16px",
      width: "100%",
      order: 1,
      flexGrow: 0,
      margin: " 0 1em 1.5em 7em!important",
    },
    buttonActive: {
      margin: "0!important",
      padding: "0!important",
      minWidth: "56px!important",
      maxWidth: "56px",
    },
    buttonGrid: {
      maxWidth: "181px",
      width: "100%",
      marginBottom: "1.5em",
    },
    buttonActivate: {
      marginBottom: "0em",
    },
    iconLeft: {
      paddingLeft: "8px",
      minWidth: "0!important",
    },
    textRight: {
      paddingRight: "10px",
      fontStyle: "normal",
      textAlign: "start",
      fontWeight: 700,
      fontSize: "16px!important",
      lineHeight: "160%!important",
      letterSpacing: "0.15px",
      textTransform: "uppercase",
      color: theme.palette.primary.colorTextBleind,
    },
    logout: {
      position: "fixed",
      bottom: 0,
      maxWidth: "64px!important",
      transition: "all 1s linear",
      "& :before": {
        content: " ",
        borderTop: "1px solid #000",
        opacity: "0.7",
      },
    },
    iconButton: {
      color: theme.palette.primary.colorTextBleind,
    },
    hidden: {
      display: "none",
    },
    itemIcons: {
      minWidth: "0px!important",
      maxHeight: "48px",
      overflow: "hidden",
      "&& li": {
        maxHeight: "48px",
        overflow: "hidden",
      },
      "&& .MuiMenuItem-gutters:hover": {
        backgroundColor: theme.palette.primary.selectedModal,
      },
      "&& svg": {
        color: theme.palette.primary.text,
      },
      color: theme.palette.primary.text,
    },
    gridMenu: {
      maxWidth: "100%",
      width: "100%",
    },
    menuItems: {
      maxWidth: "256px",
      width: "100%",
      textDecoration: "none",
      minHeight: "48px!important",
      backgroundColor: theme.palette.primary.backgroundAll,
      display: "flex",
      "& .links": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.selectedModal + "!important",
        "& svg": {
          color: theme.palette.primary.hoverText + "!important",
        },
        "& div": {
          color: theme.palette.primary.hoverText + "!important",
        },
        "& a": {
          color: theme.palette.primary.hoverText + "!important",
        },
      },
      "& a": {
        color: theme.palette.primary.text + "!important",
      },
      "& svg": {
        color: theme.palette.primary.text + "!important",
      },
    },
    circleLink: {
      color: theme.palette.primary.text,
    },
    MenuItemsForList: {
      width: "100%!important",
    },
    ItemsTextList: {
      paddingTop: "5px",
      marginLeft: "2em",
    },
    selected: {
      backgroundColor: theme.palette.primary.selectedModal + "!important",
      "& svg": {
        color: theme.palette.primary.hoverText + "!important",
      },
      "& div": {
        color: theme.palette.primary.hoverText + "!important",
      },
      "& a": {
        color: theme.palette.primary.hoverText + "!important",
      },
    },
    logoGrid: {
      display: "flex",
      justifyContent: "flex-start",
      "& svg": {
        marginLeft: "12px",
      },
    },
    titleGrid: {
      maxWidth: "100%",
      marginLeft: "24.3%",
      width: "100%",
      "& h1": {
        fontStyle: " normal",
        fontWeight: 400,
        fontSize: "30px",
        lineHeight: "41px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "1.1px",
      },
    },
    accountGrid: {
      maxWidth: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "end",
      "& svg": {
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid transparent",
        borderRadius: "36px",
        color: theme.palette.primary.backgroundAll,
        backgroundColor: `${theme.palette.primary.text}`,
      },
    },
  };
});

