/* eslint-disable react-hooks/exhaustive-deps */
  import { Button, Grid, Typography } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { connect } from "react-redux";
  import useNavigation from "../../../hooks/useNavigation";
  import types from "../../../types";
  import ListFile from "./ListFile";
  
  const SendToFTP = ({ sendToFtp, selectedItems }) => {
    const { navigate } = useNavigation()
    const onSubmitForm = () => sendToFtp({ _id: selectedItems })
    const onClose = () => navigate(-1)
    return (
      <>
        <Grid sx={{ bgcolor: "primary.backgroundAll" }} container item xs={12}>
            <Grid container mt={2} item xs={12}>
              <Grid container justifyContent="center" item xs={12}>
                <Typography variant="manage">
                  SEND RECORDING BY FTP
                </Typography>
              </Grid>
            </Grid>
            <Grid component="form" container item xs={12} noValidate autoComplete="off">
              <Grid minHeight={250} item xs={12}>
                 <ListFile carouselImages={selectedItems} />
              </Grid>
              <Grid container spacing={3} justifyContent="center" item xs={12}>
                <Grid item xs={2}>
                    <Button onClick={() => onClose()} variant="outlined" color="orange">
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => onSubmitForm()} variant="contained" color="orange">
                        send by ftp
                    </Button>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      sendToFtp: (data) => dispatch({ type: types.recordingsmanage.SEND_TO_FTP_MANAGE_RECORDING, data }),
    };
  };
  const mapStateToProps = (state) => ({
    selectedItems: state.managerecordings.selectedItems,
  });
  export default connect(mapStateToProps, mapDispatchToProps)(SendToFTP);
  