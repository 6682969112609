import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

const DeleteDialog = ({ onConfirm, onCancel, ...props }) => {
  return (
    <Dialog {...props} >
        <DialogTitle> Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete selected items?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="orange" variant="text">no, cancel</Button>
          <Button onClick={onConfirm} autoFocus color="orange" variant="contained">yes, confirm</Button>
        </DialogActions>
    </Dialog>
  );
};
export default DeleteDialog;