import { experimental_sx as sx } from "@mui/material/styles";

export default (theme) => ({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          "&::before": {
            top: 'auto'
          },
          "&::after": {
            top: 'auto'
          },
        },
        wrapper:{
          fontSize: '0.8rem'
        }
      },
    },
  },
});
