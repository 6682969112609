import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as SuccessFile } from "../../../images/send_file.svg";
import useNavigation from "../../../hooks/useNavigation";
import types from "../../../types";
import { connect } from "react-redux";

const SuccessSend = ({ setDefaultVariables, ...props }) => {
  const { navigate } = useNavigation();
  useEffect(() => {
    setTimeout(() => {
      setDefaultVariables()
      navigate(-2);
    }, 2000);
  }, []);
  return (
    <Grid container item xs={12} sx={{ bgcolor: "primary.backgroundAll" }}>
      <Grid justifyContent="center" pb={15} container item xs={12}>
        <Grid justifyContent="center" container item xs={7}>
          <SuccessFile />
        </Grid>
        <Grid justifyContent="center" container item xs={7}>
          <Typography textAlign="center" variant="manage" sx={{ textTransform: "uppercase" }}>
            Success send email
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapDispatchToProps = (dispatch) => ({ setDefaultVariables: () => dispatch({ type: types.interview.DEFAULT_INTERVIEW_VARIABLES})})
export default connect(null, mapDispatchToProps)(SuccessSend);
