/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Page from "../../containers/Page";

import { Outlet } from "react-router-dom";

const Interviews = () => {
  return (
    <>
      <Page titleText="YOUR INTERVIEWS">
        <Outlet />
      </Page>
    </>
  );
};

export default Interviews;
