import { FiberManualRecordTwoTone } from "@mui/icons-material";
import { Grid, styled } from "@mui/material";

export const FiberManualRecordTwoToneAnimated = styled(FiberManualRecordTwoTone)({
  "@keyframes blinkrec": {
    "30%": { opacity: 1 },
    "50%": { opacity: 0.3 },
    "70%": { opacity: 1 },
  },
  marginLeft: 1,
  width: "28px",
  height: "28px",
})
export const GridAnimated = styled(Grid)({
  "@-moz-keyframes moveGradient": {
     "0%": { 
      backgroundImage: 'linear-gradient(to top right, #EA4D6F,#EA4D6F38)',
      backgroundPosition: '100% 0%'
     },
     "25%": { 
      backgroundImage: 'linear-gradient(to bottom right, #EA4D6F,#EA4D6F38)',
      backgroundPosition: '100% 0%'
    },
    "50%": { 
      backgroundImage: 'linear-gradient(to bottom left, #EA4D6F,#EA4D6F38)',
      backgroundPosition: '0% 100%'
    },
    "75%": { 
      backgroundImage: 'linear-gradient(to top left, #EA4D6F,#EA4D6F38)',
      backgroundPosition: '0% 100%'
    },
    "100%": { 
      backgroundImage: 'linear-gradient(to top right, #EA4D6F,#EA4D6F38)',
      backgroundPosition: '100% 0%'
    },
  },
  borderRadius: '3em',
  backgroundPosition: "100% 0%",
  transition: "all .3s linear",
})