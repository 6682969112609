import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from "react-router";
import types from '../types';
import { Janus } from 'janus-gateway';
import { JANUS, MEDIA } from '../constants';
import useAudio from './useAudio';
import useNavigation from "./useNavigation";
import ComputerMagic from "../audio/Computer_Magic.mp3";

Janus.init({
  debug: true,
  // dependencies: Janus.useDefaultDependencies(), // or: Janus.useOldDependencies() to get the behaviour of previous Janus versions
  callback: function() {
    console.log('Janus.init done');
  }
});

function findLine(sdp, prefix) {
  var regex_result = (new RegExp(prefix.replace('/', '\\/') + '.*')).exec(sdp);
  return regex_result && regex_result[0];
}

const sdpUpdate = function(sdp, incomingSdp) {
  var self = {
    config: {
      bitrate: 64, // 32, 48, 56, 64, 80, 96, 128, 192, 256
      stereo: 0,
      cbr: 1,
      fec: 1,
      dtx: 0,
    }
  };
  // console.log('SDP Before:');
  // console.log(sdp);
  
  // Modify opus codec parameters
  var opusIndex = /a=rtpmap:([0-9]+)\s+opus\/48000/.exec(sdp)[1];
  console.log('opusIndex:', opusIndex);
  var opusLine = findLine(sdp, 'a=fmtp:' + opusIndex);
  var stereo = parseInt(self.config.stereo);
  if (incomingSdp) {
      var opusLineOffer = findLine(incomingSdp, 'a=fmtp:' + opusIndex);
      stereo = opusLineOffer.match(/stereo=1/);
  }
  var fmtp = 'a=fmtp:' + opusIndex +
      ' maxaveragebitrate=' + (self.config.bitrate * 1000) +
      ';maxplaybackrate=48000' +
      ';stereo=' + (stereo ? 1 : 0) +
      ';sprop-stereo=' + (stereo ? 1 : 0) +
      ';cbr=' + self.config.cbr +
      ';useinbandfec=' + self.config.fec +
      ';usedtx=' + self.config.dtx;
  if (opusLine) {
      sdp = sdp.replace(opusLine, fmtp);
  } else {
      sdp += fmtp + '\n';
  }

  // Remove maxptime is present
  sdp = sdp.replace(/a=maxptime:[0-9]+/, '');

  // Remove to reduce size
  sdp = sdp.replace(new RegExp('a=(extmap):[0-9].*[\\r\\n]+', 'g'), '');

  // Remove all codec indexes from m=audio that are not opus
  sdp = sdp.replace(/(m=audio\s+[0-9]+\s+\S+).*/, '$1 ' + opusIndex);

  // Remove all codecs that aren't opus
  sdp = sdp.replace(new RegExp('a=(rtpmap|rtcp-fb|fmtp):(?!' + opusIndex + '\\s).*[\\r\\n]+', 'g'), '');
  // console.log('SDP After:');
  // console.log(sdp);
  return sdp;
}

let plugin;
let pluginSip1;
let janus;
// let janus1;

const useJanus = ( item, ...props ) => {
  const { setAskBeforeNavigate, navigate } = useNavigation( () => initJanus() );
  const { inputSource } = useAudio();
  const dispatch = useDispatch();

  const profile = useSelector(state => state.app.profile.identity || {});
  const callState = useSelector(state => state.janus.call_state || false);
  const connected =  useSelector(state => state.janus.connected || false);
  const registered =  useSelector(state => state.janus.registered || false);
  const recording_id =  useSelector(state => state.janus.recording_id || false);
  const isAutorecordingLocal =  useSelector(state => state.setting.isAutorecordingLocal);
  const isAutorecordingCloud =  useSelector(state => state.setting.isAutorecordingCloud);

  const organization_id =  useSelector(state => state.app.profile?.home?.organization_id);
  const user_id =  useSelector(state => state.app.profile?._id);
  const interview_id =  useSelector(state => state.interview.item?._id);
  const interviewItem =  useSelector(state => state.interview.item);
  
  const [remoteSource, setRemoteSource] = useState(null); 
  const [reconnect, setReconnect] = useState(0);
  const [pluginSip, setPluginSip] = useState(false);
  const [incomingGsep, setIncomingGsep] = useState(false);
  const [incomingAudio, ] = useState(new Audio(ComputerMagic));

  const initJanus = () => {
    console.log('initJanus');
    setRemoteSource(null);
    setPluginSip(null);
    resetRecording();
    dispatch({type: types.janus.INIT_JANUS});
  };
  const resetRecording = () => dispatch({ type: types.recordingsmanage.GET_RECORDING_SUCCESS});
  const setRegistered = (data) => dispatch({type: types.janus.SET_REGISTERED, payload: data});
  const setConnected = (data) => dispatch({type: types.janus.SET_CONNECTED, payload: data});
  const setCallState = (data) => dispatch({type: types.janus.SET_CALL_STATE, payload: data});
  const setLocalStream = (data) => dispatch({type: types.janus.SET_LOCAL_STREAM, payload: data});
  const setRemoteStream = (data) => dispatch({type: types.janus.SET_REMOTE_STREAM, payload: data});
  const showToast = (message) => dispatch({ type: types.notifications.ENQUEUE_SNACKBAR, notification: { message } });
  const generateCallId = () => dispatch({ type: types.janus.GENERATE_CALL_ID });
  const tickSecond = () => dispatch({type: types.janus.TICK_SECOND});
  const setCloudRecording = (data) => dispatch({type: types.janus.SET_CLOUD_RECORDING, payload: data});
  const createRecording = (data) => dispatch({type: types.recordingsmanage.CREATE_MANAGE_RECORDING, data });
  const setLocalRecording = (data) => dispatch({type: types.janus.SET_LOCAL_RECORDING, payload: data});

  useEffect(() => setLocalStream( inputSource ), [inputSource]);
  
  useEffect(() => {
    if(!item || !pluginSip || registered){
      return;
    }
    pluginSip.send({
      message: {
        request: 'register',
        display_name: `${profile.firstname} ${profile.lastname}`,
        force_tcp: true,
        magicKey: item?.producer?.key + 'F',
      }
    });
  }, [item, pluginSip]);

  const handleRemoteJsep = (jsep) => {
    plugin.handleRemoteJsep({ 'jsep': jsep, 'error': console.error });
    dispatch({type: types.interview.SET_IS_STARTED, payload: true});
  };

  const janusSuccess = function() {
    setConnected(true);
    janus.attach({
      plugin: "janus.plugin.sip",
      success: function(pluginHandle) {
        plugin = pluginHandle;
        console.log('Plugin attached! (' + pluginHandle.getPlugin() + ', id=' + pluginHandle.getId() + ')');
        setRegistered(false);
        setPluginSip(pluginHandle);
      },
      iceState: function(state) {
        console.log("ICE state changed to " + state);
      },
      error: function(cause) {
        console.log('error', cause);
      },
      consentDialog: function(on) {
        console.log('consentDialog');
      },
      onmessage: function(msg, jsep) {
        switch(msg?.result?.event){
          case 'registering':
            setRegistered(false);
            break;

          case 'registered':
            setRegistered(true);
            break;

          case 'calling':
          case 'ringing':
          case 'proceeding':
            setCallState(JANUS.CALLING);
            break;

          case 'accepted':
            !!jsep && handleRemoteJsep(jsep);
            setCallState(JANUS.IN_CALL);
            break;

          case 'incomingcall':
            setCallState(JANUS.INCOMING_CALL);
            setIncomingGsep(jsep);
            break;

          case 'hangingup':
            (callState === JANUS.IN_CALL) && generateCallId();
            setCallState(JANUS.NOT_IN_CALL);
            setIncomingGsep( null );
            setRemoteSource( null );
            stopRecording();
            isAutorecordingCloud && setCloudRecording(MEDIA.STOP);
            isAutorecordingLocal && setLocalRecording(MEDIA.STOP);
            break;

          case 'hangup':
            (callState === JANUS.IN_CALL) && generateCallId();
            setCallState(JANUS.NOT_IN_CALL);
            setIncomingGsep( null );
            setRemoteSource( null );
            if(msg?.result?.code !== 200){
              showToast(msg?.result?.reason);
              setReconnect(reconnect + 1);
            }
            stopRecording();
            isAutorecordingCloud && setCloudRecording(MEDIA.STOP);
            isAutorecordingLocal && setLocalRecording(MEDIA.STOP);
            break;
          
          case 'recordingupdated':
            isAutorecordingCloud && setCloudRecording(MEDIA.RECORDING);
            isAutorecordingLocal && setLocalRecording(MEDIA.RECORDING);
            break;
        }
        switch(msg?.error_code){
          case 445:
            setReconnect(reconnect + 1);
            break;
        }
        console.info(msg, jsep);
      },
      onlocaltrack: function(track, added) {
        console.log('onlocaltrack', track, added);
      },
      onremotetrack: function(track, mid, added) {
        const stream = new MediaStream([track]);
        const audio = new Audio();
        audio.srcObject = stream;
        audio.play();
        setRemoteSource( stream );
        setRemoteStream( stream );
      },
      oncleanup: function() {
        setCallState(JANUS.NOT_IN_CALL);
        console.log('oncleanup');
      },
      detached: function() {
        console.log('detached');
      },
      ondataopen : function() {
        console.log('ondataopen');
      },
      ondata : function(data) {
        console.log('ondata', data);
      },
    });
          
  };

  const call = () => {
    const tracks = inputSource.getAudioTracks();
    pluginSip.createOffer({
      tracks:[
        {type: 'audio', capture: tracks?.length && tracks[0] || true, recv: true, dontStop: true},
        // {type: 'video', capture: false, recv: false}
      ],
      success: function(jsep) {
          jsep.sdp = sdpUpdate(jsep.sdp, null);
          var body = { request: "call", uri: item?.guest?.key + 'F' };
          pluginSip.send({"message": body, "jsep": jsep});
      },
      error: function(error) {
        console.info(error);
      }
    });
  };

  const hangup = () => {
    dispatch({type: types.interview.SET_IS_STARTED, payload: false});
    pluginSip.send({ 'message': { 'request': 'hangup' } });
    // setReconnect(reconnect + 1);
  };

  const decline = () => {
    dispatch({type: types.interview.SET_IS_STARTED, payload: false});
    pluginSip.send({ "message": { "code": 603, "request": "decline" } });
  };

  const answer = () => {
    const tracks = inputSource.getAudioTracks();
    pluginSip.createAnswer({
      tracks:[
        {type: 'audio', capture: tracks?.length && tracks[0] || true, recv: true, dontStop: true},
        // {type: 'video', capture: false, recv: false},
        // {type: 'data'}
      ],
      jsep: incomingGsep,
      success: function(jsep) {
        jsep.sdp = sdpUpdate(jsep.sdp, incomingGsep.sdp);
        var body = { request: "accept" };
        pluginSip.send({ "message": body, "jsep": jsep });
      },
      'error': function(error) {
        console.info(error);
      }
    });
  }

  const startRecording = () => {
    localStorage.setItem(`jws_recording_id_${interview_id}`, recording_id);
    pluginSip.send({
      message: {
        request: 'recording',
        action: 'start',
        audio: true,
        video: false,
        peer_audio: true,
        peer_video: false,
        filename: generatePath(JANUS.JANUS_RECORDINGS_PATH, {
          organization_id,
          user_id,
          interview_id,
          recording_id,
        })
      }
    });
    createRecording({
      interview_id,
      recording_id,
      duration: 0,
      name: `${item?.name}-${item?.count_records ? parseInt(item?.count_records) + 1 : 0}`
    });
  };

  const stopRecording = () => {
    setCloudRecording(MEDIA.STOP);
    pluginSip && pluginSip.send({
      message: {
        request: 'recording',
        action: 'stop',
        audio: true,
        video: false,
        peer_audio: true,
        peer_video: false,
      }
    });
  };

  const message = () => {
    pluginSip1.send({message: { 
      request: "message", 
      content: 'content2',
      uri: item?.guest?.agent?.uri || '',
      headers: {'From': item?.producer?.agent?.uri || ''}
    }});
  };

  const subscribe = () => {
    
  };

  useEffect(() => {
    initJanus();
    janus = new Janus({
      server: JANUS.JANUS_SERVERS,
      iceServers: JANUS.JANUS_ICE_SERVERS,
      bundlePolicy: 'max-bundle',
      success: janusSuccess,
      error: function(cause) {
        console.log('error', cause);
        setConnected(false);
        setTimeout(function() {
          janus.reconnect({
            success: function() {
              console.log("Session successfully reclaimed:", janus.getSessionId());
            },
            error: function(err) {
              console.error("Failed to reconnect:", err);
              setReconnect(reconnect + 1);
            }
          });
        }, 2000);
        // Error, can't go on...
      },
      destroyed: function() {
        console.log('destroyed');
          // I should get rid of this
      }
    });
    return janus.destroy;
  }, [ reconnect ]);


  // useEffect(() => {
  //   if(!item?.producer || !item?.guest){
  //     return;
  //   }

  //   janus1 = new Janus({
  //     server: ['wss://janus.conf.meetecho.com/ws'],
  //     success: function() {
  //       let plugin;
  //       janus1.attach({
  //         plugin: "janus.plugin.sip",
  //         success: function(pluginHandle) {
  //           plugin = pluginHandle;
  //           console.log('Plugin attached! (' + plugin.getPlugin() + ', id=' + plugin.getId() + ')');
  //           plugin.send({
  //             message: {
  //               request: "register",
  //               secret: item?.producer?.agent?.password || '',
  //               username: item?.producer?.agent?.uri || '',
  //             }
  //           });
  //         },
  //         onmessage: function(msg, jsep) {
  //           switch(msg?.result?.event){
  //             case 'registered':
  //               // setInterval(() => {
  //               //   plugin.send({message: { 
  //               //     request: "message", 
  //               //     content: 'content2',
  //               //     uri: 'sip:iqoyaguest-4b239d40-252d-48e1-b302-96e0ad018139@sip.ossystem.iqoya.com',
  //               //     headers: {'From': 'sip:iqoyaguest-bb457a59-b0be-447b-afc6-03282569a8b7@sip.ossystem.iqoya.com'}
  //               //   }});
  //               // }, 5000);
  //               break;
  //             case 'message':
  //               console.log(msg)
  //               break;
  //           }
  //         },
  //         error: console.log
  //       });
  //     },
  //     error: console.log
  //   });
  //   return janus1.destroy;
  // }, [ item ]);

  useEffect(() => {
    let interval;
    // in call flow
    if(callState === JANUS.IN_CALL){
      setAskBeforeNavigate(true);
      startRecording();
      interval = setInterval(() => tickSecond(), 1000);
    }else{
      setAskBeforeNavigate(false);
      clearInterval(interval);
    }

    // incoming call flow
    if(callState === JANUS.INCOMING_CALL){
      incomingAudio.loop = true;
      incomingAudio.currentTime = 0;
      incomingAudio.play();
    }else{
      incomingAudio.pause();
    }

    return () => {
      clearInterval(interval);
      incomingAudio.pause();
    }
  }, [callState]);


  return { 
    call,
    hangup,
    decline,
    answer,
    message,
    subscribe,
    localSource: inputSource,
    remoteSource,
  };
};

export default useJanus;

