// defined in /public/index.htlm
// override /public/env.js

export default {
  JANUS_SERVERS: window.JANUS_SERVERS,
  JANUS_ICE_SERVERS: window.JANUS_ICE_SERVERS,
  JANUS_RECORDINGS_PATH: window.JANUS_RECORDINGS_PATH,

  NOT_IN_CALL: 'NOT_IN_CALL',
  IN_CALL: 'IN_CALL',
  INCOMING_CALL: 'INCOMING_CALL',
  CALLING: 'CALLING',
}