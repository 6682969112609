/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import types from "../../../types";
import { FETCH, ROUTES } from "../../../constants";
import Preloader from "../../containers/Preloader";
import FloatActionsInterview from "./FloatActions";
import useNavigation from "../../../hooks/useNavigation";
import DeleteDialog from "../../containers/Dialog/DeleteDialog";
import { Tooltip, Link } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";
import { generatePath } from "react-router-dom";
import NoRowsText from "../../containers/NoRowsText";
 
var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", minute: 'numeric' };

const List = ({ items, status, loading, getInterviews, editName, deleteItems, ...props }) => {
  const [selected, setSelected] = useState([]);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [cellModesModel, setCellModesModel] = useState({});
  const page = { pageLength: FETCH.PAGE_SIZE, skip: 0 };
  const { navigate } = useNavigation();
  const columns = [{
      field: "__check__",
      hideable: false,
      sortable: false,
      editable: false,
      width: 55,
    },{
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: true,
      editable: true,
      clickable: true,
      renderCell: (params) => <Tooltip title="Click to edit interview">
        <Link underline="none" href="#">{params.value}</Link></Tooltip>
    },{
      field: "createAt",
      headerName: "Date",
      type: "date",
      flex: 1,
      description: "To resume, just click on the corresponding line",
      sortable: true,
      editable: false,
      valueGetter: ({ value }) => value && new Date(value).toLocaleString('en-US', options),
    },{
      field: "count_records",
      type: "string",
      description: "To resume, just click on the corresponding line",
      headerName: "Recordings",
      sortable: false,
      flex: 1,
      renderCell: (params) => (params?.value > 0) && 
        <Tooltip title="Click to manage recordings">
          <Link component="button" underline='none' sx={{ display: 'flex', alignItems: 'center'}}
            onClick={() => navigate(generatePath(ROUTES.MANAGERECORDING_INTERVIEW, { interviewId: params?.row?._id }))}> 
            <GraphicEq 
              sx={{ mr: 1 }} 
              preserveAspectRatio= 'none'
              style={{ height: '15px', width: '25px' }}
            /> manage recordings {`(${params?.value || ''})`}
          </Link>
        </Tooltip>
    }];

  useEffect(() => { getInterviews(page) }, []);

  const onDelete = () => setOpenConfirm(true);
  const onCancelDelete = () => setOpenConfirm(false);
  const onConfirmDelete = () =>{ 
    setOpenConfirm(false); 
    deleteItems({ _ids: selected }); 
  }
  const onClose = () => setSelected([]);
  const onAdd = () => navigate(ROUTES.INTERVIEW);

  const onRename = useCallback((e) => {
    e.stopPropagation(); 
    setEditing(true);
    setName(getName(selected[0]));
  });

  const getName = (id) => {
    const item = items.filter((i) => i._id === id)
    return item && item.length && item[0].name;
  };

  const isEditing = useCallback(() => {
    return Object.keys(cellModesModel).reduce((acc, id) => 
      acc || Object.keys(cellModesModel[id]).reduce(
          (acc2, field) => acc2 || (cellModesModel[id][field]?.mode === GridCellModes.Edit)
        , false)
      , false);
  }, [cellModesModel]);

  useEffect( () => {
    const id = selected[0]
    const field = 'name';
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce((acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce((acc2, field) => ({
            ...acc2,
            [field]: { mode: GridCellModes.View }
          }), {})
        }),{}),
        ...(id && editing && {
          [id]: { 
          ...cellModesModel[id], 
          [field]: { mode: GridCellModes.Edit } 
        }}),
      }
    });
  }, [editing, selected]);

  const renameSubmit = (value) => {
    const id = selected[0];
    const cur = getName(id);
    setEditing(false);
    setName(null);
    id && value && (cur !== value) && editName({
      _id: id,
      name: value,
    });
  }

  const handleCellKeyDown = (params, event, details) => {
    if (event.keyCode === 13) {
      renameSubmit(event.target.value)
    }
  };

  const onCellFocusOut = useCallback((event, p) => {
    if (!editing) return;
    p.defaultMuiPrevented = true;
    renameSubmit(name);
  }, [name]);

  const onChangeHandle = (event) => (event.target.type !== "checkbox") && setName(event.target.value);

  const handleCellModesModelChange = (newModel) => !isEditing() && setSelected(newModel);

  const onEditInterview = (item) => 
    (item.field === "name" && !editing) 
      && navigate(ROUTES.INTERVIEW + "/" + item.id)

  return (
    <>
      <DataGrid
        rows={items}
        columns={columns}
        loading={loading}
        onCellClick={onEditInterview}
        onCellFocusOut={onCellFocusOut}
        isRowSelectable={(e) => !editing}
        onCellKeyDown={handleCellKeyDown}
        cellModesModel={cellModesModel}
        components={{
          LoadingOverlay: Preloader,
          NoRowsOverlay: () => (<NoRowsText text="Click on the + button to create your first interview" />)
        }}
        componentsProps={{ cell: { onChange: onChangeHandle } }}
        getRowId={(row) => row._id}
        onSelectionModelChange={handleCellModesModelChange}
        experimentalFeatures={{
          newEditingApi: true,
          preventCommitWhileValidating: true,
        }}
        selectionModel={selected}
        checkboxSelection={true}
      />
      <FloatActionsInterview
        selected={selected}
        onRename={onRename}
        onDelete={onDelete}
        onClose={onClose}
        onAdd={onAdd}
      />
      <DeleteDialog 
        open={openConfirm}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  items: state.interview.items,
  status: state.interview.status,
  token: state.contacts.token,
  loading: state.interview.fetchStatus === FETCH.LOADING,

});
const mapDispatchToProps = (dispatch) => ({
  editName: (data) => dispatch({ type: types.interview.RENAME_INTERVIEW, data: data }),
  getInterviews: (data) => dispatch({ type: types.interview.GET_INTERVIEWS, data: data }),
  deleteItems: (data) => dispatch({ type: types.interview.DELETE_INTERVIEW, data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(List);
