import types from "../types";
import constants from "../constants";

const initialStore = {
  fetchStatus: constants.fetch.FINISHED,
  items: [],
  item: [],
  newContact: null,
  data: [],
  openForm: false,
  openManage: false,
  switchRecord: false,
  token: null,
  status: false,
  request: false,
};
const contacts = (state = initialStore, action) => {
  switch (action.type) {
    case types.contacts.FETCH_STATUS:
      return {
        ...state,
        ...action,
      };
    case types.contacts.GET_CONTACT_SUCCESS: {
      return {
        ...state,
        status: action.payload.data.length > 0,
        request: action.payload.statusCode > 200 && action.payload.statusCode < 400,
        items: action.payload.data,
        item: [],
      };
    }
    case types.contacts.UPDATE_CONTACT_SUCCESS: {
      return {
        ...state,
        items: [],
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    }
    case types.contacts.SAVE_CONTACT_SUCCESS: {
      return {
        ...state,
        items: [],
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    }
    case types.contacts.EDIT_CONTACT_SUCCESS: {
      return {
        ...state,
        item: action.payload.data,
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    }
    case types.contacts.ADD_CONTACT_SUCCESS: {
      return {
        ...state,
        items: [],
        newContact: action.payload,
        status: action.payload.statusCode >= 200 && action.payload.statusCode < 400,
      };
    }
    case types.contacts.DELETE_CONTACT_SUCCESS: {
      return {
        ...state,
        items: action.payload.data || [],
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    }
    case types.contacts.DEFAULT_CONTACT_SUCCESS: {
      return {
        ...state,
        newContact: null,
        status: false,
      };
    }
    case types.contacts.OPEN_SOME_FORM_SUCCESS: {
      if (!action.payload) {
        state.items = [];
      }
      return {
        ...state,
        openForm: action.payload,
      };
    }
    default:
      return state;
  }
};

export default contacts;
