import types from "../types";

const initialStore = {
  audio: false,
  microphone: false,
  source: {},
  blob: null,
  devices: {},
  isMenuOpen: true,
};
const media = (state = initialStore, action) => {
  switch (action.type) {
    case types.media.SET_DEVICES:
      return {
        ...state,
        devices: {...state.devices, ...action.payload}
      };

    case types.media.AUDIOINPUTAUDIOINPUT:
      return {
        ...state,
        microphone: action.payload,
      };
    case types.media.TOGGLE_MENU_OPEN:
      return { ...state, isMenuOpen: (action.toggle !== undefined) ? action.toggle : !state.isMenuOpen };
  
    case types.media.SOURCE:
      return {
        ...state,
        source: action.payload,
      };
    case types.media.AUDIOOUTPUT:
      return {
        ...state,
        audio: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default media;
