/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-undef */
// import { Toolbar, MenuItem } from "@material-ui/core";
import { Toolbar, useMediaQuery } from "@mui/material";
import {  Menu as MenuIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useStyles } from "./config";
import "../../../scss/header.scss";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "../../../images/Logo_Solid_Color.svg";
import ProfileAvatar from "./ProfileAvatar";
import DigigramLogo from "./DigigramLogo";
import types from "../../../types";
import NameCompany from "./NameCompany";

const Header = (props) => {
  const classes = useStyles(props);
  const { toggleMenuOpen } = props;
 
  const matches1200 = useMediaQuery("(min-width:1200px)");
  useEffect(() => {
    toggleMenuOpen(!!matches1200);
  }, [matches1200]);


  // const handleCloseForm = () => {
  //   props.OpenFormSet(false);
  //   handleCloseModalAlert();
  //   if (props.isAudioMass) {
  //     changeAudiomass();
  //   }
  //   history(hrefLink, { replace: true });
  //   return;
  // };
  // const handleCloseModalAlert = () => {
  //   setOpenModalAlert(false);
  //   return;
  // };

  return (
    <Toolbar className={classes.toolbarMain}>
      <Toolbar className={classes.toolbar}>
        <Grid justifyContent="space-between" alignItems="center" container item xs={12}>
          <Grid item display={{ xs: "flex", sm: "none" }} xs={1}>
            <MenuIcon
              className={classes.menuBurger}
              variant="contained"
              color="primary.text"
              onClick={() => toggleMenuOpen()}
            />
          </Grid>
          <Grid color="primary.text" container alignItems="center" item xs={1} sm={4} md={3.3} lg={2} xl={1.8}>
            <Grid ml={1} item xs={1} sm={2}>
              <Logo height="40" />
            </Grid>
            <Grid display={{ xs: "none", sm: "flex"}} item xs={9.5}>
            <DigigramLogo />
          </Grid>
          </Grid>
         
          <Grid color="primary.text" justifyContent="center"  container item xs={6} md={5} lg={8} >
            <Typography sx={{ fontSize: { xs: "18px", sm: "22px", md: "34px" }}} noWrap>
              Journalist Workspace
            </Typography>
          </Grid>
          <NameCompany container justifyContent="flex-end" item xs={1} />
          <ProfileAvatar container justifyContent="center" item xs={1} />
        </Grid>
      </Toolbar>
    </Toolbar>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    toggleMenuOpen: (toggle) => dispatch({ type: types.app.TOGGLE_MENU_OPEN, toggle: toggle }),
  });
export default connect(mapStateToProps, mapDispatchToProps)(Header);
