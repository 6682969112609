/* eslint-disable import/no-anonymous-default-export */
export default {
  // interview process
  SET_IS_STARTED: 'interview/set_is_started',
  
  GET_INTERVIEWS: 'interview/get_interviews',
  GET_INTERVIEW: 'interview/get_interview',
  GET_KEY_FOR_LINK: 'interview/get_key_for_link',
  SEND_INVITE_INTERVIEW: 'interview/send_invite_interview',
  RENAME_INTERVIEW: 'interview/rename_interview',
  DELETE_INTERVIEW: 'interview/delete_interview',
  CREATE_INTERVIEW: 'interview/create_interview',
  UPDATE_INTERVIEW: 'interview/update_interview',
  FETCH_STATUS: 'interview/fetch_status',
  MARKERS_FETCH_STATUS: 'interview/markers_fetch_status',
  STATUS_MESSAGE: 'interview/status_message',
  UPDATE_MARKERS_INTERVIEW: 'interview/update_markers_interview',
  DELETE_MARKERS_INTERVIEW: 'interview/delete_markers_interview',
  // Success
  GET_INTERVIEWS_SUCCESS: 'interview/get_interviews_success',
  GET_INTERVIEW_SUCCESS: 'interview/get_interview_success',
  GET_KEY_FOR_LINK_SUCCESS: 'interview/get_key_for_link_success',
  RENAME_INTERVIEW_SUCCESS: 'interview/rename_interview_success' ,
  DELETE_INTERVIEW_SUCCESS: 'interview/delete_interview_success',
  UPDATE_INTERVIEW_SUCCESS: 'interview/update_interview_success',
  CREATE_INTERVIEW_SUCCESS: 'interview/create_interview_success',
  DEFAULT_INTERVIEW_VARIABLES: 'interview/default_contact_variables',
  STATUS_MESSAGE_SUCCESS: 'interview/status_message_success',
  SEND_INVITE_INTERVIEW_SUCCESS: 'interview/send_invite_interview_success',
  UPDATE_MARKERS_INTERVIEW_SUCCESS: 'interview/update_markers_interview_success',
  DELETE_MARKERS_INTERVIEW_SUCCESS: 'interview/delete_markers_interview_success',

}