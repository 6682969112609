import { v4 as uuidv4 } from 'uuid';
import types from "../types";
import { JANUS, MEDIA } from "../constants";

const initialStore = {
  connected: false,
  registered: false,
  call_state: JANUS.NOT_IN_CALL,
  localSource: null,
  remoteSource: null,
  recording_id: uuidv4(),
  recording_time: null,
  recording: {
    local: MEDIA.STOP,
    cloud: MEDIA.STOP,
  }
};

const app = (state = initialStore, action) => {
  switch (action.type) {

    case types.janus.TICK_SECOND:
      return {
        ...state,
        recording_time: state.recording_time + 1,
      };

    case types.janus.INIT_JANUS:
      return { ...state, 
        connected: false,
        registered: false,
        recording_time: 0,
        recording_id: uuidv4(),
        call_state: JANUS.NOT_IN_CALL,
        recording: {
          local: MEDIA.STOP,
          cloud: MEDIA.STOP,
        }
      };

    case types.janus.SET_CONNECTED:
      return { ...state, connected: action.payload };
    
    case types.janus.SET_REGISTERED:
      return { ...state, registered: action.payload };
    
    case types.janus.SET_CALL_STATE:
      return { 
        ...state, 
        recording_id: (action.payload !== JANUS.IN_CALL && state.call_state === JANUS.IN_CALL) ? uuidv4() : state.recording_id,
        recording_time: (action.payload === JANUS.IN_CALL) ? state.recording_time : 0,
        call_state: action.payload 
      };

    case types.janus.SET_LOCAL_STREAM:
      return { ...state, localSource: action.payload };
    
    case types.janus.SET_REMOTE_STREAM:
      return { ...state, remoteSource: action.payload };

    case types.janus.GENERATE_CALL_ID:
      return { ...state, recording_id: uuidv4() };

    case types.janus.SET_LOCAL_RECORDING:
      return { ...state, recording: {...state.recording, local: action.payload} };

    case types.janus.SET_CLOUD_RECORDING:
      return { ...state, recording: {...state.recording, cloud: action.payload} };
      
    default:
      return {
        ...state,
      };
  }
};

export default app;
