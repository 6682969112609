import React from "react";
import { generatePath, Outlet, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Page from "../../containers/Page";


const ManageRecording = () => {
  const { interviewId } = useParams();

  return (
    <Page titleText="YOUR RECORDINGS" backButton url={generatePath(ROUTES.INTERVIEW_EDIT, { interviewId })}>
      <Outlet />
    </Page>
  );
};

export default ManageRecording;
