import types from "../types";
import { PATH } from "../constants";

const initialStore = {
  title: "JOURNALIST WORKSPACE",
  basename: PATH.BASE_URL || '/',
  loading: true,
  authorized: null,
  token: null,
  profile: {},
  isMenuOpen: false,
  askBeforeNavigate: false,
  confirmationDialog: {
    isShowing: false,
  },
};

const app = (state = initialStore, action) => {
  switch (action.type) {

    case types.app.UPDATE_TOKEN:
      return { ...state, token: action.payload };
    
    case types.app.UPDATE_LOADING:
      return { ...state, loading: action.payload };

    case types.app.UPDATE_USER_PROFILE_DATA:
      return { ...state, profile: action.payload };

    case types.app.TOGGLE_MENU_OPEN:
      return { ...state, isMenuOpen: (action.toggle !== undefined) ? action.toggle : !state.isMenuOpen };

    case types.app.ASK_BEFORE_NAVIGATE:
      return { ...state, askBeforeNavigate: action.payload };

    case types.app.SHOW_CONFIRMATION_DIALOG:
      return { ...state, confirmationDialog: action.payload };

    default:
      return {
        ...state,
      };
  }
};

export default app;
