import { Button } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  normal: {
    background: `radial-gradient(circle at left, ${theme.palette.orange.main} 50%, ${theme.palette.primary.background} 50%)`,
    backgroundPosition: 'left top',
    backgroundSize: '200% 100%',
    animationName: `$progressEffect`,
    animationTimingFunction: 'linear',
    animationDirection: 'normal',
  },
  reverse:{
    animationDirection: 'reverse',
  },
  "@keyframes progressEffect": {
    from: {
      backgroundPosition: 'right top',
      backgroundSize: '200% 100%'
    },
    '49.9%':{ backgroundPosition: 'right top' },
    '50%': { backgroundSize: '100% 100%' },
    '50.1%': { backgroundPosition:'left top' },
    to: {
      backgroundPosition: 'left top',
      backgroundSize: '200% 100%',
    }
  }
}));

const Progress  = ({normal = false, reverse = false, duration = '5000ms', children, ...props }) => {
  const classes = useStyles();

  return (
    <Button 
      variant="cBlue"
      sx={{ width: 200 }}
      style={{
        animationDuration: `${duration}`
      }}
      className={clsx({
        [classes.reverse]: reverse,
        [classes.normal]: normal,
      })}
      {...props}
    >
      {children}
    </Button>
  );
};

export default Progress;