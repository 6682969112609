/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_CONTACT: 'contacts/get_contact',
    EDIT_CONTACT: 'contacts/edit_contact',
    UPDATE_CONTACT: 'contacts/update_contact',
    SAVE_CONTACT: 'contacts/save_contact',
    ADD_CONTACT: 'contacts/add_contact',
    DELETE_CONTACT: 'contacts/delete_contact',
    DEFAULT_CONTACT: 'contacts/default_contact',
    SWITCH_FORM: 'contacts/switch_form',
    FETCH_STATUS: 'contacts/fetch_status',

  // success
  GET_CONTACT_SUCCESS: 'contacts/get_contact_success',
  EDIT_CONTACT_SUCCESS: 'contacts/edit_contact_success',
  UPDATE_CONTACT_SUCCESS: 'contacts/update_contact_success',
  SAVE_CONTACT_SUCCESS: 'contacts/save_contact_success',
  ADD_CONTACT_SUCCESS: 'contacts/add_contact_success',
  DELETE_CONTACT_SUCCESS: 'contacts/delete_contact_success',
  DEFAULT_CONTACT_SUCCESS: 'contacts/default_contact_success',
  OPEN_SOME_FORM_SUCCESS: 'contacts/open_some_form_success',
}