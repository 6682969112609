/* eslint-disable react-hooks/rules-of-hooks */
import { call, put } from "redux-saga/effects";
import { generatePath } from "react-router";
import types from "../../types";
import { fetchWrapper } from "../fetchings";

export function* getInterviews(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/getInterviews", action.data );
  yield put({ type: types.interview.GET_INTERVIEWS_SUCCESS, payload: response });
}

export function* getInterview(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/getInterview", action.data );
  yield put({ type: types.interview.GET_INTERVIEW_SUCCESS, payload: response });
}
export function* getKeyForLink(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/KeyForLink", action.data );
  yield put({ type: types.interview.GET_KEY_FOR_LINK_SUCCESS, payload: response });
}
export function* renameInterviews(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/rename", action.data );
  yield put({ type: types.interview.RENAME_INTERVIEW_SUCCESS, payload: response });
}

export function* deleteInterviews(action) {
  const response = yield call( fetchWrapper().delete, "v1/interviews/delete", action.data );
  yield put({ type: types.interview.DELETE_INTERVIEW_SUCCESS, payload: response });
}

export function* createInterviews(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/create", action.data );
  yield put({ type: types.interview.CREATE_INTERVIEW_SUCCESS, payload: response });
}

export function* updateInterviews(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/update", action.data );
  yield put({ type: types.interview.UPDATE_INTERVIEW_SUCCESS, payload: response });
}
export function* updateMarkersInterview(action) {
  const path = generatePath(`v1/interviews/:interviewId/markers`, {interviewId: action.data.id});
  const response = yield call( fetchWrapper().put, path, action.data.markers );
  yield put({ type: types.interview.UPDATE_MARKERS_INTERVIEW_SUCCESS, payload: response });
}
// export function* deleteteMarkersInterview(action) {
//   const response = yield call( fetchWrapper().post, "v1/interviews/deleteMarkersInterview", action.data );
//   yield put({ type: types.interview.DELETE_MARKERS_INTERVIEW_SUCCESS, payload: response });
// }
export function* sendInviteInterviews(action) {
  const response = yield call( fetchWrapper().post, "v1/interviews/invite", action.data );
  yield put({ type: types.interview.SEND_INVITE_INTERVIEW_SUCCESS, payload: response });
}
