/* eslint-disable react-hooks/exhaustive-deps */
import { Box, styled } from "@mui/material";
import React, { useEffect, useRef, useCallback, useState } from "react";

const BoxEnd = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "auto",
  maxHeight: "95px",
  height: "100%",
  overflow: "hidden",
  maxWidth: "415px",
  "& > div": {
    display: "block",
    width: "1px",
    position: "relative",
    border: "2px solid transparent",
    borderRadius: "20px",
    height: "20%",
    padding: "0 2px",
    margin: "0 1px",
    visibility: "hidden",
    backgroundColor: `${theme.palette.primary.text}`,
    transform: "scaleY( .6 )",
    opacity: "1",
    transition: "all 1s linear",
  },
}));

const AudioVisual = ({start, stream, isMute, pause, stop, ...props}) => {
  const audioContext = new AudioContext();
  const requestRef = useRef();
  const [countPause, setCountPause] = useState(0);
  const analize = audioContext.createAnalyser();
  let visualElements = 32,
    inretvalId,
    starts = 0;

  const main = useRef();
  useEffect(() => {
    if (stream && stream.getAudioTracks() && !pause) {
      startin();
    }
    if (pause) {
      setCountPause((int) => parseInt(int + 1));
    }
    if (stop) {
      setCountPause(0);
    }
    return () => {
      clearInterval(inretvalId);
      cancelAnimationFrame(requestRef.current);
    };
  }, [start, stream, isMute, requestRef, pause]);

  const startin = () => {
    const source = audioContext.createMediaStreamSource(stream);
    if (source) {
      source.connect(analize);
      analize.smoothingTimeConstant = 0.05;
      analize.fftSize = 32;
    }
    initRenderLoop();
  };

  const sortMiddle = (data) => {
    let l = [],
      m = [],
      r = [];
    m[2] = data[9];
    m[1] = data[8];
    m[0] = data[7];
    m[3] = data[6];
    m[4] = data[5];
    l[0] = data[0];
    l[1] = data[4];
    l[2] = data[3];
    r[0] = data[2];
    r[1] = data[1];
    return l.concat(m, r);
  };
  const processFrame = (data) => {
    const values = Object.values(data);
    let divBlocks = main.current?.querySelectorAll("div"),
      i;
    for (i = 0; i < visualElements; ++i) {
      const value =
        values[i] < 150
          ? values[i] < 100 && values[i] > 50
            ? values[i] / 3
            : 20
          : values[i] > 250
          ? values[i] / 3
          : values[i] / 2;

      const elm = document.createElement("div");
      elm.style.height = `${value}%`;
      elm.style.width = "1px";
      elm.style.borderRadius = "50px";
      elm.style.visibility = "hidden";

      elm.style.border = "2px solid transparent";
      if (divBlocks.length <= 0) {
        main.current.style.marginLeft = "243px";
        main.current.appendChild(elm);
        elm.style.visibility = "visible";
        elm.style.transition = "all .1s linear";
        return;
      }
      if (divBlocks.length >= 42) {
        main.current.removeChild(divBlocks[0], divBlocks[1]);
      }
      if (divBlocks.length > 21) {
        main.current.appendChild(elm);
        elm.style.visibility = "visible";
        elm.style.transition = "all .1s linear";

        return;
      }
      main.current.style.marginRight = '33.6px'
      main.current.style.marginLeft =
        parseInt(main.current.style.marginLeft) - 9.4 + "px"
      main.current.insertBefore(elm, divBlocks[0]);
      elm.style.visibility = "visible";
      elm.style.transition = "all .1s linear";
      return;
    }
  };
  const processFrameWidth = (data) => {
    const values = Object.values(data);
    let divBlocks = main.current.querySelectorAll("div");
    let i;
    for (i = 0; i < 10; ++i) {
      const value = values[i];
      divBlocks[i].style.height = `${value > 20 ? value : 20}%`;
    }
  };
  const renderWidth = (e) => {
    if (parseInt(e) >= parseInt(starts)) {
      starts = parseInt(starts) + 200;
      const frequencyData = new Uint8Array(analize.frequencyBinCount);
      analize.getByteFrequencyData(frequencyData);
      processFrameWidth(sortMiddle(frequencyData.sort().slice(0, 10)));
    }
    requestRef.current = requestAnimationFrame(renderWidth);
  };
  const renderFrame = useCallback((e) => {
    const frequencyData = new Uint8Array(analize.frequencyBinCount);
    analize.getByteFrequencyData(frequencyData.sort().reverse());
    processFrame(frequencyData.sort().reverse(), e);
  });
  const initRenderLoop = () => {
    starts = 0;
    visualElements = 32;
    clearInterval(inretvalId);
    cancelAnimationFrame(requestRef.current);
    if (countPause <= 0 || stop) {
      main.current.innerHTML = "";
    }
    main.current.style.maxWidth = "410px";

    let divBlocks = main.current.querySelectorAll("div");
    if (start === true) {
      main.current.style.justifyContent = "start";
      cancelAnimationFrame(requestRef.current);
      inretvalId = setInterval(() => {
        renderFrame(divBlocks);
      }, 100);
      return;
    }

    main.current.style.justifyContent = "center";
    main.current.style.marginLeft = "0px";
    main.current.style.maxWidth = "100%";
    if (divBlocks.length <= 0) {
      for (let index = 0; index < 10; ++index) {
        const elm = document.createElement("div");
        elm.style.width = "1px";
        elm.style.visibility = "visible";

        elm.style.borderRadius = "50px";
        elm.style.transition = "all .1s linear";
        elm.style.border = "2px solid transparent";
        main.current.appendChild(elm);
      }
    }
    clearInterval(inretvalId);
    requestRef.current = requestAnimationFrame(renderWidth);
  };
  return (
    <BoxEnd sx={{ height: "100%" }} ref={main}></BoxEnd>
  );
};
export default AudioVisual;
