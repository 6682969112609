export default (theme) => ({
    components: {
        MuiClockPicker: {
            styleOverrides: {
                root: {
                    "& .css-19ugm16": {
                        backgroundColor: `${theme.palette.blue.light}`
                    },
                    "& .css-19bhfl0-MuiButtonBase-root-MuiIconButton-root": {
                        backgroundColor: `${theme.palette.blue.light}`,
                        color: `${theme.palette.primary.text}`
                    },
                    "& .css-syoxz5.Mui-selected": {
                        backgroundColor: `${theme.palette.blue.light}`
                    },
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: `${theme.palette.blue.light}`
                    },
                }
            }
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    "& .MuiPickersDay-dayWithMargin": {
                        backgroundColor: `${theme.palette.primary.backgroundAll}`
                    }
                }
            }
        }
    }
})