import { all, takeLatest, call, put } from "redux-saga/effects";
import types from '../types';
import constants from '../constants';
import * as app from './functions/functionsApp';
import * as interviews from './functions/functionsInterviews';
import * as contacts from './functions/functionsContacts';
import * as settings from './functions/functionsSettings';
// import * as audiosetting from './functions/functionsAudiosettings';
import * as managerecording from './functions/functionsManageRecordings';

const safe = (fetchStatusAction, sagaWorker, ...argsuments) => function* (action) {
  let saga = sagaWorker;
  let args = argsuments;
  if(typeof fetchStatusAction === 'function'){
    saga = fetchStatusAction;
    args = sagaWorker ? [sagaWorker, ...args] : [];
  }
	try {
    yield put({ type: fetchStatusAction, fetchStatus: constants.fetch.LOADING});
		yield call(saga, ...args, action);
    yield put({ type: fetchStatusAction, fetchStatus: constants.fetch.FINISHED});
	} catch (err) {
    yield put({ type: fetchStatusAction, fetchStatus: constants.fetch.FAILED});
    yield put({ type: types.notifications.ENQUEUE_SNACKBAR, notification: {
      message: err.toString()
    } });
    console.error('API:', err)
	}	
}

const startSagas = function (fetchStatusAction, sagas){
  return Object.entries(sagas)
    .map( ([key, value]) => takeLatest(key, safe(fetchStatusAction, value)) );
}

const saga = function* (params) {
  
  // contacts
  yield* startSagas(types.contacts.FETCH_STATUS,{
    [types.contacts.GET_CONTACT]: contacts.getContacts,
    [types.contacts.ADD_CONTACT]: contacts.createContact,
    [types.contacts.EDIT_CONTACT]: contacts.fetchContact,
    [types.contacts.SAVE_CONTACT]: contacts.fetchSaveContact,
    [types.contacts.DELETE_CONTACT]: contacts.fetchDeleteContact,
    [types.contacts.DEFAULT_CONTACT]: contacts.defaultVariables,
    [types.contacts.SWITCH_FORM]: contacts.switchForm,
  });

  // general settings
  yield* startSagas(types.settings.FETCH_STATUS,{
    [types.settings.GET_GENERAL_SETTINGS]: settings.getSettings,
    [types.settings.UPDATE_GENERAL_SETTINGS]: settings.updateSettings,
    [types.settings.CHANGE_THEME]: settings.changeTheme,
  });

  // interviews
  yield* startSagas(types.interview.FETCH_STATUS,{
    [types.interview.GET_INTERVIEWS]: interviews.getInterviews,
    [types.interview.GET_INTERVIEW]: interviews.getInterview,
    [types.interview.GET_KEY_FOR_LINK]: interviews.getKeyForLink,
    [types.interview.SEND_INVITE_INTERVIEW]: interviews.sendInviteInterviews,
    [types.interview.RENAME_INTERVIEW]: interviews.renameInterviews,
    [types.interview.DELETE_INTERVIEW]: interviews.deleteInterviews,
    [types.interview.CREATE_INTERVIEW]: interviews.createInterviews,
    [types.interview.UPDATE_INTERVIEW]: interviews.updateInterviews,
  });

  // markers
  yield* startSagas(types.interview.MARKERS_FETCH_STATUS,{
    [types.interview.UPDATE_MARKERS_INTERVIEW]: interviews.updateMarkersInterview,
    [types.recordingsmanage.UPDATE_MARKERS_RECORDING]: managerecording.updateMarkersInterview,
  });

  // manage recording
  yield* startSagas(types.recordingsmanage.FETCH_STATUS, {
    [types.recordingsmanage.GET_MANAGE_RECORDING]: managerecording.getAll,
    [types.recordingsmanage.GET_RECORDING]: managerecording.getOne,
    [types.recordingsmanage.GET_MARKERS]: managerecording.getMarkers,
    [types.recordingsmanage.GET_AUDIO_URL]: managerecording.getAudio,
    [types.recordingsmanage.DELETE_MANAGE_RECORDING]: managerecording.deleteRecording,
    [types.recordingsmanage.CREATE_MANAGE_RECORDING]: managerecording.create,
    [types.recordingsmanage.UPLOAD_RECORDING]: managerecording.upload,
    [types.recordingsmanage.UPDATE_MANAGE_RECORDING]: managerecording.update,
    [types.recordingsmanage.SEND_TO_EMAIL_MANAGE_RECORDING]: managerecording.sendToEmail,
    [types.recordingsmanage.SEND_TO_FTP_MANAGE_RECORDING]: managerecording.sendToFtp,
  });

  // // audio settings
  // yield* startSagas(types.media.FETCH_STATUS, {
  //   [types.media.AUDIOOUTPUT]: audiosetting.audiooutput,
  //   [types.media.SOURCE]: audiosetting.source,
  //   [types.media.AUDIOINPUT]: audiosetting.audioinput,
  // });

  // auth
  yield* startSagas(types.app.FETCH_STATUS, {
    [types.app.GET_AUTH_TOKEN]: app.getAuthToken,
    [types.app.GET_AUTH_TOKEN_BY_USER]: app.getAuthTokenByUser,
    [types.app.VALIDATE_AUTH_TOKEN]: app.validateAuthToken,
    [types.app.GET_USER_PROFILE_DATA]: app.getUserProfileData,
    [types.app.LOGOUT]: app.logout,
  });

};
export default saga;
