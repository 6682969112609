import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyAvatar = (props) => {
  const generateAvatar = () => {
    let name = props.name.split(" ");
    return (
      <Typography
        sx={{ fontWeight: 400, lineHeight: "20px", fontSize: "20px", mt: 0.8, color: 'primary.backgroundAll'}}
      >
        {name.map((n) => {
          return n.charAt(0);
        })}
      </Typography>
    );
  };
  return (
    <Box
      sx={{
        maxWidth: 32,
        minHeight: 32,
        width: "100%",
        borderRadius: 32,
        textAlign: "center",
        bgcolor: "#BDBDBD",
        "&:hover": {
          bgcolor: "#BDBDBD",
        },
      }}
    >
      {generateAvatar()}
    </Box>
  );
};
export default EmptyAvatar;
