export const validators = {
  "name": validateName,
  "email": validateEmail,
  "phone": validatePhoneNumber,
  "second_phone": validatePhoneNumber,
};

// // ******************************
// export const validator = (values, fieldName) => {
//   let errors = {};
//   fieldValidators[fieldName]((values[fieldName], errors));
//   return errors;
// };

// ******************************
function validateName(value){
  if (value.length === 0) {
    return "Contact name is Required";
  }
  if (value.length < 2 ) {
    return "Contact name must contain at least two characters";
  }
  if ( value.length > 50 ) {
    return `Contact name can not exceed 50 characters`;
  }
}

// ******************************
function validatePhoneNumber(value) {
  let clear = new RegExp(/[^0-9\+]/);
  if (value && clear.test(value)) {
    return "Phone number can contain only + character (in the beginning) and digits (maximum 20 digits)";
  }
}

// ******************************
function validateEmail(value) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let result = re.test(String(value).toLowerCase());
  if (value && !result) {
    return "Incorrect email";
  }
}

