/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from "@mui/icons-material";
import { Divider, Grid, Chip, Typography, TextField, Stack, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { JANUS } from "../../../constants";
import types from "../../../types";

const Markers = ({ item, itemRec, seconds, start, updateMarkersInterview, updateMarkersRecording, callState, ...props }) => {
  const { interviewId } = useParams();
  const isCall = callState !== JANUS.IN_CALL
  const [markers, setMarkers] = useState(itemRec?.markers || []);
  const [predefined, setPredefined] = useState(item?.markers?.predefined || []);
  const [text, setText] = useState("");
  const [sec, setSec] = useState(null);
  const inputRef = useRef()
  useEffect(() => { 
    setMarkers(itemRec?.markers || []);
    setPredefined(item?.markers?.predefined || []);
  },[item.markers, itemRec.markers]);

  useEffect(() => {
    if(text.length >= 1 && sec === null) {
      setSec(seconds)
    }
  }, [text.length >= 1])

  useEffect(() => { 
    let timer;
    if(text.length < 1) {
      timer = setTimeout(() => {
        setSec(null)
      }, 5000);
    }
    if(text.length > 1) {
      clearTimeout(timer)
    }
    return () => clearTimeout(timer);
  }, [text])

  const persistMarkers = (m) => {
    callState === JANUS.IN_CALL &&
    updateMarkersRecording({
      id: itemRec?._id, 
      markers: { markers: m.recording } 
    }) 
    m.predefined &&
    updateMarkersInterview({
      id:interviewId, 
      markers: { predefined: m.predefined }
    });
  }

  const submitMarkers = (data) => {
    persistMarkers({
      ...item?.markers,
      [callState === JANUS.IN_CALL ? 'recording' : 'predefined']: data 
    });
    onClearTimer();
  }

  const onKeyDown = (e) => {
    if(e.keyCode === 13 && text !== ""){
      submitMarkers([ ...(callState === JANUS.IN_CALL ? markers : predefined), {
        id: uuidv4(),
        sec,
        text
      }]);
    }
  }

  const handleDelete = (id) => {
      persistMarkers({
        ...item?.markers,
        predefined: predefined.filter(m => m.id !== id), 
        recording: markers.filter(m => m.id !== id), 
      });
  };
  const handleClick = (e) => {
    submitMarkers([ ...markers, {
      id: uuidv4(),
      sec: seconds,
      text: e.target.innerText
    }]);
  }

  const handleClickPredefined = (id) => {
    // do not transfer predefined markers if not in call
    if(callState !== JANUS.IN_CALL){
      return;
    }

    const marker = predefined.find( m => m.id === id);
    persistMarkers({
      ...item?.markers,
      recording: [ ...markers, {...marker, sec: seconds}],
      predefined: predefined.filter(m => m.id !== id),
    });
  }

  const onChangeInput = (e) => setText(e.target.value)

  const generateTime = (s) => { 
    const sec = Math.floor(s % 60);
    const min = Math.floor(s / 60);
    return `${min}m:${("00" + sec).slice(-2)}s`;
  }

  const onClearTimer = () => {
    setText('')
    setSec(null)
  }
  return (
    <Grid 
      container
      direction="column"
      justifyContent="space-between"
      sx={{ borderRadius: 1, height: '100%' }} 
      bgcolor="primary.backgroundAll" 
      p={1}
    >
      <Typography variant="markersText" minHeight={45} ml={1}>
        Markers:
      </Typography>

      <Divider sx={{ mb: 1 }} />

      <Grid container item flexDirection="column" flexWrap="nowrap" sx={{ flex: "1 1 auto", overflowY: "auto", height: "0px" }} >
      { predefined?.map((item, i) => (
          <Grid item key={i} mt={.5}>
            <Chip
              sx={{ minHeight: "30px", height: "auto", }}
              color="blue"
              onClick={(e) => handleClickPredefined(item.id)}
              label={(
                <Grid container item>
                  <Grid><Typography variant="modalSubText"> {item.text} </Typography></Grid>
                </Grid>
              )}
              variant="contained"
              onDelete={(e) => handleDelete(item.id)}
            />
          </Grid>
        ))}
        { (markers?.length > 0) && <Divider sx={{ mt: 1 }}>Current recording</Divider>}
        { markers?.map((item, i) => (
          <Grid item key={i} mt={.5}>
            <Chip
              sx={{ minHeight: "30px", height: "auto"}}
              color="primary"
              label={(
                <Grid container item>
                  <Grid><Typography variant="modalSubText" mr={1}>{generateTime(item.sec)}</Typography></Grid>
                  <Grid><Typography variant="modalSubText"> {item.text} </Typography></Grid>
                </Grid>
              )}
              variant="outlined"
              onDelete={(e) => handleDelete(item.id)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container item justifyContent="center" mt={1}>
        <Grid height="52px" item xs>
          <TextField
            label={`Add marker: ${(sec !== null) ? generateTime(sec) : ''}`}
            name="name"
            disabled={start}
            focused
            onChange={onChangeInput}
            onKeyDown={onKeyDown}
            value={text}
            InputProps={{
              endAdornment: ( (sec !== null) && <IconButton onClick={onClearTimer}><Close /></IconButton>)
            }}
            inputProps={{
              ref: inputRef,
              maxLength: 40
            }}
          />
        </Grid>
        {/* <Grid height="25px" item xs={12}>
          <Typography variant="smaller">{(sec !== null) && generateTime(sec)}</Typography>
        </Grid> */}
        <Stack
          width="100%"
          mt={1}
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
        >
          <Stack spacing={1} mb="1px" direction="row">
            <Chip
              sx={{ height: { xs: '24px', lg: '32px' } }}
              color="primary"
              label="Highlight"
              variant="outlined"
              onClick={handleClick}
              disabled={isCall}
            />
            <Chip
              sx={{ height: { xs: '24px', lg: '32px' } }}
              color="primary"
              label="Cue in"
              variant="outlined"
              onClick={handleClick}
              disabled={isCall}
            />
          </Stack>
          <Stack spacing={1} direction="row">
            <Chip
              sx={{ height: { xs: '24px', lg: '32px' } }}
              color="primary"
              label="Cue out"
              variant="outlined"
              onClick={handleClick}
              disabled={isCall}
            />
            <Chip
              sx={{ height: { xs: '24px', lg: '32px' } }}
              color="primary"
              label="Noise"
              variant="outlined"
              onClick={handleClick}
              disabled={isCall}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  item: state.interview.item,
  itemRec: state.managerecordings.item,
  seconds: state.janus.recording_time || 0,
  callState: state.janus.call_state
})
const mapDispatchToProps = (dispatch) => ({
  updateMarkersInterview: (data) => dispatch({ type: types.interview.UPDATE_MARKERS_INTERVIEW, data }),
  updateMarkersRecording: (data) => dispatch({ type: types.recordingsmanage.UPDATE_MARKERS_RECORDING, data }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Markers);
