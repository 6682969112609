/* eslint-disable import/no-anonymous-default-export */
export default {
    SET_DEVICES: 'media/set_devices',
    TOGGLE_MENU_OPEN: 'media/toggle_menu_open'
    // AUDIOINPUT: 'contacts/audioinput',
    // AUDIOOUTPUT: 'contacts/audiooutput',
    // SOURCE: 'contacts/source',
    
    // // success
    // AUDIOINPUT_SUCCESS: 'contacts/audioinput_success',
    // AUDIOOUTPUT_SUCCESS: 'contacts/audiooutput_success',
    // SOURCE_SUCCESS: 'contacts/source_success',
}