import { Fab, Grid} from "@mui/material";
import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import constants, { PATH, ROUTES } from "../../../constants";
import types from "../../../types";
import NavBarMarkers from "./NavBarMarkers";
import { v4 as uuidv4 } from 'uuid';
import useNavigation from "../../../hooks/useNavigation";
import { ArrowBack } from "@mui/icons-material";
import { generatePath, useParams } from "react-router-dom";
const fabStyle = {
  position: 'absolute',
  top: 16,
  left: 6,
  marginBottom: "-56px",
};
const AudioMass = ({ selectedItems, items, token, ...props }) => {
  const { navigate, setAskBeforeNavigate } = useNavigation();
  const { interview_id } = useParams()

  useEffect(() => setAskBeforeNavigate(true) ,[]);

  const name = () => {
    let itemName = items[items.length-1].name
   return itemName?.includes("edit") ? 
    (
      itemName.split('-',-1)[0] + "-edit-" + Math.floor(parseInt(itemName.split('edit')[1].split('-')[1])+1)
    ) :
    itemName + "-edit-1"
  }

  const generateUrl = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.append("base_url", constants.path.API_BASE_URL +"v1/managerecording/audio/")
    searchParams.append("recording_ids", selectedItems)
    searchParams.append("file_name", name())
    searchParams.append("interview_id", items[0].interview_id)
    searchParams.append("new_recording_id", uuidv4())
    searchParams.append("token", token)
    return "?" + searchParams.toString()
  }, [selectedItems, items, token]);

  const handleBack = () => {
    navigate(generatePath(ROUTES.MANAGERECORDING_INTERVIEW, { interviewId: interview_id }));
  }

  return (
    <Grid container item xs>
      <Grid
        sx={{
          position: "relative",
          height: "calc(100vh - 97px)",
          "& iframe": {
            position: "absolute",
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            border: 0,
          },
        }}
        item 
        xs
      >
        <Fab onClick={handleBack} aria-label="back" sx={{...fabStyle}}><ArrowBack /></Fab>

        <iframe
          title="audiomass"
          seamless
          src={PATH.AUDIOMASS_URL + generateUrl}
          allow="fullscreen"
          sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts"
        />
      </Grid>
      <NavBarMarkers />
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  items: state.managerecordings.items,
  selectedItems: state.managerecordings.selectedItems,
  token: state.app?.token,
  urls: state.managerecordings.urls
})
const mapDispatchToProps = (dispatch) => ({
  getAudio: (data) => dispatch({ type: types.recordingsmanage.GET_AUDIO_URL, data})
})
export default connect(mapStateToProps, mapDispatchToProps)(AudioMass);
