/* eslint-disable import/no-anonymous-default-export */
export default {
  HOME: '/',

  INTERVIEW: '/interview',
  INTERVIEW_INVITE: '/interview/:interviewId/invite',
  INTERVIEW_EDIT: '/interview/:interviewId',
  INTERVIEW_SUCCESS_SEND: '/interview/invited_form/success_send/:interviewId',
  INTERVIEW_MARKERS: '/interview/:interviewId/markers',

  AUDIO_SETTING_INTERVIEW: '/audiosetting',
  AUDIO_SETTING_INTERVIEW_ID: '/audiosetting/:interviewId',

  ADRESSBOOK: '/addressbook',
  ADRESSBOOK_NEW: '/addressbook/new',
  ADRESSBOOK_NEW_OUTSIDE: '/addressbook/new/:outside',
  ADRESSBOOK_EDIT: '/addressbook/:contactId',

  INTERVIEWERS: '/interviewers',

  MANAGERECORDING: '/managerecording',
  MANAGERECORDING_INTERVIEW: '/managerecording/:interviewId',
  MANAGERECORDING_SEND_TO_FTP: '/managerecording/sendtoftp',
  MANAGERECORDING_SEND_TO_EMAIL: '/managerecording/sendtoemail',
  MANAGERECORDING_POSTPRODACTION: '/managerecording/:interviewId/postproduction',

  SETTINGS: '/settings',
  UPLOADIMAGE: '/settings/uploadimage',
  AUDIOMASS: '/audiomass',

  EDIT_RECORDINGS:'/edit_recordings/:interview_id',
  NOT_FOUND: '/*'
}