import { call, put } from "redux-saga/effects";
import types from "../../types";
import { fetchWrapper } from "../fetchings";

export function* getSettings(action) {
  const response = yield call(fetchWrapper().get, "v1/generalsettings/getSettings", action.data );
  yield put({ type: types.settings.GET_GENERAL_SETTINGS_SUCCESS, payload: response });
}
export function* updateSettings(action) {
  const response = yield call(fetchWrapper().post, "v1/generalsettings/updateSettings", action.data );
  yield put({ type: types.settings.UPDATE_GENERAL_SETTINGS_SUCCESS, payload: response });
}
export function* changeTheme(action) {
  yield put({ type: types.settings.CHANGE_THEME_SUCCESS, data: action.data });
}
