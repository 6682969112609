export default {
  INIT_JANUS: 'janus/init_janus',
  SET_CONNECTED: 'janus/set_connected',
  SET_REGISTERED: 'janus/set_registered',
  SET_CALL_STATE: 'janus/set_call_state',
  SET_LOCAL_STREAM: 'janus/set_local_stream',
  SET_REMOTE_STREAM: 'janus/set_remote_stream',
  GENERATE_CALL_ID: 'janus/generate_call_id',
  TICK_SECOND: 'janus/tick_second',
  SET_LOCAL_RECORDING: 'janus/set_local_recording',
  SET_CLOUD_RECORDING: 'janus/set_cloud_recording',
}