/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Switch, Tooltip, Button, ImageList, ImageListItem, ListItemButton } from "@mui/material";
import { AddPhotoAlternate, Cancel,  } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as Jpg } from "../../../images/jpg.svg";
import { ReactComponent as Png } from "../../../images/png.svg";
import types from "../../../types";
import Preloader from "../../containers/Preloader";
import HelpTip from "../../containers/HelpTip";

import useNavigation from "../../../hooks/useNavigation";
import constants, { FETCH, ROUTES } from "../../../constants";

const GlobalForm = ({ 

  isAutorecordingLocal,
  isAutorecordingCloud,
  theme, 
  profile, 
  loading, 
  setTheme, 
  updateSettings,
  carouselImages, 
  customCarusel,
  ...props }) => {
    
  const [ is_autorecording_local, setIsAutorecordingLocal ] = useState(isAutorecordingLocal);
  const [ is_autorecording_cloud, setIsAutorecordingCloud ] = useState(isAutorecordingCloud);
  const [ is_carousel, setIsCarousel] = useState(customCarusel);
  const { navigate } = useNavigation();
  useEffect(() => {
    setIsCarousel(customCarusel);
    setIsAutorecordingLocal(is_autorecording_local);
    setIsAutorecordingCloud(is_autorecording_cloud);
    setTheme(theme);
  }, [customCarusel, isAutorecordingCloud, isAutorecordingLocal, theme]);
  const setCustomCarousel = (e) => {
    setIsCarousel(e.target.checked);
    updateSettings({customCarusel: !is_carousel});
  };

  const setLocal = (e) => {
    setIsAutorecordingLocal(e.target.checked);
    updateSettings({is_autorecording_local: !is_autorecording_local});
  };
  const setCloud = (e) => {
    setIsAutorecordingCloud(e.target.checked);
    updateSettings({is_autorecording_cloud: !is_autorecording_cloud});
  };
  const openUploadPage = () => navigate( ROUTES.UPLOADIMAGE )
  const deleteImage = (id) => {
    const images = carouselImages.filter(i => i.id !== id);
    updateSettings({carouselImages: images});
  };

  const onChangeTheme = (e) => {
    const t = theme === constants.themes.DARK 
      ? constants.themes.LIGHT 
      : constants.themes.DARK
    setTheme(t);
    updateSettings({theme: t});
  }
  const isDarkMode = theme === constants.themes.DARK;
  
  return (
    <>
      {/*(carouselImages?.length <= 0 && false) ||*/ loading ? (
        <Grid justifyContent="center" container item xs={12}>
          <Preloader />
        </Grid>
      ) : (
        <Grid noValidate autoComplete="off" component="form" justifyContent="center" container item xs={12}>
          <Grid alignItems="center" container item xs={12} md={9}>
            <Grid item xs={12} sm={7}>Dark mode:</Grid>
            <Grid item xs={12} sm={5} container alignItems="center">
              Disable
              <Switch onChange={onChangeTheme} checked={isDarkMode} />
              Enable
              <HelpTip>
                When this toggle button is set to “Enable”, then all the pages of the interview workspace
                are displayed in dark mode.
              </HelpTip>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={9} sx={{mt: 3}}>
              <b>Customise image carousel on the guest connection page</b>
          </Grid>

          <Grid alignItems="center" container item xs={12} md={9}>
            <Grid item xs={12} sm={7}>Guest Carousel:</Grid>
            <Grid item xs={12} sm={5} container alignItems="center">
              Default
              <Switch onChange={setCustomCarousel} checked={is_carousel} />
              Custom
              <HelpTip>
                This toggle button determines the presence of the carousel on the IQOYA GUEST home page: <br/>
                <li>If set to "Custom", the IQOYA GUEST user will see the carousel. Click on the "Load images..." button to upload the images that will loop in the carousel. If no images are uploaded, a default still image will be displayed.</li>
                <li>If set to "Default", the IQOYA GUEST user will see a default still image will be displayed.`</li>
              </HelpTip>
            </Grid>
          </Grid>

          {is_carousel &&(
            <Grid container item xs={12} md={9}>

            <Grid container item xs={12} sm={7}>
              <Button
                disabled={carouselImages?.length >= 10 && true}
                variant="cBlue"
                sx={{ maxWidth: 200, minHeight: 42, maxHeight: 42, mt: 2 }}
                onClick={(e) => openUploadPage(e)}>
                <AddPhotoAlternate sx={{ mr: 1 }} /> Load images...
              </Button>
            </Grid>

            {carouselImages.length > 0 && (
              <Grid container item xs={12} sm={5}>
              <ImageList cols={5} rows={2} gap={8} rowHeight={85} variant="quilted">
                {carouselImages.map((item, int) => (
                       <ImageListItem key={int}>
                         <ListItemButton 
                          sx={{ position: 'absolute', right: 0, p: 0, maxHeight: 18 }} 
                          onClick={() => deleteImage(item.id)}>
                            <Cancel color="primary" sx={{ maxWidth: 20 }} />
                        </ListItemButton>
                        <Grid container direction={'column'} alignItems="center" item xs={12}>
                          <Tooltip title={item.name}>
                            <Grid pt={1}>{item.img.indexOf("png") ? <Png /> : <Jpg />}</Grid> 
                          </Tooltip>
                        {/* <ImageListItemBar  title={item.name} /> */}
                        </Grid>
                    </ImageListItem>
                  ))} 
              </ImageList>
            </Grid>
            )}

          </Grid>
          )}


          {/* <Grid justifyContent="center" pt={5} container item xs={12} md={9}>
            <Button sx={{ maxWidth: 140 }} variant="contained" color="orange" onClick={submitGlobalSetting}>
              save settings
            </Button>
          </Grid> */}

        </Grid>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.setting.theme,
  profile: state.app.profile,
  carouselImages: state.setting?.carouselImages || [],
  customCarusel: state.setting?.customCarusel,
  isAutorecordingCloud: state.setting?.isAutorecordingCloud,
  isAutorecordingLocal: state.setting?.isAutorecordingLocal,
  loading: (state.setting.fetchStatus === FETCH.LOADING)
  
})
const mapDispatchToProps = (dispatch) => ({
  setTheme: (data) => dispatch({ type: types.settings.CHANGE_THEME, data }),
  updateSettings: (data) => dispatch({ type: types.settings.UPDATE_GENERAL_SETTINGS, data }),
})
export default connect(mapStateToProps, mapDispatchToProps)(GlobalForm);
