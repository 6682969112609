import { connect } from "react-redux";
import types from "../../../types";
import { useStyles } from "../../containers/Header/config";
import { ArrowBackIos, Bookmark, GraphicEq } from "@mui/icons-material";
import {styled,Drawer as MuiDrawer,MenuItem,Typography,Grid,Chip,Tooltip} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const drawerWidth = 256;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.easing,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.easing,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("xs")]: {
    width: 0,
  },
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme, open }) => ({
  width: drawerWidth,
  right: 0,
  flexShrink: 0,
  whiteSpace: "wrap",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBarMarkers = ({ isMenuOpen, items, markers, getMarkers, getInterview, selectedItems, toggleMenuOpen, ...props }) => {
  const classes = useStyles(props);
  const { interview_id } = useParams();
  const [flatMarkers, setFlatMarkers] = useState([]);

  const generateTime = (s) => { 
    const sec = Math.floor(s % 60);
    const min = Math.floor(s / 60);
    return `${min}:${("00" + sec).slice(-2)}`;
  }

  useEffect(() => {
    interview_id && getInterview( { _id: interview_id } );
  }, [interview_id]);

  useEffect(() => { 
    getMarkers({ ids: selectedItems })
  },[]);

  // flatten markers of recordings
  useEffect(() => { 
    let flaten = [];
    let lastDuration = 0;
    let mm = {};
    (markers || []).map(marker => {
      // init recording as marker
      flaten.push({
        id: marker._id,
        sec: lastDuration,
        text: marker.name,
        isRecording: true,
      });
      // iterate recording markers
      (marker.markers || []).map( m => {
        mm = {...m, sec: lastDuration + (m?.sec || 0)}
        flaten.push(mm);
      })
      // increment last duration
      lastDuration = marker?.duration ? lastDuration + marker?.duration : mm?.sec;
    });
    setFlatMarkers(flaten);
  },[markers]);

  return (
    <Drawer
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          position: "fixed",
          right: 0,
          marginTop: {
            xs: "3.5em",
            sm: "4em",
            md: "4em",
          },
          bgcolor: "primary.backgroundAll",
        },
      }}
      className="active"
      variant="permanent"
      open={isMenuOpen}
      onClose={() => toggleMenuOpen(false)}>
      <MenuItem>
        <Bookmark sx={{ mt: 1}} />
        <Typography
          variant="markersText"
          className={isMenuOpen ? classes.ItemsTextList : classes.hidden}
          ml={1}>
          Markers:
        </Typography>
      </MenuItem>
      <Grid item xs={12} p={1} sx={{
        overflowY: "auto",
      }}>
        {flatMarkers.map((item) => (
          <Grid container item key={item.id} mt={.5}>
            {!isMenuOpen
              ? 
                <Typography variant="modalSubText" mr={1}>{generateTime(item.sec)}</Typography>
              : 
                <Tooltip placement='left' title={item.text}>
                  <Chip
                    style={{width: "100%", justifyContent: "flex-start"}}
                    icon={item?.isRecording ? <GraphicEq /> : <Bookmark />}
                    variant="outlined"
                    color="primary"
                    label={(
                      <Grid container item wrap="nowrap" xs={12}>
                        <Grid item><Typography variant="modalSubText" mr={1}>{generateTime(item.sec)}</Typography></Grid>
                        <Grid item xs><Typography variant="modalSubText" sx={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}> {item.text} </Typography>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Tooltip>
            }
          </Grid>
        ))}
      </Grid>
      <MenuItem
        className={classes.menuItems}
        sx={{
          mb: 0,
          position: "absolute",
          bottom: {
            xs: "4em",
          },
          bgcolor: "primary.backgroundAll",
          alignItems: "center",
        }}
        onClick={() => toggleMenuOpen()}>
        <ArrowBackIos
          sx={{
            color: "primary.text",
            width: "20px",
            margin: `${isMenuOpen ? "0 auto" : "auto 0"}`,
            height: "20px",
            transform: `${isMenuOpen ? "rotate(180deg)" : "rotate(0deg)"}`,
          }}/>
      </MenuItem>
    </Drawer>
  );
};
const mapStateToProps = (state) => ({
  items: state.managerecordings.items,
  markers: state.managerecordings.markers,
  selectedItems: state.managerecordings.selectedItems,
  isMenuOpen: state.media.isMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getInterview: (data) => dispatch({ type: types.interview.GET_INTERVIEW, data }),
  getMarkers: (data) => dispatch({ type: types.recordingsmanage.GET_MARKERS, data }),
  toggleMenuOpen: (toggle) =>
    dispatch({ type: types.media.TOGGLE_MENU_OPEN, toggle: toggle }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarMarkers);
