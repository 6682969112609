// defined in /public/index.htlm
// override /public/env.js

export default {
  BASE_URL: window.BASE_URL,
  SIGN_IN_URL: window.SIGN_IN_URL,
  API_BASE_URL: window.API_BASE_URL,
  IQOYA_CONNECT_URL: window.IQOYA_CONNECT_URL,
  GUEST_URL: window.GUEST_URL,
  AUDIOMASS_URL: window.AUDIOMASS_URL,
}