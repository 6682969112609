import React, { useState } from "react";
import "./App.css";
import { Grid, styled } from "@mui/material";

import Header from "../../containers/Header";
import Footer from "../../containers/Footer";
import SEO from "../../containers/SEO/SEO";
import { useStyles } from "./configCss";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "../../containers/Routers";
import DialogConfirmationRaw from "../../containers/Dialog/Confirmation";
import { connect } from "react-redux";
import NavMenu from "../../containers/NavMenu";

const HomeContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [theme.breakpoints.up("lg")]: {
    justifyContent: "center",
  },
  justifyContent: "center",
  width: "100%",
  margin: "0",
  minHeight: "calc(100vh - 84px)",
  display: "flex",
  maxWidth: "100%",
}));

const App = ({title, basename, isAudioMass, ...props}) => {
  const classes = useStyles(props);
  
  return (
    <>
      <SEO title={title} />
      <Router basename={ basename }>
        <Header sx={{ bgcolor: "primary.main2", color: "primary.text" }} />
        <HomeContainer
          mt={{xs: 0, sm: isAudioMass || window.location.href.includes("edit_recordings") ? 0 : 2}}
          maxWidth="lower"
        >
          <NavMenu classes={classes} />
          <Routers />
        </HomeContainer>
        <Footer sx={{ bgcolor: "primary.main2", color: "primary.text" }} />
      </Router>
      <DialogConfirmationRaw />
    </>
  );
};
const mapStateToProps = (state) => ({
    title: state.app.title,
    basename: state.app.basename,
    isAudioMass: state.setting.isOpenAudioMass,
    token: state.contacts.token,
    statusAuth: state.contacts.statusAuth,
});
const mapDispatchToProps = (dispatch) => ({ });
export default connect(mapStateToProps, mapDispatchToProps)(App);
