import path from './path';
import janus from './janus';
import auth from './auth';
import fetch from './fetch';
import themes from './themes';
import routes from './routes';
import media from './media';
import template from './template';

export const PATH = path;
export const JANUS = janus;
export const AUTH = auth;
export const FETCH = fetch;
export const THEMES = themes;
export const ROUTES = routes;
export const MEDIA = media;
export const TEMPLATE = template;

export default {
  path,
  janus,
  auth,
  fetch,
  themes,
  routes,
  template,
  media,
};
