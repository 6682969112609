import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { 
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
  FormControlLabel, Checkbox, Grid, Box, Typography, CircularProgress 
} from "@mui/material";
import { useParams } from "react-router-dom";

import useIndexedDb from '../../../hooks/useIndexedDb';
import constants, { MEDIA } from "../../../constants";
import types from "../../../types";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontSize="1.5rem"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const RecordingsDialog = ({recording, itemRec, resetRecording, deleteRecording, saveBlob, getInterview, token, ...props }) => {
  const { interviewId } = useParams();
  const { db, clear, count, save, blob } = useIndexedDb(interviewId);
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(0);
  const uploadProgress = (data) => {
    return new Promise( async (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.addEventListener("readystatechange", function() {
          if(this.readyState === 4) {
              if(this.responseText.match(/50*/g) || (this.status !== 200)){
                  onCancel(true)
                  return reject();
              }
              return resolve();
          }
      });
      xhr.upload.addEventListener('progress', function(event) {
          const res = parseInt((event.loaded / event.total) * 100);
          setResult(res);
      });
      
      xhr.open("POST", `${constants.path.API_BASE_URL}v1/managerecording/${itemRec?._id}/upload`,true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`)
      xhr.send(data);
  });
  }
  useEffect(async () => {
    if(!db) return;
    setOpen((await count() > 0) && (recording.local !== MEDIA.RECORDING));
  },[db, recording]);

  const onCancel = async (status) => { 
    await clear();
    setOpen((await count() > 0) && (recording.local !== MEDIA.RECORDING));
    setResult(0)
    !status && await deleteRecording({ _ids: [itemRec?._id]})
    resetRecording();
  };
  
  const onConfirm = async () => {
    setResult(1);
    const file = await blob();
    let form = new FormData();
    form.append('interview_id', interviewId);
    form.append('duration', await count());
    // important to keep blob last field
    form.append('blob', new File([file], itemRec?.recording_id));
    const promises = [uploadProgress(form)];
    if(checked){
      promises.push(save(file));
    }
    await Promise.all(promises).catch(console.error);
    getInterview( { _id: interviewId } );
    resetRecording();
  };

  return (   
    <Dialog open={open} >
      <DialogTitle>Upload local recording</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The call has been recorded on your device. Do you want to upload it?
          <FormControlLabel control={
            <Checkbox 
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          } label="Save a copy of the recording locally" />
        </DialogContentText>
        {(result > 0) && <Grid container justifyContent="center"><CircularProgressWithLabel variant="determinate" size={100} value={result} /></Grid>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={(result > 0)} color="orange" variant="text">delete</Button>
        <Button onClick={onConfirm} disabled={(result > 0)} autoFocus color="orange" variant="contained">upload</Button>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  token: state.app?.token,
  itemRec: state.managerecordings.item,
  recording: state.janus.recording,
})
const mapDispatchToProps = (dispatch) => ({
  getInterview: (data) => dispatch({ type: types.interview.GET_INTERVIEW, data }),
  deleteRecording: (data) => dispatch({ type: types.recordingsmanage.DELETE_MANAGE_RECORDING, data }),
  resetRecording: () => dispatch({ type: types.recordingsmanage.GET_RECORDING_SUCCESS}),
})
export default connect(mapStateToProps, mapDispatchToProps)(RecordingsDialog);