

const validateName = () => {}
const validateEmail = () => {}
const validateDateTo = () => {}
const validateDateFrom = () => {}
const validateDuration = () => {}




export const validators = {
    "name": validateName,
    "email": validateEmail,
    "dateTo": validateDateTo,
    "dateFrom": validateDateFrom,
    "duration": validateDuration,
};