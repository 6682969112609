import { useEffect, useState } from 'react'
import Dexie from 'dexie';
import { useSelector } from 'react-redux';
import fixWebmDuration from "fix-webm-duration";
import useSaveFile from './useSaveFile';

// const db_name = 'jws_recordings';
const store_name = 'recordings';
// const index_name = 'recording_id';

const useIndexedDb = (interviewId) => {
  const { invokeSaveAsDialog } = useSaveFile();

  // const recording_id = useSelector(state => state.janus.recording_id);
  const interview_id = useSelector(state => state.interview.item._id);

  const [dbName, setDbName] = useState(interviewId || interview_id);
  const [db, setDB] = useState(null);

  // init db for the recording
  useEffect(async () => setDbName(interviewId || interview_id), [interviewId || interview_id]);

  useEffect(async () => {
    if(!dbName){
      return;
    }
    const idb = new Dexie(dbName);
    idb.version(1).stores({[store_name]: `id`})
    idb.open();
    setDB(idb);
    return () => idb.close()
  }, [dbName]);

  const collection = (recording_id) => {
    const coll = db.table(store_name)//.where(index_name);
    return recording_id ? coll.equals(recording_id) : coll;
  }

  const getAllBlobs = async (recording_id = null) => {
    return (await collection(recording_id).toArray()).map(r => r.record);
  }

  const count = async (recording_id = null) => {
    return await collection(recording_id).count();
  }

  const clear = async (recording_id = null) => {
    localStorage.setItem(`jws_recording_id_${dbName}`, null);
    return await collection(recording_id).clear();
  }

  const onDataAvailable = async (event) => {
    await db.table(store_name).add({ 
      id: Date.now(), 
      // recording_id, 
      record: event.data
    });
  }

  const blob = async (recording_id) => {
    const recData = await getAllBlobs(recording_id);
    const file = new Blob(recData, { 'type': 'audio/webm' });
    return file;//await fixWebmDuration(file, (await count()) * 1000);
  }

  const save = async (blob = null) => {
    const record = blob || await blob();
    const filename = getFilename();
    invokeSaveAsDialog(record, filename);
  };

  const getFilename = () => {
    return new Date().toLocaleString("en-ZA");
    // TODO
    // return new Date().toLocaleString("en-ZA") + 
    // '-' + g_organization['description'] + 
    // '-' + (
    //     g_organization['type'] ? 
    //     g_organization['target']['name'] : 
    //     g_organization['guest']['name']
    // );
  }

  return {
    db,
    blob,
    clear,
    count,
    save,
    onDataAvailable
  }
}
export default useIndexedDb;
