import path from 'path-browserify';

import store from '../store';
import constants from "../constants";
import types from "../types";

// FetchWrapper used to standartize requests and responses
export const fetchWrapper = () => {
  const { app } = store.getState();

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
  };

  function request(method) {
    return (url, body) => {
      const requestOptions = {
        method,
        headers: authHeader(url)
      };
      if (body) {
        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.body = JSON.stringify(body);
      }
      return fetch(constants.path.API_BASE_URL + url, requestOptions).then(handleResponse);
      // return fetch(path.join(constants.path.API_BASE_URL, url), requestOptions).then(handleResponse);
    }
  }

  // helper functions
  function authHeader(url) {
    // return auth header with jwt if user is logged in
    const token = app?.token;
    const isLoggedIn = !!token;
    if (isLoggedIn) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {}; // TODO authenticate or logout
    }
  }

  function handleResponse(response) {
    const auth_token = response.headers.get(constants.auth.AUTH_TOKEN_HEADER);
    return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if ([401, 403].includes(response.status) && app?.token) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem(constants.auth.AUTH_TOKEN_LS_KEY);
          store.dispatch({ type: types.app.UPDATE_TOKEN, payload: null });
          store.dispatch({ type: types.app.GET_AUTH_TOKEN_BY_USER });
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      // update store and LS token value
      localStorage.setItem(constants.auth.AUTH_TOKEN_LS_KEY, auth_token);
      store.dispatch({ type: types.app.UPDATE_TOKEN, payload: auth_token });
      return data;
    });
  }
}
