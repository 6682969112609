import { combineReducers } from 'redux'
import app from './app'
import notifications from './notifications'
import setting from './setting'
import todos from './todos'
import media from './media'
import interview from './interview'
import template from './template'
import managerecordings from './managerecordings'
import contacts from './contacts'
import janus  from './janus'

export default combineReducers({
  app,
  notifications,
  todos,
  media,
  interview,
  template,
  setting,
  managerecordings,
  contacts,
  janus,
})