import types from "../types";
import constants from "../constants";

// get initial theme from LS or browser settings
let theme = localStorage.getItem(constants.themes.LS_KEY);
if (theme === null) {
  theme = (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ? constants.themes.DARK
    : constants.themes.LIGHT;
}

const initialStore = {
  carouselImages: [],
  customCarusel: false,
  isAutorecordingLocal: true,
  isAutorecordingCloud: true,
  status: false,
  theme: theme,
  fetchStatus: constants.fetch.FINISHED,
};
const setting = (state = initialStore, action) => {
  switch (action.type) {
    
    case types.settings.FETCH_STATUS:
      return {
        ...state,
        ...action,
      };

    case types.settings.GET_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload.data
      };

    case types.settings.UPDATE_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
      };

    case types.settings.ADD_IMAGES_GENERAL_SETTINGS_CARUSEL_SUCCESS:
      return {
        ...state,
        carouselImages: action.payload.data,
      };

    case  types.settings.CHANGE_THEME:
      localStorage.setItem(constants.themes.LS_KEY, action.data);
      return { ...state, theme: action.data, };

    case  types.settings.TOGGLE_THEME:
      const theme = (state.theme === constants.themes.LIGHT) 
        ? constants.themes.DARK
        : constants.themes.LIGHT
      localStorage.setItem(constants.themes.LS_KEY, theme);
      return { ...state, theme: theme, };

    default:
      return {
        ...state,
      };
  }
};

export default setting;
