import { experimental_sx as sx } from '@mui/material/styles';

export default (theme) => ({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: sx({
          color: "primary.text",
        })
      }
    },
  },
});