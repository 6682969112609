/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import useForm from "../../../hooks/useForm";
import useNavigation from "../../../hooks/useNavigation";
import types from "../../../types";
import ListFile from "./ListFile";
import { validators } from "./validators";

const SendFormToEmail = ({ sendToEmail, selectedItems }) => {
  const { navigate, setAskBeforeNavigate } = useNavigation(); 
  const initState = {
    email: "",
    object: "",
    body: "",
    file_id: []
  }
  const onSubmitForm = () => sendToEmail({ _id: selectedItems })
  const {
    handleChange,
    handleStateChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    isChanged,
  } = useForm({initState, validators, callback: onSubmitForm })
  const onPageUrl = (url) => navigate(url) 
  useEffect(() => setAskBeforeNavigate(isChanged), [isChanged]);
  useEffect(() => { selectedItems && handleStateChange({ file_id: selectedItems}) }, [selectedItems])
  return (
    <>
        <Grid container mt={5} sx={{ bgcolor: "primary.backgroundAll" }} item xs={12}>
          <Grid container item xs={12}>
            <Grid container justifyContent='center' item xs={12}>
              <Typography variant="manage">
                E-MAIL A RECORDING
              </Typography>
            </Grid>
          </Grid>
          <Grid component="form" mt={5} justifyContent='center' container item xs={12} noValidate autoComplete="off">
              <Grid container justifyContent='center' item xs={7} >
                <TextField
                  label="To"
                  name="email"
                  helperText={errors.email}
                  error={errors.email ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state.email}
                  InputProps={{
                    startAdornment: " ",
                    placeholder: "Recipient’s e-mail adress",
                  }}
                />
              </Grid>
              <Grid container justifyContent='center' item xs={7}>
                <TextField
                   label="Object"
                   name="object"
                   helperText={errors.object}
                   error={errors.object ? true : false}
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={state.object}
                   InputProps={{
                     startAdornment: " ",
                     placeholder: "E-mail object",
                   }}
                />
              </Grid>
              <Grid container justifyContent='center' item xs={7}>
                <TextField
                  label="Body"
                  name="body"
                  multiline
                  minRows={5}
                  helperText={errors.body}
                  error={errors.body ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state.body}
                  InputProps={{
                    startAdornment: " ",
                    placeholder: "E-mail body",
                  }}
                />
              </Grid>
              <Grid container justifyContent="center" item xs={12}>
                  <Grid item xs={6}>
                    <ListFile carouselImages={selectedItems} />
                  </Grid>
              </Grid>
              <Grid spacing={4} container justifyContent='center' item xs={12}>
                <Grid item xs={2}>
                    <Button onClick={() => onPageUrl(-1)} color="orange" variant="outlined">
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => handleSubmit()} color="orange" variant="contained">
                        SEND
                    </Button>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => ({
  selectedItems: state.managerecordings.selectedItems,
});
export default connect(mapStateToProps, mapDispatchToProps)(SendFormToEmail);
