export default (theme) => ({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper:{
          backgroundColor: `${theme.palette.dialog.main}`,
          color: `${theme.palette.dialog.contrastText}`,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: `${theme.palette.dialog.contrastText}`,
        },
      },
    },
  },
});
