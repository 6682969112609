import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./I18n";
import "fontsource-roboto";
import { Provider, connect } from "react-redux";
import store from "./store";
import Loader from "./js/components/Loader";
import { SnackbarProvider } from 'notistack';
import types from "./types";
import themes from "./themes";
import constants from "./constants";

const Root = connect(

  (state) => ({
    colorTheme: state.setting.theme,
  }), 

  (dispatch) => ({
    changeTheme: (data) => dispatch({ type: types.settings.CHANGE_THEME, data }),
  })

)(({colorTheme, changeTheme, ...props}) => {

  const handleChangeColorScheme = (event) => {
    changeTheme(
      (event.matches)
        ? constants.themes.DARK
        : constants.themes.LIGHT
    );
  }

  useEffect(() => {

    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleChangeColorScheme);

    // cleanup this component
    return () => {
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleChangeColorScheme);
    };

  }, []);

  return (
    <ThemeProvider theme={themes(colorTheme || constants.themes.LIGHT)}>
      <CssBaseline />
      <SnackbarProvider>
        <Loader />
      </SnackbarProvider>
    </ThemeProvider>
  );
});

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
