import React, { useEffect, useState } from "react";
import { Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GlobalForm from "./GlobalForm";
import { connect } from "react-redux";
// import FtpSettings from "./FtpSettings";
import types from "../../../types";


const SettingsTabs = ({theme, getGlobalSetttings, items, carouselImages, ...props}) => {
  const [value, setValue] = useState("1");
  useEffect(() => {
    getGlobalSetttings();
  }, [items])
    
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
        <Grid sx={{ justifyContent: "center" }} container item xs={12}>
        <TabList 
          sx={{ width: "100%" }} 
          onChange={handleChange}
        >
          <Tab sx={{ maxWidth: { xs: "1200px" }, width: "100%" }} label="Global settings" value="1" />
          {/* <Tab sx={{ maxWidth: { xs: "200px", sm: "300px", md: "400px", lg: "560px" }, width: "100%" }} label="FTP Parameters" value="2" /> */}
        </TabList>
      </Grid>
        <TabPanel sx={{ width: "100%" }} value="1">
          <GlobalForm />
        </TabPanel>
        {/* <TabPanel sx={{ width: "100%" }} value="2">
          <FtpSettings />
        </TabPanel> */}
    </TabContext>
  );
};
const mapStateToProps = (state) => ({
  carouselImages: state.setting?.carouselImages || [],
})
const mapDispatchToProps = (dispatch) => ({
    getGlobalSetttings: () => dispatch({ type: types.settings.GET_GENERAL_SETTINGS }),
  });
export default connect(mapStateToProps, mapDispatchToProps)(SettingsTabs);
