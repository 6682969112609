import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import AddressBook from "../../components/AddressBook";
import AddressBookList from "../../components/AddressBook/List";
import AddressBookForm from "../../components/AddressBook/Form";

import ManageInterviews from "../../components/ManageInterviews";
import ManageInterviewsList from "../../components/ManageInterviews/List";

import OnlineInterviewing from "../../components/OnlineInterviewing";

import Settings from "../../components/Settings";
import { Grid } from "@mui/material";
import NotFound from "../../components/NotFound";
import Audiomass from "../../components/Audiomass";
import { ROUTES } from "../../../constants";

import SettingsTabs from "../../components/Settings/SettingsTabs";
import SettingsLoadImages from "../../components/Settings/LoadImages";
import AudioSettings from "../../components/AudioSettings";
import CreateInterview from "../../components/CreateInterview"; 
import Form from "../../components/CreateInterview/Form";
import SuccessSend from "../../components/CreateInterview/successSend";

import ManageRecording from "../../components/ManageRecording";
import ManageRecordingList from "../../components/ManageRecording/List";
import SendFormToFTP from "../../components/ManageRecording/SendFormToFTP";
import SendFormToEmail from "../../components/ManageRecording/SendFormToEmail";
import PostProductionForm from "../../components/ManageRecording/PostProdaction";
import ListSettings from "../../components/AudioSettings/ListSettings";

const Routers = ({isAudioMass, ...props}) => {
  const location = useLocation();
  return (
    <Grid container sx={{
        display: "block",
        maxWidth: `${isAudioMass || location.pathname.includes('edit_recordings') ? "100%" : "1110px"}`,
      }}>
      <Routes>
      <Route exact path={ROUTES.HOME} element={<ManageInterviews />} >
          <Route path='' element={ <ManageInterviewsList /> } />
        </Route>
        <Route path={ROUTES.INTERVIEW} element={<CreateInterview />} >
          <Route path='' element={ <OnlineInterviewing /> } />
          <Route path={ROUTES.INTERVIEW_EDIT} element={ <OnlineInterviewing /> } />
          <Route path={ROUTES.INTERVIEW_INVITE} element={ <Form /> } />
          <Route path={ROUTES.INTERVIEW_SUCCESS_SEND} element={ <SuccessSend /> } />
        </Route>
        <Route path={ROUTES.MANAGERECORDING} element={<ManageRecording />} >
            <Route path={ROUTES.MANAGERECORDING_INTERVIEW} element={ <ManageRecordingList /> } />
            <Route path={ROUTES.MANAGERECORDING_SEND_TO_FTP} element={ <SendFormToFTP /> } />
            <Route path={ROUTES.MANAGERECORDING_SEND_TO_EMAIL} element={ <SendFormToEmail /> } />
            <Route path={ROUTES.MANAGERECORDING_POSTPRODACTION} element={ <PostProductionForm /> } />
        </Route>
        <Route path={ROUTES.ADRESSBOOK} element={<AddressBook />} >
          <Route path='' element={ <AddressBookList /> } />
          <Route path={ROUTES.ADRESSBOOK_NEW} element={ <AddressBookForm /> } />
          <Route path={ROUTES.ADRESSBOOK_NEW_OUTSIDE} element={ <AddressBookForm /> } />
          <Route path={ROUTES.ADRESSBOOK_EDIT} element={ <AddressBookForm /> } />
        </Route>
        <Route path={ROUTES.SETTINGS}element={<Settings />}>
          <Route path='' element={ <SettingsTabs /> } />
        </Route>
        <Route path={ROUTES.AUDIO_SETTING_INTERVIEW} element={<AudioSettings />}>
          <Route path='' element={<ListSettings />} />
          <Route path={ROUTES.AUDIO_SETTING_INTERVIEW_ID} element={<ListSettings />} />
        </Route>
        <Route path={ROUTES.UPLOADIMAGE} element={ <SettingsLoadImages /> } />
        <Route 
          path={ROUTES.EDIT_RECORDINGS} 
          element={<Audiomass />} 
        />
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  isAudioMass: state.setting.isOpenAudioMass,
});
const mapDispatchToProps = (dispatch) => ({ });
export default connect(mapStateToProps, mapDispatchToProps)(Routers);
