import { useDispatch, useSelector } from 'react-redux';
import constants from "../constants";
import types from "../types";

const initialStore = {
  items: [],
  isSelectedInterview: 0,
  dataRecordings: [],
  item: {},
  isSendInvite: false,
  status: false,
  request: false,
  isCall: false,
  keyForLink: '',
  takeCallVar: false,
  aboutCaller: [
    {
      name: "John",
    },
  ],
  isGuestConnect: false,
  systemStatus: false,
  isGuestOnline: false,
  fetchStatus: constants.fetch.FINISHED,
  isAddMarkers: false,
  markers: {
    fetchStatus: constants.fetch.FINISHED,
  }
};

const initInterview = {
  isStarted: false,
};

const interview = (state = {...initialStore, ...initInterview}, action) => {
  switch (action.type) {

    case types.interview.SET_IS_STARTED:
      return {
        ...state,
        seconds: action.payload ? state.seconds : 0,
        isStarted: action.payload,
      };

    case types.interview.FETCH_STATUS:
      return {
        ...state,
        ...action,
      };

    case types.interview.MARKERS_FETCH_STATUS:
      return {
        ...state,
        markers: {
          ...state.markers,
          fetchStatus: action.fetchStatus,
        }
      };

    case types.interview.GET_INTERVIEWS_SUCCESS:
      return {
        ...state,
        item: [],
        isSendInvite: false,
        items: action.payload.data ? action.payload.data : [],
        status: action.payload.data.length > 0,
        request: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    case types.interview.SEND_INVITE_INTERVIEW_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        isSendInvite: !!action.payload.data._id 
      };
    case types.interview.GET_INTERVIEW_SUCCESS:
      return {
        ...state,
        isAddMarkers: false,
        ...initInterview,
        isSendInvite: false,
        item: action.payload.data,
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    case types.interview.GET_KEY_FOR_LINK_SUCCESS:
      return {
        ...state,
        keyForLink: action.payload.key
        };
    case types.interview.RENAME_INTERVIEW_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        isSendInvite: false,
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    case types.interview.DELETE_INTERVIEW_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        isSendInvite: false,
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    case types.interview.CREATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        item: action?.payload.data || {},
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    case types.interview.UPDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        isSendInvite: false,
        status: action.payload.statusCode > 200 && action.payload.statusCode < 400,
      };
    case types.interview.UPDATE_MARKERS_INTERVIEW_SUCCESS: {
        return {
          ...state,
          item: {
            ...state.item,
            markers: action?.payload.data,
          },
          isAddMarkers: action?.payload?.data?.length > 0,
        };
    }
    case types.interview.DEFAULT_INTERVIEW_VARIABLES: {
      return {
        ...state,
        isSendInvite: false,
        status: false,
      };
    }
    case types.interview.STATUS_MESSAGE_SUCCESS: {
      return {
        ...state,
        statusMessage: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default interview;
