/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { FETCH, ROUTES } from "../../../constants";
import useNavigation from "../../../hooks/useNavigation";
import types from "../../../types"

const getInterviewTime = (date) => {
  return (new Intl.DateTimeFormat("en-US", {
    weekday: "long",month: "long",day: "numeric",
    year: "numeric",
  }).format(date) + " - " + new Intl.DateTimeFormat("en-US", {
    hour: "numeric",minute: "numeric",second: "numeric",hour12: false,
  }).format(date));
};

const TitleForm = ({ item, items, status, fetchFinished, createInterview, renameInterview, setIsDisabled, ...props }) => {
  const { navigate } = useNavigation()
  const { interviewId } = useParams()

  const [name, setName] = useState('My new Interview_' + parseInt(items?.length + 1) );
  const [createAt, setCreateAt] = useState(getInterviewTime(new Date()));
  const [isBlur, setIsBlur] = useState(!interviewId);

  useEffect(()=> setIsDisabled(isBlur), [isBlur]);

  const submitName = (name) => {
    if(interviewId) return renameInterview({ _id: interviewId, name })
    createInterview({ name });
  }

  const onChangeInput = (e) => setName(e.target.value)

  const onBlurInput = (e) => {
    setIsBlur(false);
    submitName(e.target.value)
  };

  const onKeyDownEnter = (element) => {
    if (element.keyCode === 13) {
      submitName(element.target.value);
      setIsBlur(false);
    }
  };

  useEffect(()=>{
    setName(item?.name || 'My new Interview_' + parseInt(items?.length + 1));
    setCreateAt( getInterviewTime(item?.createAt ? new Date(item?.createAt) : new Date()) );
    (item && item.name ) && navigate(generatePath(ROUTES.INTERVIEW_EDIT, { interviewId: item._id }))
  }, [item])
  return (
    <Grid container item xs={12} sx={{ 
      maxHeight: 120, 
      minHeight: 120, 
      alignItems: 'center', 
      justifyContent: 'center',
      bgcolor: "primary.backgroundAll",
      borderRadius: "8px",
    }}>
      <Grid container height="0px" item xs={7.4}>
        {isBlur ? (
          <TextField
            label="Name of interview"
            name="name"
            helperText={false}
            autoFocus={isBlur}
            onChange={onChangeInput}
            onBlur={onBlurInput}
            value={name}
            onKeyDown={(e) => onKeyDownEnter(e)}
            inputProps={{ maxLength: 120 }}
            InputProps={{
              min: 10,
              startAdornment: " ",
              placeholder: "My name interview",
            }}
          />
        ) : (
          <Typography sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }} onDoubleClick={() => setIsBlur(true)} variant="biggest">
            {name}
          </Typography>
        )}
      </Grid>
      <Grid mt={1} container item xs={7.1}>
        <Typography variant="smaller">{createAt}</Typography>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  item: state.interview.item,
  items: state.interview.items,
  fetchFinished: state.interview.fetchStatus === FETCH.FINISHED,
})
const mapDispatchToProps = (dispatch) => ({
  createInterview: (data) => dispatch({ type: types.interview.CREATE_INTERVIEW, data }),
  renameInterview: (data) => dispatch({ type: types.interview.RENAME_INTERVIEW, data })
})
export default connect(mapStateToProps, mapDispatchToProps)(TitleForm);
