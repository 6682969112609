import { Grid, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

const NameCompany = ({ profile, ...props}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if(profile.memberships?.length === 0){
      return;
    }
    const memberships = profile?.memberships || [];
    const organization = memberships.find( (o) => o.organization._id === profile.home.organization_id );
    setName(organization?.organization?.details?.name || '');
  },[profile.memberships?.length > 0]);

  return (
      <Grid {...props} >
        <Typography sx={{ fontSize: { xs: "12px",sm: "14px", md: "16px" }}} >
          {name}
        </Typography>
        {/* <Select disableUnderline fullWidth defaultValue={0} variant='filled'
          sx={{ bgcolor: "primary.backgroundAll" }}
          inputProps={{ sx: { p: 0, bgcolor: "primary.backgroundAll" }}}>
          {profile && profile.memberships?.map((member, int) => (
              <MenuItem value={int} key={int}>{member.organization.details.name}</MenuItem>
          ))}
          </Select> */}
      </Grid>
  )
}
export default connect(
    (state) => ({ profile: state.app.profile }),
    (dispatch) => ({})
  )(NameCompany)