/* eslint-disable react-hooks/rules-of-hooks */
import { call, put } from "redux-saga/effects";
import { fetchWrapper } from "../fetchings";
import constants from "../../constants";
import types from '../../types';

export function* getAuthToken() {
  const token = localStorage.getItem(constants.auth.AUTH_TOKEN_LS_KEY);  
  if(!token){
    // try to login if no token
    yield put({ type: types.app.GET_AUTH_TOKEN_BY_USER });
  }else{
    // try to validate token
    yield put({ type: types.app.UPDATE_TOKEN, payload: token });
    yield put({ type: types.app.VALIDATE_AUTH_TOKEN });
  }
}

export function* validateAuthToken() {
  const fetchFn = fetchWrapper();
  try {
    yield call(fetchFn.get, 'v1/token');
    yield put({ type: types.app.UPDATE_LOADING, payload: false });
  } catch (e) {
    yield put({ type: types.app.GET_AUTH_TOKEN_BY_USER });
  }
}

export function* getAuthTokenByUser() {
  const fetchFn = fetchWrapper();
  const user_id = localStorage.getItem("user_id") || sessionStorage.getItem("user_id");
  const organization_id = localStorage.getItem("organization_id") || sessionStorage.getItem("organization_id");
  const session_id = localStorage.getItem("session_id") || sessionStorage.getItem("session_id");
  if(!user_id || !organization_id || !session_id){
    // auth error, creds error
    window.location.href = constants.path.SIGN_IN_URL;
  }

  try{
    const data = { user_id, organization_id, session_id };
    const response = yield call(fetchFn.post, 'v1/auth/login', data);
    const token = response?.access_token;
    // update store and LS token value
    localStorage.setItem(constants.auth.AUTH_TOKEN_LS_KEY, token);
    yield put({ type: types.app.UPDATE_TOKEN, payload: token });
    yield put({ type: types.app.UPDATE_LOADING, payload: false });
  } catch(e) {
    // auth error, relogin required
    window.location.href = constants.path.SIGN_IN_URL;
  }
}

export function* getUserProfileData() {
  const response = yield call(fetchWrapper().get, 'v1/profile');
  yield put({ type: types.app.UPDATE_USER_PROFILE_DATA, payload: response });
  if(response?.settings?.theme) {
    yield put({ type: types.settings.CHANGE_THEME, data: response?.settings?.theme });
  }
}
export function* logout() {
  const response = yield call(fetchWrapper().post, 'v1/auth/logout');
  if(response.length > 0) {
      localStorage.removeItem("organization_id");
      localStorage.removeItem("session_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("x-access-token");
      
      sessionStorage.removeItem("organization_id");
      sessionStorage.removeItem("session_id");
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("x-access-token");
      window.location.href = constants.path.SIGN_IN_URL;
  }
}