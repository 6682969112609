import rotateReducer from "../reducers";
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import saga from "../saga";
import { MEDIA } from "../constants";
import types from "../types";

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({ 
  reducer: rotateReducer,
  middleware: [ sagaMiddleware ],
  devTools: process.env.NODE_ENV !== 'production',
})
sagaMiddleware.run(saga);

// get IO audio devices
navigator
  .mediaDevices
  .enumerateDevices()
  .then((devices) => {
    const inputId = localStorage.getItem(MEDIA.LS_MEDIA_INPUT);
    const outputId = localStorage.getItem(MEDIA.LS_MEDIA_OUTPUT);
    store.dispatch({type: types.media.SET_DEVICES, payload: {
      inputs: devices?.filter((device) => device.kind === MEDIA.AUDIOINPUT),
      outputs: devices?.filter((device) => device.kind === MEDIA.AUDIOOUTPUT),
      inputId: inputId || 'default',
      outputId: outputId || 'default',
    }});
  });

export default store;
