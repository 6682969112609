import { Link, Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Guest from "../../../images/guest.png";
import { ReactComponent as Outlook } from "../../../images/outlook.svg";
import { ReactComponent as BannerForm } from "../../../images/formEmail.svg";
import { ReactComponent as Earbuds } from "../../../images/earbuds.svg";
import { ReactComponent as Headphones } from "../../../images/headphone.svg";
import { ReactComponent as IQOYA } from "../../../images/IQOYA.svg";
import { ReactComponent as Calendar } from "../../../images/calendar.svg";
import HelpTip from '../../containers/HelpTip';
import { ArrowDropDown } from '@mui/icons-material';
import constants from '../../../constants';
import { connect } from 'react-redux';

const TemplateAccordion = ({ template, languages, openDuration, triggerDuration, state, handleStateChange, profile, ...props }) => {
    const [templateBody, setTemplateBody] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState(template.find((tmpl) => tmpl.language === state.languageTemplate))
    useEffect(() => {
        if (selectedTemplate) {
          setTemplateBodyFunc()
        }
      }, [state.name, selectedTemplate]);
      useEffect(() => {
        setSelectedTemplate(template.find((tmpl) => tmpl.language === state.languageTemplate))
      }, [state.languageTemplate])
    useEffect(() => {setTemplateBodyFunc()}, [state.dateFrom])
    useEffect(() => { handleStateChange({ bodyTemplate: templateBody })}, [templateBody])
    const generateText = (isEdit = false, text) => {
        var optionsDate = { year: "numeric", month: "long", day: "numeric" };
        var optionsTime = { hour: "numeric", minute: "numeric" };
        let date, time;
        text = text.replace("%3", profile?.memberships?.length && profile?.memberships[0].organization?.details?.name);
        date = state.dateFrom ? new Intl.DateTimeFormat("en-US", optionsDate).format(new Date(state.dateFrom)) : "";
        time = state.dateFrom ? new Intl.DateTimeFormat("en-US", optionsTime).format(new Date(state.dateFrom)) : "";
        if (isEdit) {
          text = text.replace(/%1/gi, date);
          text = text.replace(/%2/gi, time);
          return text;
        }
        return (
          <Typography variant="span">
            {state.dateFrom ? date : "____"} at {state.dateFrom ? time : "____"}
          </Typography>
        );
      };
    const setTemplateBodyFunc = useCallback(() => {
      let tmpl =  selectedTemplate.top + " " + (state.name ? state.name + "\n" : "<Name>\n") 
      + generateText(true, selectedTemplate.body) 
      setTemplateBody(tmpl);
    },[state.dateFrom])
    const onChangeBody = (element) => {
      setTemplateBody(element.target.value);
      handleStateChange({ bodyTemplate: element.target.value })
    }
    const onChangeInput = (element) => {
      handleStateChange({ languageTemplate: element.target.value })
      setTemplateBodyFunc();
    }
    return (
        <Accordion sx={{ bgcolor: "primary.backgroundAll"}} disableGutters
          elevation={0}
          expanded={openDuration === "panel3"}
          onChange={() => triggerDuration("panel3")}>
        <AccordionSummary sx={{ p:0}} expandIcon={<ArrowDropDown sx={{ fontSize: '32px'}} color="info" />}>
          <Typography variant="middleText" color='text.primary'>
            Edit the invitation that will be sent to your guest
            <HelpTip children="Edit the invitation that will be sent to your guest" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Grid sx={{ alignItems: "baseline" }} container item xs={12}>
            <Grid item xs={12}>
              <Typography>Choose the language for the invitation</Typography>
            </Grid>
            <Grid container sx={{ alignItems: "center" }} item xs={12}>
              <Grid mt={2} item sx={{ marginRight: "30px" }} xs={5.5}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "primary.text" }}>Language</InputLabel>
                  <Select
                    value={state.languageTemplate}
                    name="language"
                    defaultValue={0}
                    displayEmpty={false}
                    label="Language"
                    onChange={onChangeInput}>
                    {languages && languages.map((item, int) => (
                        <MenuItem key={int} value={item.code}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <Button
                  disabled={false}
                  onClick={() => handleStateChange({ languageTemplate: 'en' })}
                  variant='cBlue'
                  sx={{maxWidth: "238px",}}>
                  <Typography variant='modalSubText'>
                    Set as default language
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3.75 }} container item xs={12}>
            <Grid container item justifyContent='center' xs={12}
              sx={{
                direction: `${selectedTemplate.direction}`,
                borderImageSlice: 1,
                border: "solid 2px transparent",
                backgroundImage:"linear-gradient(#fff, #fff),linear-gradient(to bottom, #6FB9D9, #6FB9D900)",
                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
              }}>
              <Grid item xs={12} position="relative">
                <BannerForm width="100%" height='96%' />
              </Grid>
              <Grid position="absolute" justifyContent='center' container item xs={12}>
                <Grid mt={{ xs: 2, lg: 3 }} item xs={8}>
                  <Typography
                    sx={{
                      whiteSpace: "pre-wrap",
                      "& fieldset": {
                        border: 0,
                      },
                      "&& .Mui-focused": {
                        border: 0,
                        "& fieldset": {
                          border: 0,
                        },
                      }
                    }}
                    multiline
                    inputProps={{
                      sx: { color: "primary.textWhite",
                      fontSize: { sm: "12px",md: "15px", lg: "18px" }}
                    }}
                    InputProps={{
                      sx: { p: 0, m: 0},
                      variant: "standard",
                    }}
                    component={TextField}
                    onChange={onChangeBody}
                    value={templateBody}>
                    {state?.name?.length > 0 ? state.name : "____"}
                  </Typography>
                </Grid>
                <Grid container alignItems='center' item xs={12}>
                  <Grid container item xs={9} sx={{
                    height: '36px',
                    bgcolor: "background.paper",
                    borderRadius: `${
                      selectedTemplate.direction === constants.template.DIRECTION_RTL
                        ? "19px 0 0 19px" : "0 19px 19px 0"}`, mt: 1.5 }}>
                  <Grid container item xs={2} justifyContent="center" alignItems="center">
                  <Typography color='secondary' variant="tamplateText">{selectedTemplate.add}</Typography>
                  </Grid>
                  <Grid alignItems="center" container item xs={4.5}>
                    <Calendar />
                    <Link color='secondary' variant="tamplateText" sx={{ ml: 1 }} href="#">Google Calendar</Link>
                  </Grid>
                  <Grid alignItems="center" container item xs={5.5}>
                    <Outlook />
                    <Link color='secondary' variant="tamplateText" sx={{ ml: 1 }} href="#">iCal/Outlook Calendar</Link>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid mt={1} item xs={8} >
                  <Typography variant="tamplateText" sx={{ color: "primary.textWhite" }}>{selectedTemplate.press}</Typography>
                </Grid>
                <Grid container justifyContent='center' item xs={12}>
                  <Grid container justifyContent='center' mt={{ xs: 1, lg: 2 }} item xs={3}>
                    <Button color='orange' sx={{ width: "auto"}} variant="contained">
                      <Typography variant="tamplateText">{selectedTemplate.button}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent='center' item xs={12} 
                  sx={{direction: `${selectedTemplate.direction}`}}>
              <Grid mt={{ xs: 1, lg: 2 }} item xs={8}>
                <Typography color='primary.textNoChange' variant="middleText">{selectedTemplate.browser}</Typography>
              </Grid>
              <Grid item xs={8} >
                <Link color="secondary">&lt;guest-link-generated-by-IQOYA-CONNECT&gt;</Link>  
              </Grid>
              <Grid mt={1} mb={5} container item xs={8} >
                <Grid mr={1} container alignItems="center" item xs={8}>
                  <Typography color='primary.textNoChange' variant="middleText">{selectedTemplate.headset}</Typography>
                </Grid>
                <Grid container spacing={4} item xs={3}>
                  <Grid item xs={6}><Headphones sx={{ height: "20px"}} /></Grid>
                  <Grid item xs={6}><Earbuds /></Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: "start", alignItems: "center"}} item xs={12}>
                <Grid container alignItems="center" justifyContent="center" item xs={8}
                  sx={{ borderRadius: `${ selectedTemplate.direction === constants.template.DIRECTION_RTL
                        ? "19px 0 0 19px" : "0 19px 19px 0"}`,
                    backgroundImage: "linear-gradient(177.99deg, #2F6A99 38.66%, rgba(47, 106, 153, 0) 217.52%);",
                    }}>
                  <Typography pr={1} sx={{ color: "primary.textWhite" }} variant="tamplateText">{selectedTemplate.copy1}</Typography>
                  <IQOYA />
                  <img src={Guest} alt="" />
                  <Typography pl={1} sx={{ color: "primary.textWhite" }} variant="tamplateText">{selectedTemplate.copy2}</Typography>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
            <Grid container justifyContent="end" mt={2} item xs={12}>
              <Button
                onClick={setTemplateBodyFunc}
                variant='cBlue'
                sx={{ maxWidth: "238px"}}>
                <Typography variant="modalSubText">
                  Set as default template
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
}
const mapStateToProps = (state) => {
    return {
      template: state.template.template,
      languages: state.template.language,
      profile: state.app.profile
    };
  };
export default connect(mapStateToProps)(TemplateAccordion);