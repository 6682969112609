import { experimental_sx as sx } from '@mui/material/styles';

export default (theme) => ({
  components: {
    MuiButton: {
      styleOverrides: {
        root: sx({
          // color: "primary.hoverText",
          // bgcolor: "primary.button",
          // // maxWidth: 130,
          minHeight: 40,
          maxHeight: 42,
          width: "100%",
          borderRadius: 1,
          "&:hover": {
          //   color: "primary.hoverText",
          //   bgcolor: "primary.button",
          },
        })
      },
      variants: [
        { // contrained blue
          props: { variant: 'cBlue' },
          style: {
            backgroundColor: theme.palette.blue.main,
            color: theme.palette.blue.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.blue.dark,
            },
          },
        },
      ],
    },
  },
});