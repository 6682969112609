import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import types from '../types';

const useNavigation = ( beforeNavigate = () => {} ) => {
  const dispatch = useDispatch();
  const askBeforeNavigate = useSelector(store => store.app.askBeforeNavigate || false);

  const history = useNavigate();
  const location = useLocation();

  const doNavigate = (href) => {
    beforeNavigate();
    history(href);
  }

  const navigate = (href) => {
    // event.preventDefault();
    (askBeforeNavigate && location.pathname !== href)
      ? dispatch({type: types.app.SHOW_CONFIRMATION_DIALOG, payload: {
          isShowing: true,
          onConfirm: () => doNavigate( href ),
        }})
      : doNavigate( href );
  };

  return { 
    navigate,
    setAskBeforeNavigate: ( value ) => dispatch({type: types.app.ASK_BEFORE_NAVIGATE, payload: value}),
  };
};

export default useNavigation;

