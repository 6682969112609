import { createTheme } from "@mui/material";

let palette = createTheme({
  mode: 'light',
  palette: {
    background: {
      default: "#F0F1F4",
    },
    info: {
      main: "#F49744",
    },
    noRows: {
      main: '#F49744',
      contrastText: '#F49744',
    },
    text: {
      primary: '#253D5A',
      secondary: '#F49744',
      main: "#253D5A",
    },
    openBlock: {
      main: "#253D5A",
      hover: "#253D5A",
      text: "#F0F1F4",
    },
    action: {
      active: "#253D5A",
    },
    blue: {
      main: "#2F6A99",
      dark: "#224d79",
      light: "#377aab",
      contrastText: '#fff',
    },
    orange:{
      main: "#F49744",
      contrastText: '#fff',
    },
    white:{
      main: "#ffffff"
    },
    dialog:{
      main: "#ffffff",
      contrastText: '#253D5A',
    },
    secondary: {
      main: "#2F6A99"
    },
    call: {
      main: '#67D3B9',
      dark: '#0bbf9e',
      contrastText: '#fff',
    },
    hangup: {
      main: '#EA4D6F',
      dark: '#e53858',
      contrastText: '#fff',
    },
    primary: {
      main: "#2F6A99",
      contrastText: "#fff",
      background: "#2F6A99",
      disabled: "#253D5A73",
      disabledBg: "#00000012",
      disabledText: "#0000004f",
      recordbutton: "#EA4D6F",
      linkText: "#6FB9D9",
      textGris: "#435561",
      buttonOrange: "#F49744",
      buttonWhite: "#F0F1F4",
      buttonBackground: "#F0F1F4",
      callButton: "#67D3B9",
      manageButton: "#2F6A99",
      soundVisual: "#C4C4C4",
      soundVisualActive: "#1EDD5F",
      redText: "#EA4D6F",
      callStopButton: "#EA4D6F",
      main2: "#F0F1F4",
      selected: "rgba(25, 118, 210, 0.08)",
      text: "#253D5A",
      textNoChange: "#253D5A",
      textWhite: "#F0F1F4",
      textCancelButton: "#253D5A6b",
      textFAB: "#253D5A",
      hoverText: "#F0F1F4",
      text2: "#2F6A99",
      backgroundLight: "#2F6A99",
      colorBorderChip: "#253d5a91",
      blackText: "#435561",
      textHeader: "#F49744",
      modalText: "#1976D2",
      modalText2: "#90CAF9",
      selectedModal: "#F49744",
      white: "#ffffff",
      backgroundAll: "#ffffff",
      backgroundAllNumber: "#F0F1F4",
      checkbox: "#253D5A",
      button: "#F49744",
      closeBtn: "#E5E5E5",
      backgroundGrey: "#E5E5E5",
      borderInput: "#435561",
      iconbutton: "#CCDDFF",
      colorText: "#979797",
      colorTextBleind: "rgba(0, 0, 0, 0.87)",
      textGray: "#727971",
      grayForPagination: "#bdbaba",
      hover: {
        "&:hover": {
          backgroundColor: "#f49744",
        },
      },
    },
  },
});

export default palette;
