import { experimental_sx as sx } from "@mui/material/styles";

export default (theme) => ({
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          "@keyframes ripple": {
            "0%": {
              transform: "scale(.8)",
              opacity: 1,
            },
            "100%": {
              transform: "scale(2.4)",
              opacity: 0,
            },
          },
          "& .MuiBadge-badge": {
            width: "16px",
            borderRadius: "50%",
            height: "16px",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              border: "1px solid currentColor",
              content: '""',
            },
          },
        },
      },
    },
  },
});
