import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = (props) => {
    return (
        <Helmet>
            <title>{ props.title }</title>
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
            />
        </Helmet>
    )
}
export default SEO