import { React } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import IQOYA from "../../../images/Cloud.svg";
import { useMediaQuery, Backdrop } from "@mui/material";
import {
  Button,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  MenuItem,
} from "@mui/material";

// import NavHeaderEx from "./NavHeaderEx";
import MenuItems from "./MenuItems";
import { useStyles } from "../Header/config";
import constants from "../../../constants";
import { connect } from "react-redux";
import types from "../../../types";
import { ArrowBackIos } from "@mui/icons-material";

const drawerWidth = 256;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.easing,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.easing,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("xs")]: {
    width: 0,
  },
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "wrap",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavMenu = (props) => {
  const classes = useStyles(props);

  const {
    // openForm,
    // setOpenModalAlert,
    // setHrefLink,
    isMenuOpen,
    toggleMenuOpen,
  } = props;
  const heightIs = useMediaQuery("(max-height:376px)");
  const matches = useMediaQuery("(max-width:600px)");
  
  return (
    <>
      <Drawer
        sx={{
          position: `${matches ? "absolute" : "relative"}`,
          overflowY: `${heightIs ? "scroll" : "auto"}`,
          "& .MuiDrawer-paper": {
            marginTop: {
              xs: "3.5em",
              sm: "4em",
              md: "4em",
            },
            bgcolor: "primary.backgroundAll",
          },
        }}
        className="active"
        variant="permanent"
        open={isMenuOpen}
        onClose={() => toggleMenuOpen(false)}
      >
        {/* <List>
          <NavHeaderEx heightIs={heightIs} collapsed={!isMenuOpen} />
        </List> */}
        <Divider sx={{ mt: 0, mb: 0 }} />
        <List>
          <Button color="white" 
            sx={{
              padding: "6px 0px",
              maxHeight: "48px",
              width: "100%",
            }}
            href={`${constants.path.IQOYA_CONNECT_URL}`}
          >
            <ListItemIcon sx={{ justifyContent: "center", "& img": { maxWidth: 44} }}>
              <img src={IQOYA} className={classes.iconButton} alt="Home" />
            </ListItemIcon>
            <ListItemText
              className={!isMenuOpen ? classes.hidden : ""}
              disableTypography
              primary={
                <Typography type="body2" sx={{ color: "primary.text" }} className={classes.textRight}>
                  IQOYA CONNECT
                </Typography>
              }
            />
          </Button>
        </List>
        <Divider sx={{ mt: "0px!important", mb: "0px!important" }} />
        <MenuItems heightIs={heightIs}/>
        <MenuItem
          className={classes.menuItems}
          sx={{
            mb: 0,
            position: "absolute",
            bottom: {
              xs: "4em",
            },
            bgcolor: "primary.backgroundAll",
            alignItems: "center",
            display: {
              xs: "none",
              sm: `${heightIs ? "none" : "flex"}`,
            },
          }}
          onClick={() => toggleMenuOpen()}
        >
          <ArrowBackIos
            sx={{
              color: "primary.text",
              width: "20px",
              margin: `${isMenuOpen ? "0 auto" : "auto 0"}`,
              height: "20px",
              transform: `${isMenuOpen ? "rotate(0deg)" : "rotate(180deg)"}`,
            }}
          />
        </MenuItem>
      </Drawer>
      <Backdrop
        sx={{ color: "#fff", zIndex: 1 }}
        open={isMenuOpen && matches}
        onClick={() => toggleMenuOpen(false)}
      ></Backdrop>
    </>
  );
};

const mapStateToProps = (state) => ({
  openForm: state.todos,
  isAudioMass: state.setting.isOpenAudioMass,
  isMenuOpen: state.app.isMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  changeAudiomass: () => dispatch({ type: "CHANGE_AUDIOMASS" }),
  toggleMenuOpen: (toggle) => dispatch({ type: types.app.TOGGLE_MENU_OPEN, toggle: toggle }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
