/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Outlet } from "react-router-dom";
import Page from "../../containers/Page";

const Settings = (props) => {
  return (
    <Page titleText="SETTINGS">
      <Outlet />
    </Page>
  );
};

export default Settings;
