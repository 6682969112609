const validateNormalization = () => {}
const validateMagicClean = () => {}
const validateLoudness = () => {}
const validateObject = () => {}
const validateEmail = (value) => {
    let regex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!regex.test(value)) {
        return "Is not a valid email address";
      }
      if (value.length <= 2) {
        return "Need more two characters";
      }
}
const validateBody = () => {}




export const validators = {
    "email": validateEmail,
    "object": validateObject,
    "body": validateBody,
    "normalization": validateNormalization,
    "magicClean": validateMagicClean,
    "loudnessTarget": validateLoudness,
};