import React, { useEffect, useState } from "react";
import { Avatar, Grid, Button, MenuItem, Select } from "@mui/material";
import { Mic, Headphones } from "@mui/icons-material";
import { connect } from "react-redux";
import StreamLevelBars from "./StreamLevelBars";
import ButtonTestInput from './ButtonTestInput';
import ButtonTestOutput from './ButtonTestOutput';
import { MEDIA } from "../../../constants"
import types from "../../../types";
import useAudio from "../../../hooks/useAudio";

const ListSettings = ({ devices, setDevices, ...props }) => {
    const { inputSource } = useAudio();
    const [outputSource, setOutputSource] = useState(null); 
  
    useEffect(() => { updateList() }, [inputSource])
  
    const updateList = () => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        setDevices({
          inputs: devices?.filter((device) => device.kind === MEDIA.AUDIOINPUT),
          outputs: devices?.filter((device) => device.kind === MEDIA.AUDIOOUTPUT),
        });
      });
    };
  
    const handleChangeInput = (e) => {
      const value = e.target.value;
      setDevices({inputId: value});
      localStorage.setItem(MEDIA.LS_MEDIA_INPUT, value);
    };
  
    const handleChangeOutput = (e) => {
      const value = e.target.value;
      setDevices({outputId: value});
      localStorage.setItem(MEDIA.LS_MEDIA_OUTPUT, value);
    };
    return (
        <Grid container justifyContent="center">
        <Grid container direction="column" sx={{maxWidth: 600, pl:2, pr: 2}}  justifyContent="center">

          <Grid container justifyContent="flex-end">
            <Button variant="cBlue" sx={{maxWidth: 200}} onClick={updateList}>
              refresh device list
            </Button>
          </Grid>

          <Grid container mt={2} flexWrap="nowrap">
            <Grid container item sx={{width: 60}} >
              <Avatar sx={{ width: 56, height: 56, bgcolor: "background.default" }}>
                <Mic color='orange' />
              </Avatar>
            </Grid>

            <Grid item sx={{ flexGrow: 1 }}>
              <Select
                fullWidth
                sx={{maxWidth: 508}}
                value={devices.inputId}
                defaultValue={devices.inputId}
                onChange={handleChangeInput}>
                  {devices?.inputs?.map((device, int) => (
                    <MenuItem key={int} value={device.deviceId}>
                      {device.label}
                    </MenuItem>
                  ))}
              </Select>

              <Grid container mt={1} flexWrap="nowrap" alignItems="center" justifyContent="space-between">
                <ButtonTestInput input={inputSource} />
                <StreamLevelBars stream={inputSource} />
              </Grid>

            </Grid>
          </Grid>

        {devices?.outputs?.length > 0 && 
          <Grid container mt={2} flexWrap="nowrap">
            <Grid container item  sx={{width: 60}} >
              <Avatar sx={{ width: 56, height: 56, bgcolor: "background.default" }}>
                <Headphones color='orange' />
              </Avatar>
            </Grid>

            <Grid item sx={{ flexGrow: 1 }}>
              <Select
                fullWidth
                sx={{maxWidth: 508}}
                defaultValue={devices.outputId}
                value={devices.outputId}
                onChange={handleChangeOutput}>
                {devices?.outputs?.map((device, int) => (
                  <MenuItem key={int} value={device.deviceId}>
                      {device.label}
                  </MenuItem>
                ))}
              </Select>

              <Grid container mt={1} flexWrap="nowrap" alignItems="center" justifyContent="space-between">
                <ButtonTestOutput setOutput={setOutputSource}/>
                <StreamLevelBars stream={outputSource} />
              </Grid>

              </Grid>
          </Grid>
        }
        </Grid>
      </Grid>
    )
}
const mapStateToProps = (state) => ({
    devices: state.media.devices
  })
const mapDispatchToProps = (dispatch) => ({
    setDevices: (data) => dispatch({ type: types.media.SET_DEVICES, payload: data })
})
export default connect(mapStateToProps, mapDispatchToProps)(ListSettings);
