import {
  DeleteOutlineOutlined,
  Edit,
} from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import React from "react";

const FloatActions = ({selected, onDelete, onClose, onAdd, onEdit, ...props}) => {
  const openAction = selected?.length > 0;
  const editAction = selected?.length === 1;
  const deleteAction = selected?.length > 1;

  const handleFab = () => openAction ? onClose() : onAdd();
  
  const handleEdit = () => onEdit();

  const handleDelete = (e) => { 
    e.stopPropagation(); 
    onDelete(); 
  }

  return (
    <SpeedDial
      direction="left"
      ariaLabel={ openAction ? "close" : "add" }
      icon={<SpeedDialIcon />}
      onClick={ handleFab }
      open={ openAction }
    >
      <SpeedDialAction
        icon={<DeleteOutlineOutlined />}
        tooltipTitle="Delete"
        onClick={ handleDelete }
      />
      { !deleteAction && <SpeedDialAction
        icon={<Edit />}
        tooltipTitle="Edit"
        onClick={ handleEdit }
        open={ editAction }
      /> }
    </SpeedDial>
  );
};

export default FloatActions;
