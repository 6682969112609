export default {
  UPDATE_TOKEN: 'app/update_token',
  UPDATE_LOADING: 'app/update_loading',
  GET_AUTH_TOKEN: 'app/get_auth_token',
  VALIDATE_AUTH_TOKEN: 'app/validate_auth_token',
  GET_AUTH_TOKEN_BY_USER: 'app/get_auth_token_by_user',
  GET_USER_PROFILE_DATA: 'app/get_user_profile_data',
  UPDATE_USER_PROFILE_DATA: 'app/update_user_profile_data',
  FETCH_STATUS: 'app/fetch_status',
  TOGGLE_MENU_OPEN: 'app/toggle_menu_open',
  ASK_BEFORE_NAVIGATE: 'app/ask_before_navigate',
  SHOW_CONFIRMATION_DIALOG: 'app/show_confirmation_dialog',
  LOGOUT: 'app/logout',
}