/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Typography,
  Card,
  // Snackbar,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from 'notistack';
import { AddPhotoAlternate, Cancel, FileUploadOutlined } from "@mui/icons-material";
import types from "../../../types";
import { connect } from "react-redux";
import useNavigation from "../../../hooks/useNavigation";
import { FETCH, ROUTES } from "../../../constants";
import Preloader from "../../containers/Preloader";

const LoadImages = ({ carouselImages, updateSettings, fetchStatus, ...props}) => {
  const { navigate } = useNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const inputFile = useRef();

  useEffect(() => {
    carouselImages?.length > 0 && setUploadFiles(carouselImages)
  }, [carouselImages]);
  const onCancel = () => navigate( ROUTES.SETTINGS )

  const saveFiles = (files) => {

    if(uploadFiles.length >= 10) {
      return enqueueSnackbar("You cannot add the more 10 images");
    }
    for(let i = 0; i < files.length; ++i){
      let file = files[i];
      if(file && file.size > 1048576) {
        return enqueueSnackbar("Image must be less than 1 MB");
      }
      if( !["image/png", "image/jpg", "image/jpeg" ].includes(file.type) ){
        return enqueueSnackbar("You can add only png/jpg/jpeg image format");
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setUploadFiles((f) => [ ...f, {
          id: uuidv4(),
          name: file.name,
          img: reader.result,
        }]);
      };
    }
    
  };

  const triggerFile = () => inputFile.current.click();
  const changeInputFile = (e) => saveFiles(e.target.files);
  const deleteImage = (id) =>  (uploadFiles.length > 0) 
      && setUploadFiles( uploadFiles.filter((e) => id !== e.id) );
  
  const submitForm = () => {
    setIsUpdating(true);
    updateSettings({carouselImages: uploadFiles});
  };

  useEffect(() => {
    (isUpdating && (fetchStatus === FETCH.FINISHED)) && navigate( ROUTES.SETTINGS );
  }, [fetchStatus, isUpdating])

  const onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "rgb(239 247 255 / 48%)";
  }

  const onDragLeave = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.currentTarget.style.backgroundColor = "";
  }

  const onDrop = (event) => {
    onDragLeave(event);
    let file = event.dataTransfer.files;
    file && saveFiles(file);
    event.dataTransfer.clearData();
  };

  return (
    <Grid container  direction={"row"} sx={{ 
      bgcolor: "primary.backgroundAll", 
      flexDirection: 'column',
      minHeight: 'calc(100vh - 184px)',
      flex: 1,
    }}>

      <Grid justifyContent="center" mb={2.5} mt={2} container direction="column" rowSpacing={10}>
        <Typography textAlign="center" sx={{ textTransform: "uppercase", color: "orange.main", mb: 2, mt: 2 }} variant="biggest">
          Selection of the carousel images
        </Typography>
        <Typography textAlign="center">
          For best display, the images in the carousel should be 414 x 160 pixels.
        </Typography>
        <Typography textAlign="center">
          You can’t upload more than 10 images and each image must be less then 1MB
        </Typography>
      </Grid>

      {isUpdating 
        ? ( <Grid justifyContent="center" container item xs={12}><Preloader /></Grid> )
        : ( <>
          <Grid container item xs={12} component="form" style={{flexGrow: 1}}>

                <Grid container draggable
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  onClick={triggerFile}
                  sx={{
                    borderStyle: "dashed",
                    borderWidth: 1,
                    borderRadius: "12px",
                    m: 2, p: 1,
                  }}
                  style={{ position: 'relative' }}
                >

                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ 
                      position: 'absolute', 
                      left: '50%',
                      top: '50%',
                      zIndex: 0,
                      marginLeft: '-50%',
                      marginTop: '-50px',
                    }}
                  >
                    <IconButton color="primary"><FileUploadOutlined /></IconButton>
                    <Typography textAlign="center" sx={{ opacity: 0.8 }} variant="smaller">
                      Drop your file here to upload or select from storage
                    </Typography>
                    <Button color="primary" variant="cBlue"
                      sx={{ maxWidth: 200, mt: 2.75 }}>
                      <AddPhotoAlternate sx={{ mr: 1 }} /> Load images
                    </Button>
                  </Grid>

                  {uploadFiles.length > 0 &&
                    uploadFiles.map((item, i) => {
                      return (
                        <Card sx={{m: 0.5, zIndex: 1, width: 414, height: 160, position: "relative", overflow: 'visible'}} elevation={2} key={i}>
                          <IconButton 
                            onClick={(e) => {e.stopPropagation(); deleteImage(item.id)}}
                            sx={{ pt: 1, maxHeight: 13, maxWidth: 13 }}
                            style={{position: "absolute", right: 0}}
                          ><Cancel /></IconButton>
                          <img src={item.img} style={{ height: "100%", width: "100%", objectFit: "contain" }} title={item.name} alt={item.name} />
                        </Card>
                      );
                    })}

              </Grid>

          </Grid>

          <Grid justifyContent="center" container item xs={12}>
            <Button sx={{ maxWidth: 140, mr: 2 }} color="orange" variant="outlined" onClick={onCancel}>
              cancel
            </Button>
            <Button sx={{ maxWidth: 140 }} color="orange" variant="contained" onClick={submitForm}>
              save
            </Button>
          </Grid>
        </>
      )}
      
      <TextField sx={{ visibility: 'hidden', maxHeight: 0 }}
        inputProps={{ ref: inputFile, accept: "image/jpg, image/jpeg, image/png", multiple: true }}
        onChange={changeInputFile}
        type="file"
      />

    </Grid>
  );
};

const mapStateToProps = (state) => ({
  carouselImages: state.setting?.carouselImages || [],
  fetchStatus: state.setting.fetchStatus
})
const mapDispatchToProps = (dispatch) => ({
  updateSettings: (data) => dispatch({ type: types.settings.UPDATE_GENERAL_SETTINGS, data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoadImages);
