export default {
    RECORDING: 'recording',
    PLAY: 'play',
    STOP: 'stop',
    PAUSE: 'pause',
    AUDIOINPUT: 'audioinput',
    AUDIOOUTPUT: 'audiooutput',
    DEFAULT: 'default',
    TEST_MIC_SEC: 5,

    LS_MEDIA_INPUT: 'input_audio_deviceId',
    LS_MEDIA_OUTPUT: 'output_audio_deviceId',
  }