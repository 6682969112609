import { ArrowDropDown, Close } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Grid, InputLabel, Switch, TextField, Typography, IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import HelpTip from '../../containers/HelpTip';

const DateTimeAccordion = ({ state, openDuration, triggerDuration, handleChange, handleStateChange, ...props }) => {   
    const updateDate = (element, change) => {
      let date,duration;
        if(change === true){
        if(element === null || isNaN(new Date(element))) return

          let dateTime = new Date(element).getTime() + 30 * 60000
          date = Math.floor(dateTime - new Date(element).getTime())
          duration = Math.floor(date / 60000)
          handleStateChange({ dateFrom: new Date(element).toISOString(), dateTo: new Date(dateTime).toISOString(), duration });
          return
        }
        if(element === null || isNaN(new Date(element))) {
          handleStateChange({ dateTo: '', duration: ''})
          return
        }
        date = new Date(element).getTime() - new Date(state.dateFrom).getTime()
        duration = Math.ceil(date / 60000)
        if(Math.sign(date) === -1) {
          let dateTime = new Date(state.dateFrom).getTime() + 30 * 60000
          handleStateChange({ dateTo: new Date(dateTime).toISOString(), duration: 30});
          return 
        } 
        handleStateChange({ dateTo: new Date(element).toISOString(), duration });
    }
    const onDateFrom = (dateFrom) => {
      if(dateFrom === null){
        return
      }
      updateDate(dateFrom, true)
    }
    const onDuration = (durat) => {
      let value = durat.target.value, 
      date = new Date(state.dateFrom).getTime()+(value * 60000)
      if(value < 0) return
      handleStateChange({dateTo: date, duration: value});
    }
    const resetDataTime = (e) => {
      handleStateChange({ dateFrom: '', dateTo: '', duration: '' });
    }
    return (
        <Accordion sx={{ bgcolor: "primary.backgroundAll"}} disableGutters elevation={0}
          expanded={openDuration === "panel1"}
          onChange={() => triggerDuration("panel1")}>
          <AccordionSummary sx={{ p:0}} expandIcon={<ArrowDropDown sx={{ fontSize: '32px'}} color="info" />}>
            <InputLabel>
              <Typography variant="middleText" color='text.primary'>
                Define the schedule of your interview (optional)
              </Typography>
              <HelpTip children="Define the schedule of your interview" />
            </InputLabel>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mt: 2 }}>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    color="primary"
                    InputProps={{
                      endAdornment: (
                        <Grid sx={{ width: "15px",cursor: "pointer", display: state.dateFrom ? "flex" : 'none' }}
                              onClick={(e) => resetDataTime(e)}>
                          <Close sx={{ width: 18}} />
                        </Grid>
                      ),
                      sx: {"& .MuiInputAdornment-positionStart": { m: 0 }}
                    }}
                    InputAdornmentProps={{
                      position: "start",
                      variant: "standard",
                    }}
                    renderInput={(props) => {
                      props.error = false;
                      return <TextField name="dateFrom" {...props} />;
                    }}
                    minDateTime={new Date(Date.now() - 3600 * 1000)}
                    label="Start date and time"
                    inputRef={(e) => (state.dateFrom === '' && e) && (e.value = null) }
                    value={state.dateFrom}
                    onChange={onDateFrom}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    color="primary"
                    disabled={state.dateFrom === ""}
                    InputAdornmentProps={{
                      position: "start",
                      variant: "standard",
                    }}
                    renderInput={(props) => {
                      props.error = false;
                      return <TextField name="dateTo" {...props}/>
                    }}
                    label="End date and time"
                    inputRef={(e) => (state.dateTo === '' && e) && (e.value = "") }
                    value={state.dateTo}
                    minDateTime={new Date(state.dateFrom).getTime() + 5 * 60000}
                    onChange={updateDate}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid container alignItems='center' item xs={.5}>
                <Typography variant="middle">or</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type='number'
                  disabled={!state.dateFrom}
                  name="duration"
                  value={state.duration}
                  label="Duration"
                  onChange={onDuration}
                  sx={{ "& input": { mr: 1 }}}
                  InputProps={{
                    startAdornment: " ",
                    endAdornment: "min",
                    placeholder: "Minutes",
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ alignItems: "baseline" }} container item xs={12}>
              <Grid item xs={6}>
                <InputLabel sx={{ opacity: 0.8, m: 0, color: "primary.blackText" }}>
                  Should guest link expire after the event?
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  sx={{ color: "primary.text" }}
                  control={
                    <>
                      <Typography variant='middleText'>No</Typography>
                      <Switch 
                        disabled={state.dateFrom === ""}
                        onChange={handleChange}
                        checked={state.expire} 
                        name="expire"/>
                      <Typography variant='middleText'>Yes</Typography>
                    </>
                  }
                  label="" />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
    )
}
export default DateTimeAccordion